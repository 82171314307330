import { HTMLProps } from 'react';
import styles from './ErrorMessage.module.css';
import cn from 'classnames';

const ErrorMessage = (props: HTMLProps<HTMLSpanElement>) => (
    <span {...props} className={cn(styles.error, props.className)}>
        {props.children}
    </span>
);

export default ErrorMessage;
