import { useMemo, useState } from 'react';
import useInstagramContacted, { mapLocalStateToApiParams } from './useInstagramContacted';
import InstagramContactedTable, { InstagramContactedTableRow } from './InstagramContactedTable/InstagramContactedTable';
import { showNotification } from 'helpers';
import { mapOrderingToTableSorting, mapTableSortingToOrdering } from 'ui/WrapperTable/helpers';
import InstagramContactedFilters from './InstagramContactedFilters/InstagramContactedFilters';
import styles from './InstagramContacted.module.css';
import Button from 'ui/Button/Button';
import { mapArrayToRowSelectionState, Popover } from '@round/ui-kit';
import Card from 'ui/Card/Card';
import Calendar from 'ui/DataEntry/Calendar/Calendar';
import moment from 'moment';
import ChaseEmailPreviewModal from '../../../EmailPreview/ChaseEmailPreviewModal';
import EmailPreviewButton from '../../../components/EmailPreviewButton/EmailPreviewButton';
import useInstagramContactedUrlState from './useInstagramContactedUrlState';
import { useSelectedContacted } from './useSelectedContacted';

type Props = {
    campaignId: number;
};

const InstagramContacted = ({ campaignId }: Props) => {
    const { state: url, mergeSearchParams } = useInstagramContactedUrlState({ campaignId, shouldPersist: true });

    const [selected, setSelected] = useSelectedContacted(campaignId);

    const {
        data,
        status,
        error,
        creatorsData,
        audiosData,
        instagramUserImagesData,
        getIsAccountDataLoading,
        reset,
        update: updateInvite,
    } = useInstagramContacted({
        params: {
            ...mapLocalStateToApiParams({ ...url, campaign_id: campaignId, selected }),
        },
    });

    const rows = useMemo(() => {
        return (data?.results ?? []).map<InstagramContactedTableRow>((invite) => {
            const creator = creatorsData[invite.influencer_id]?.data ?? null;
            return {
                ...invite,
                creator,
                audio: invite.instagram_audio_id ? audiosData[invite.instagram_audio_id]?.data ?? null : null,
                image: creator?.instagram_user_id
                    ? instagramUserImagesData[creator.instagram_user_id]?.data ?? null
                    : null,
            };
        });
    }, [audiosData, creatorsData, data?.results, instagramUserImagesData]);

    const [deadlineButtonRef, setDeadlineButtonRef] = useState<HTMLButtonElement | null>(null);
    const [deadlineSelectValue, setDeadlineSelectValue] = useState(moment().format('YYYY-MM-DD'));
    const [isDeadlineUpdateLoading, setIsDeadlineUpdateLoading] = useState(false);

    const [isEmailPreviewModalOpen, setIsEmailPreviewModalOpen] = useState(false);
    const isEmailPreviewDisabled = selected?.length === 0;

    return (
        <>
            <menu className={styles.menuContainer}>
                <div className={styles.actions}>
                    {selected?.length > 0 && (
                        <>
                            <Button
                                className={styles.actionButton}
                                appearance="ghost"
                                onClick={() => {
                                    if (url.showOnlySelected) {
                                        mergeSearchParams({
                                            page: 1,
                                            showOnlySelected: undefined,
                                        });
                                        setSelected([]);
                                        reset();
                                        return;
                                    }

                                    setSelected([]);
                                }}
                            >
                                Clear selection
                            </Button>

                            <Button ref={setDeadlineButtonRef} className={styles.actionButton} appearance="ghost">
                                Set deadline{selected?.length === 1 ? '' : 's'}
                            </Button>

                            <Popover
                                anchorElement={deadlineButtonRef}
                                showOn="click"
                                options={{ placement: 'bottom-start' }}
                            >
                                {(setShow) => (
                                    <Card className={styles.deadlineSelectPopover}>
                                        <Calendar
                                            value={deadlineSelectValue}
                                            onChange={setDeadlineSelectValue}
                                            minDate={new Date()}
                                        />

                                        <Button
                                            className={styles.setDeadlineSubmit}
                                            appearance="ghost"
                                            isLoading={isDeadlineUpdateLoading}
                                            onClick={async () => {
                                                if (!selected?.length) {
                                                    return;
                                                }

                                                try {
                                                    setIsDeadlineUpdateLoading(true);
                                                    const results = await Promise.allSettled(
                                                        selected.map((inviteId) =>
                                                            updateInvite(inviteId, {
                                                                deadline: deadlineSelectValue,
                                                            })
                                                        )
                                                    );

                                                    const allUpdated = results.every(
                                                        (result) => result.status === 'fulfilled'
                                                    );
                                                    if (allUpdated) {
                                                        showNotification('all updated', 'info');
                                                        setShow(false);
                                                        setDeadlineSelectValue(moment().format('YYYY-MM-DD'));
                                                        return;
                                                    }

                                                    showNotification('Could not update some or all dates', 'error');
                                                    return;
                                                } catch {
                                                    showNotification('Could not save multiple deadlines', 'error');
                                                } finally {
                                                    setIsDeadlineUpdateLoading(false);
                                                }
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </Card>
                                )}
                            </Popover>
                        </>
                    )}
                </div>

                <div className={styles.filtersContainer}>
                    <InstagramContactedFilters
                        value={url}
                        onChange={(values, shouldRefreshData = true) => {
                            if (shouldRefreshData) {
                                mergeSearchParams({ ...values, page: 1 });
                                reset();
                                return;
                            }

                            mergeSearchParams(values);
                        }}
                        selected={selected}
                    />

                    <EmailPreviewButton
                        onClick={() => setIsEmailPreviewModalOpen(true)}
                        disabled={isEmailPreviewDisabled}
                        hint={isEmailPreviewDisabled ? 'Select users to preview email' : undefined}
                    />
                </div>
            </menu>

            <InstagramContactedTable
                data={rows}
                page={url.page}
                setPage={(page) => {
                    mergeSearchParams({ page });
                    reset();
                }}
                pageSize={url.page_size}
                setPageSize={(pageSize) => {
                    mergeSearchParams({ page_size: pageSize, page: 1 });
                    reset();
                }}
                count={data?.count ?? 0}
                noDataLabel={status === 'error' ? error : 'No data'}
                meta={{ isLoading: status === 'loading' || status === 'idle', getIsAccountDataLoading }}
                rowSelection={mapArrayToRowSelectionState(selected)}
                onRowSelectionChange={(selection) => setSelected(Object.keys(selection))}
                sorting={mapOrderingToTableSorting(url.ordering ? [url.ordering] : [])}
                onSortingChange={(sorting) => {
                    mergeSearchParams({ ordering: mapTableSortingToOrdering(sorting) });
                    reset();
                }}
            />

            <ChaseEmailPreviewModal
                isOpen={isEmailPreviewModalOpen}
                onClose={() => setIsEmailPreviewModalOpen(false)}
                inviteIds={selected}
                campaignId={campaignId}
                onSent={(results) => {
                    const invitesEmailedSuccessfullyIds = results.success;
                    setSelected((prev) => prev?.filter((id) => !invitesEmailedSuccessfullyIds.includes(id)));
                    reset();
                }}
            />
        </>
    );
};

export default InstagramContacted;
