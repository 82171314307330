import { queryOptions } from '@tanstack/react-query';
import {
    buildListKeyFactories,
    buildCoreKeyFactory,
    parseQueryKey,
    buildErrorResponseGetter,
} from '../../queries.helpers';
import { ExpectedResponseError } from '../../utils';
import { getReleases, GetReleasesParams } from './releases.api';

export const CORE_KEY = 'microwave-release';

export const keys = {
    all: buildCoreKeyFactory(CORE_KEY),
    ...buildListKeyFactories<GetReleasesParams, typeof CORE_KEY>(CORE_KEY),
};

export const options = {
    list: (params: GetReleasesParams) =>
        queryOptions({
            queryKey: keys.list(params),
            queryFn: async ({ signal, queryKey }) => {
                const { params } = parseQueryKey(queryKey);

                const response = await getReleases(params, { signal });

                if (response.status === 200) {
                    return response.data;
                }

                throw new ExpectedResponseError('Failed to fetch releases', response);
            },
        }),
};

export const utils = {
    list: {
        getErrorResponse: buildErrorResponseGetter<Awaited<ReturnType<typeof getReleases>>>(),
    },
};
