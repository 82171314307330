import { ApiResponse, ClientErrorApiResponse } from './types';
import { ExpectedResponseError, isExpectedResponseError } from './utils';

type QueryParameters = {} | string | number;

type QueryType = 'list' | 'detail';

// This type represents a query key that is used to label and identify a query in the cache.
// Each element increases the specificity of the key, so we can target queries in the cache with the same specifity
// e.g. ['influencer', 'list] to target all influencer list queries.
type QueryKey<TParams extends QueryParameters> = readonly [
    entity: string, // entity name (e.g. 'influencer')
    type: QueryType,
    params: TParams // parameter(s) passed to request, e.g. id or { page: 1, page_size: 10 }
];

export const parseQueryKey = <TParams extends QueryParameters>(queryKey: QueryKey<TParams>) => {
    const [entity, type, params] = queryKey;

    return {
        entity,
        type,
        params,
    };
};

export const buildCoreKeyFactory = <TKey extends string>(key: TKey) => () => [key] as const;

// These functions are used to build query key factories for different types of queries. They ensure that the query keys are
// tied to the entity being queried and the type of query being made (list or detail). We can then reliably access and invalidate
// these queries in the cache.
export const buildListKeyFactories = <TParams extends QueryParameters, TKey extends string>(coreKey: TKey) => {
    const lists = () => [...buildCoreKeyFactory(coreKey)(), 'list'] as const;

    return {
        lists,
        list: (params: TParams) => [...lists(), params] as const,
    };
};

export const buildDetailKeyFactories = <TParam extends QueryParameters, TKey extends string>(coreKey: TKey) => {
    const details = () => [...buildCoreKeyFactory(coreKey)(), 'detail'] as const;

    return {
        details,
        detail: (id: TParam) => [...details(), id] as const,
    };
};

export function buildErrorResponseGetter<TResponse extends ApiResponse<any>>() {
    return (error: Error | ExpectedResponseError<any> | null | undefined): ClientErrorApiResponse<TResponse> | null => {
        if (isExpectedResponseError(error)) {
            return error.response;
        }

        return null;
    };
}
