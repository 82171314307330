import { microwave } from '@round/api';
import { OptionsParams, useSelect, UseSingleValueSelectParams } from 'Hooks/useSelect';
import { MakeRequired } from 'utility/utility.types';
export type InviteOption = {
    value: string;
    label: string;
    emailAddress: string;
    influencerId: number;
    tiktokUsername: string | null;
    instagramUsername: string | null;
};

const mapToOption = (invite: microwave.Invite): InviteOption => ({
    value: invite.id,
    label: invite.influencer_email_address,
    emailAddress: invite.influencer_email_address,
    influencerId: invite.influencer_id,
    tiktokUsername: invite.influencer_tiktok_user_unique_id,
    instagramUsername: invite.influencer_instagram_user_username,
});

export const buildFetchOptions = (inviteParams: Omit<microwave.GetInvitesParams, 'page' | 'page_size' | 'search'>) => (
    params: OptionsParams,
    requestInit?: RequestInit
) => fetchOptions({ ...inviteParams, ...params }, requestInit);

export const fetchOptions = async (
    params: MakeRequired<microwave.GetInvitesParams, 'page' | 'page_size'>,
    requestInit?: RequestInit
) => {
    const response = await microwave.getInvites(params, requestInit);

    if (response.status !== 200) {
        throw new Error('Could not fetch invites');
    }

    return {
        ...response.data,
        results: response.data.results.map((invite) => {
            return mapToOption(invite);
        }),
    };
};

const fetchInitialValue = async (id: string, requestInit?: RequestInit) => {
    const response = await microwave.getInvite(id, requestInit);

    if (response.status !== 200) {
        throw new Error('Could not find initial invite');
    }

    return mapToOption(response.data);
};

type Params = Omit<UseSingleValueSelectParams<InviteOption, string>, 'isMulti'>;

export default function useInviteSelect(params: Params) {
    return useSelect({
        ...params,
        isMulti: false,
        fetchOptions: params.fetchOptions || fetchOptions,
        fetchInitialValue,
    });
}
