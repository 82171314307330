/**
 * Formats number to specified no. of demical points, and removes trailing zeroes
 **/
export const toDecimalPoint = (value: number, decimalPoints: number) => {
    return parseFloat(value.toFixed(decimalPoints));
};

/**
 * Takes a number and returns short version as in example.
 * Limits only up to millions
 * @param n
 * @example
 * formatNumberToKNotation(1000); // 1K
 * formatNumberToKNotation(10_000); // 10K
 * formatNumberToKNotation(1_000_000); // 1M
 * formatNumberToKNotation(1_000_000_000); // 1000M
 */
export const formatNumberToKNotation = (n: number): string => {
    const exponent = Math.floor(Math.log10(Math.abs(n)));
    const sliceRules = [
        { threshold: 3, exponentSign: '', format: (n: number) => n },
        {
            threshold: 4,
            exponentSign: 'K',
            format: (n: number) => {
                const formatted = (Math.floor((n / Math.pow(10, exponent)) * 10) / 10).toFixed(1);
                if (Number(formatted) - Math.floor(Number(formatted)) === 0) {
                    return parseInt(String(formatted));
                }

                return formatted;
            },
        },
        {
            threshold: 6,
            exponentSign: 'K',
            format: (n: number) => Math.round(n).toString(10).slice(0, -3),
        },
        {
            threshold: 7,
            exponentSign: 'M',
            format: (n: number) => {
                const formatted = (Math.floor((n / Math.pow(10, exponent)) * 10) / 10).toFixed(1);
                if (Number(formatted) - Math.floor(Number(formatted)) === 0) {
                    return parseInt(String(formatted));
                }

                return formatted;
            },
        },
        {
            threshold: 8,
            exponentSign: 'M',
            format: (n: number) => {
                const formatted = (Math.floor((n / Math.pow(10, exponent)) * 100) / 10).toFixed(1);
                if (Number(formatted) - Math.floor(Number(formatted)) === 0) {
                    return parseInt(String(formatted));
                }

                return formatted;
            },
        },
        {
            threshold: Infinity,
            exponentSign: 'M',
            format: (n: number) => Math.round(n).toString(10).slice(0, -6),
        },
    ];

    const rule = sliceRules.find((r) => r.threshold > exponent);
    if (!rule) {
        return n.toString();
    }

    return `${rule.format(n)}${rule.exponentSign}`;
};

export const formatDecimalToPercentageString = (value: number, decimalPoints: number = 2) =>
    `${toDecimalPoint(value * 100, decimalPoints)}%`;

export const toNumberOrUndefined = (value: string | undefined) => (value ? Number(value) : undefined);
