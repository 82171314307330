import { influencer } from '@round/api';
import { useSelect, UseSingleValueSelectParams } from '../useSelect';
import { useCallback } from 'react';

export type InfluencerLocationOption = {
    value: number;
    label: string;
    countryCode: string;
    country: string;
    city: string;
    state: string;
    type: string;
};

export const makeLocationOption = (location: influencer.InfluencerLocation): InfluencerLocationOption => {
    return {
        value: location.id,
        label: `${location.country_name}${location.city ? `, ${location.city}` : ''}${
            location.state ? ', ' + location.state : ''
        }`,
        countryCode: location.country_code,
        country: location.country_name,
        city: location.city,
        state: location.state,
        type: location.type,
    };
};

const fetchOptions = async (params: influencer.GetInfluencerLocationsParams, requestInit?: RequestInit) => {
    const response = await influencer.getInfluencerLocations(params, requestInit);

    if (response.status === 404) {
        throw new Error(response.data.detail);
    }

    return {
        ...response.data,
        results: response.data.results.map(makeLocationOption),
    };
};

type Params = Partial<
    Pick<influencer.GetInfluencerLocationsParams, 'country_code' | 'ordering' | 'state' | 'type'> &
        Pick<UseSingleValueSelectParams<InfluencerLocationOption>, 'initOn' | 'pageSize'>
>;

export function useInfluencerLocationSelect({
    country_code,
    state,
    ordering,
    type,
    initOn = 'menuOpen',
    pageSize = 25,
}: Params = {}) {
    return useSelect<InfluencerLocationOption>({
        isMulti: false,
        initOn,
        pageSize,
        fetchOptions: useCallback(
            (params, requestInit) => fetchOptions({ ...params, country_code, state, type, ordering }, requestInit),
            [country_code, ordering, state, type]
        ),
    });
}
