import { TabPanel, Tabs } from '@round/ui-kit';
import {
    CampaignStats,
    CampaignTab,
    getCampaignTabStateFromUrlSearchParam,
    setCampaignTabStateInUrlSearchParam,
} from '../../helpers';
import Campaign from '../Campaign/Campaign';
import InstagramCreators from '../../Creators/InstagramCreators/InstagramCreators';
import styles from './InstagramCampaigns.module.css';
import InstagramContacted from '../../Invites/Contacted/InstagramContacted/InstagramContacted';
import InstagramPosted from '../../Invites/Posted/InstagramPosted/InstagramPosted';
import useInstagramCampaignsUrlState from './useInstagramCampaignsUrlState';
import useInstagramCampaigns from './useInstagramCampaigns';
import CampaignSkeleton from '../Campaign/CampaignSkeleton/CampaignSkeleton';

type Props = {
    releaseId: number;
};

const InstagramCampaigns = ({ releaseId }: Props) => {
    const { state: urlData, setSearchParams } = useInstagramCampaignsUrlState({ shouldPersist: true });
    const { campaigns, status, stats: instagramCampaignStats, teams, audios, isTeamLoading } = useInstagramCampaigns({
        releaseId,
    });

    if (status === 'idle' || status === 'loading') {
        return <CampaignSkeleton />;
    }

    return (
        <>
            {campaigns.map((campaign, index) => {
                const stats = instagramCampaignStats[campaign.instagram_campaign_id];

                const campaignStats: CampaignStats | undefined = stats?.data
                    ? {
                          amountSpent: stats.data.amount_spent,
                          engagementRate: stats.data.engagement_rate,
                          postCount: stats.data.post_count,
                          totalViews: stats.data.total_views,
                      }
                    : undefined;

                const [currentTab, isTabOpen] = getCampaignTabStateFromUrlSearchParam(
                    campaign.id,
                    urlData.searchParams.campaignTabs
                );

                //If there's no open state data in the URL, open the first campaign by default
                const isOpen = isTabOpen === undefined && index === 0 ? true : isTabOpen;

                const setTabState = (isOpen: boolean, tab?: CampaignTab) =>
                    setSearchParams({
                        campaignTabs: setCampaignTabStateInUrlSearchParam(
                            campaign.id,
                            urlData.searchParams.campaignTabs,
                            isOpen,
                            tab
                        ),
                    });

                const accountTeam = teams.filter((user) => campaign.team_members?.includes(user.id));
                return (
                    <Tabs key={campaign.id} activeTab={currentTab} onChange={(tab) => setTabState(true, tab)}>
                        <Campaign
                            className={styles.campaign}
                            campaign={campaign}
                            stats={campaignStats}
                            isExpanded={isOpen}
                            onExpand={() => setTabState(!isOpen)}
                            isAccountTeamLoading={isTeamLoading(campaign)}
                            accountTeam={accountTeam}
                            audio={audios.find((a) => a.id === campaign.instagram_audio_id)}
                        >
                            <TabPanel className={styles.panel} name="creators">
                                <InstagramCreators campaignId={campaign.id} />
                            </TabPanel>
                            <TabPanel className={styles.panel} name="posted">
                                <InstagramPosted campaignId={campaign.id} />
                            </TabPanel>
                            <TabPanel className={styles.panel} name="contacted">
                                <InstagramContacted campaignId={campaign.id} />
                            </TabPanel>
                        </Campaign>
                    </Tabs>
                );
            })}
        </>
    );
};

export default InstagramCampaigns;
