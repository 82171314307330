import ApproveInstagramPostsTable, { Row } from './Table/ApproveInstagramPostsTable';
import useApproveInstagramPosts from './useApproveInstagramPosts';
import useApproveInstagramPostsUrlState from './useApproveInstagramPostsUrlState';
import ApproveInstagramPostsFilters from './Filters/ApproveInstagramPostsFilters';
import styles from './ApproveInstagramPosts.module.css';

const ApproveInstagramPosts = () => {
    const { state: urlState, mergeSearchParams } = useApproveInstagramPostsUrlState({ shouldPersist: true });

    const {
        data,
        status,
        influencers,
        getIsInfluencerLoading,
        releases,
        getIsReleaseLoading,
        audios,
        getIsAudioLoading,
    } = useApproveInstagramPosts(urlState);

    const rows: Row[] = (data?.results || []).map((row) => {
        const influencer = influencers?.find((influencer) => influencer.id === row.influencer_id) || null;
        const release = releases?.find((release) => release.id === row.release_id) || null;
        const audio = audios?.find((audio) => audio.id === row.instagram_audio_id) || null;
        return {
            ...row,
            influencer,
            release,
            audio,
        };
    });

    return (
        <>
            <div className={styles.headerContainer}>
                <ApproveInstagramPostsFilters
                    value={{
                        status: urlState.status,
                        release_id: urlState.release_id,
                        influencer_id: urlState.influencer_id,
                    }}
                    onChange={(filterVals) => {
                        mergeSearchParams({ ...filterVals, page: 1 });
                    }}
                />
            </div>

            <ApproveInstagramPostsTable
                data={rows}
                page={urlState.page}
                setPage={(page) => mergeSearchParams({ page })}
                pageSize={urlState.page_size}
                setPageSize={(pageSize) => mergeSearchParams({ page: 1, page_size: pageSize })}
                count={data?.count || 0}
                meta={{
                    isLoading: status === 'pending',
                    getIsReleaseLoading: (row: Row) => getIsReleaseLoading(row.release_id),
                    getIsInfluencerLoading: (row: Row) => getIsInfluencerLoading(row.influencer_id),
                    getIsAudioLoading: (row: Row) => getIsAudioLoading(row.instagram_audio_id),
                    activeStatusFilter: urlState.status,
                }}
                hasError={status === 'error'}
            />
        </>
    );
};

export default ApproveInstagramPosts;
