import {
    AdvancedFilterCondition,
    AdvancedFilterConditionTypeOption,
    conditionTypeOptionsWithExclude,
} from '@round/ui-kit';
import Dropdown from 'ui-new/Dropdown/Dropdown';
import FilterBadgeClearable from 'ui/Filters/FilterBadge/FilterBadgeClearable/FilterBadgeClearable';
import styles from './ContentTagsFilter.module.css';
import FilterHeader from 'ui/Filters/FilterHeader/FilterHeader';
import { ReactComponent as TagsIcon } from 'assets/icons/Bookmarks.svg';
import { ReactComponent as PlusIcon } from 'assets/PlusIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/Close.svg';
import Button from 'ui/Button/Button';
import Select from 'ui-new/Select/Select';
import { GenericDropdownOption } from 'App.types';
import { StylesConfig, ValueType } from 'react-select';
import { CategoriesFilterCondition } from 'Modules/Tags/components/Filters/helpers';
import { useMemo } from 'react';

type Props<TOption extends GenericDropdownOption<number>> = {
    conditions: CategoriesFilterCondition[];
    options: TOption[];
    onChange: (value: CategoriesFilterCondition[]) => void;
    conditionOptions: AdvancedFilterConditionTypeOption[];
    areOptionsLoading: boolean;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    name: string;
    onRemove: () => void;
    className?: string;
    isDisabled?: boolean;
};

const typeSelectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        height: '100%',
        padding: '0.475rem 0.75rem',
    }),
    valueContainer: (base) => ({
        ...base,
        paddingLeft: '0.25rem',
        paddingRight: '0.25rem',
        minWidth: '6.25rem',
    }),
};

const tagsSelectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        height: '100%',
        padding: '0.475rem 0.75rem',
    }),
    valueContainer: (base) => ({
        ...base,
        flexWrap: 'nowrap',
        padding: '0 0.5rem',
    }),
    multiValue: (base) => ({
        ...base,
        minWidth: 'unset',
        margin: '0 0.1875rem 0 0',
    }),
    clearIndicator: (base) => ({
        ...base,
        padding: 0,
    }),
};

export const ContentTagsFilterIcon = <TagsIcon className={styles.filterIcon} />;

export const ContentTagsFilter = <TOption extends GenericDropdownOption<number>>({
    conditions,
    options,
    areOptionsLoading,
    onChange: propsOnChange,
    name,
    isOpen,
    onOpen,
    onClose,
    onRemove,
    isDisabled,
}: Props<TOption>) => {
    const onChange = (conditions: AdvancedFilterCondition<ValueType<TOption, true>>[]) =>
        propsOnChange(
            conditions.map((condition) => ({
                ...condition,
                value: condition.value?.map((tag) => tag.value) ?? [],
            }))
        );

    const mappedConditions: AdvancedFilterCondition<ValueType<TOption, true>>[] = useMemo(
        () =>
            conditions.map((condition) => {
                const tags = condition.value
                    .map((tagId) => options.find((tag) => tag.value === tagId))
                    .filter((tag): tag is TOption => !!tag);

                return {
                    type: condition.type,
                    value: tags,
                };
            }),
        [conditions, options]
    );

    const hasValue = mappedConditions.length > 0;

    return (
        <Dropdown
            isOpen={isOpen}
            onClose={onClose}
            control={
                <FilterBadgeClearable isDisabled={isDisabled} onClick={onOpen} onClear={onRemove}>
                    {hasValue ? 'Filtered by content tags' : 'Content Tags'}
                </FilterBadgeClearable>
            }
            className={styles.container}
        >
            <FilterHeader
                name={name}
                icon={ContentTagsFilterIcon}
                hasValue={!!conditions.length}
                onReset={() => onChange([])}
            />

            <div className={styles.conditionsList}>
                {mappedConditions.map((condition, index) => (
                    <div key={index} className={styles.conditionContainer}>
                        <Select
                            styles={typeSelectStyles}
                            isSearchable={false}
                            menuPortalTarget={null}
                            className={styles.typeSelect}
                            options={conditionTypeOptionsWithExclude}
                            value={conditionTypeOptionsWithExclude.find((o) => o.value === condition.type)}
                            onChange={(value: ValueType<AdvancedFilterConditionTypeOption, false>) => {
                                const newValue = mappedConditions.map((item, i) => {
                                    if (i === index) {
                                        return {
                                            type: value?.value!,
                                            value: condition.value,
                                        };
                                    }

                                    return item;
                                });

                                onChange(newValue);
                            }}
                        />

                        <Select
                            styles={tagsSelectStyles}
                            className={styles.tagsSelect}
                            menuPortalTarget={null}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isLoading={areOptionsLoading}
                            options={options}
                            value={condition.value}
                            onChange={(value: ValueType<TOption, true>) => {
                                const newValue = mappedConditions.map((item, i) => {
                                    if (i === index) {
                                        return {
                                            type: condition.type,
                                            value: value,
                                        };
                                    }

                                    return item;
                                });

                                onChange(newValue);
                            }}
                        />

                        <Button
                            appearance="ghost"
                            className={styles.removeConditionButton}
                            onClick={() => {
                                onChange(mappedConditions.filter((item, i) => i !== index));
                            }}
                        >
                            <CloseIcon height={14} width={14} />
                        </Button>
                    </div>
                ))}
                <Button
                    appearance="ghost"
                    className={styles.addConditionButton}
                    onClick={() => onChange(mappedConditions.concat({ type: 'any', value: [] }))}
                    leftIcon={<PlusIcon className={styles.icon} />}
                >
                    Condition
                </Button>
            </div>
        </Dropdown>
    );
};
