import { CommonFilterProps } from 'ui-new/Filters/helpers';
import { useInstagramAudiosSelect } from '../../../hooks/selectHooks/useInstagramAudiosSelect';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';
import AudioBadge from 'Modules/Advertising/Microwave/components/AudioBadge/AudioBadge';
import { buildInstagramAudioUrl } from 'helpers';

type Props = CommonFilterProps & {
    initialValueId: number | undefined;
    onChange: (valueId: number | undefined) => void;
};

const InstagramAudioFilter = (props: Props) => {
    const { props: selectProps } = useInstagramAudiosSelect({
        initOn: 'mount',
        pageSize: 25,
        initialValueId: props.initialValueId,
    });

    const clearState = () => {
        selectProps.onChange?.(null, { action: 'set-value' });
    };

    return (
        <SelectFilter
            icon={props.icon}
            name={props.label}
            isDisabled={props.isDisabled}
            isOpen={props.isOpen}
            onClose={props.onClose}
            onOpen={props.onOpen}
            onClear={() => {
                props.onClear();
                clearState();
            }}
            onRemove={() => {
                props.onRemove();
                clearState();
            }}
            {...selectProps}
            onChange={(value, action) => {
                selectProps.onChange?.(value, action);
                props.onChange(value?.value);
            }}
            formatOptionLabel={(option, meta) => {
                if (meta.context === 'value') {
                    return option.label;
                }

                // Reads from AudioPlayerContext. Will throw an error without it
                return (
                    <AudioBadge
                        audio={{
                            audioPlayUrl: option.audioFileUrl ?? '',
                            platformAudioUrl: buildInstagramAudioUrl(option.instagramId),
                            imageUrl: option.thumbnailUrl ?? '',
                            subTitle: option.author?.username,
                            title: option.label,
                        }}
                    />
                );
            }}
            placeholder="Identifier search..."
        />
    );
};

export default InstagramAudioFilter;
