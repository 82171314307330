import { microwave } from '@round/api';
import { StringSearchParams, toNumberOrUndefined, UrlData, useSessionUrlState } from '@round/utils';
import { useEffect } from 'react';
import { mapStringListToArray } from 'helpers';
import { MakeRequired } from 'utility/utility.types';
import { FilterParams } from './Filters/InstagramCreatorsFilters';
import useRelease from '../../Release/useRelease';

type UrlStateShape = Pick<microwave.GetMicrowaveInfluencersParams, 'page' | 'page_size' | 'ordering'> &
    FilterParams & {
        selected: number[];
    };

export type UrlState = MakeRequired<UrlStateShape, 'page' | 'page_size'>;
type UrlStateKey = keyof UrlState;

const initialUrlState: UrlState = {
    page: 1,
    page_size: 10,
    exclude_invited_to_campaign: true,
    selected: [],
};

const parseUrlState = ({
    searchParams: {
        page,
        page_size,
        min_tiktok_post_count,
        max_tiktok_post_count,
        min_instagram_post_count,
        max_instagram_post_count,
        min_tiktok_post_invite_count,
        max_tiktok_post_invite_count,
        min_instagram_post_invite_count,
        max_instagram_post_invite_count,
        min_instagram_follower_count,
        max_instagram_follower_count,
        min_tiktok_post_rate,
        hashtag_ids,
        rating,
        search,
        min_instagram_post_rate,
        selected,
        content_tags,
        exclude_invited_to_campaign,
        location_id,
        showOnlySelected,
        exclude_posted_with_instagram_audio,
        posted_with_instagram_audio,
        ordering,
    },
}: UrlData<StringSearchParams<UrlState>>): UrlState => ({
    page: page ? Number(page) : initialUrlState.page,
    page_size: page_size ? Number(page_size) : initialUrlState.page_size,
    min_tiktok_post_count: toNumberOrUndefined(min_tiktok_post_count),
    max_tiktok_post_count: toNumberOrUndefined(max_tiktok_post_count),
    min_instagram_post_count: toNumberOrUndefined(min_instagram_post_count),
    max_instagram_post_count: toNumberOrUndefined(max_instagram_post_count),
    min_tiktok_post_invite_count: toNumberOrUndefined(min_tiktok_post_invite_count),
    max_tiktok_post_invite_count: toNumberOrUndefined(max_tiktok_post_invite_count),
    min_instagram_post_invite_count: toNumberOrUndefined(min_instagram_post_invite_count),
    max_instagram_post_invite_count: toNumberOrUndefined(max_instagram_post_invite_count),
    min_tiktok_post_rate: toNumberOrUndefined(min_tiktok_post_rate),
    min_instagram_post_rate: toNumberOrUndefined(min_instagram_post_rate),
    min_instagram_follower_count: toNumberOrUndefined(min_instagram_follower_count),
    max_instagram_follower_count: toNumberOrUndefined(max_instagram_follower_count),
    hashtag_ids,
    rating,
    search,
    selected: mapStringListToArray(selected ?? '').map(Number),
    content_tags,
    exclude_invited_to_campaign: exclude_invited_to_campaign === 'true' || undefined,
    location_id: toNumberOrUndefined(location_id),
    showOnlySelected: showOnlySelected === 'true' ?? undefined,
    exclude_posted_with_instagram_audio: toNumberOrUndefined(exclude_posted_with_instagram_audio),
    posted_with_instagram_audio: toNumberOrUndefined(posted_with_instagram_audio),
    ordering: microwave.isMicrowaveInfluencersSortingKey(ordering?.startsWith('-') ? ordering.slice(1) : ordering)
        ? (ordering as UrlState['ordering'])
        : undefined,
});

type HookConfig = {
    campaignId: number;
    shouldPersist?: boolean;
};

export default function useInstagramCreatorsUrlState({ shouldPersist, campaignId }: HookConfig) {
    const { data: release } = useRelease();

    const { persistSession, clearSession, ...urlState } = useSessionUrlState<UrlState>(
        `mw-ig-campaigns-creators-params-${campaignId}`,
        (url, session, { merge }) => {
            const urlKeys = Object.keys(parseUrlState({ searchParams: {}, hash: '' })) as UrlStateKey[];

            const hasAnyParamSet = Object.keys(url.searchParams).some((k) => urlKeys.includes(k as UrlStateKey));
            if (hasAnyParamSet) {
                return url;
            }

            return merge(url, { searchParams: { ...initialUrlState, ...session?.searchParams } });
        }
    );

    useEffect(() => {
        return () => {
            if (shouldPersist) {
                persistSession({ searchParams: parseUrlState(urlState.url) });
            }
        };
    }, [persistSession, shouldPersist, urlState]);

    useEffect(() => {
        return () => {
            if (!release?.id) {
                clearSession();
            }
        };
    }, [clearSession, release?.id]);

    return {
        state: parseUrlState(urlState.url),
        mergeSearchParams: urlState.mergeSearchParams,
    };
}
