import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReleaseContext } from '../../ReleaseContext';
import { microwave } from '@round/api';
import { useAbortableEffect } from '@round/utils';
import { useCallback } from 'react';

type Config = {
    campaignId: number;
    isImperative?: boolean;
};

export function useCounts({ campaignId, isImperative = false }: Config) {
    const state = useNonNullContextSelector(ReleaseContext, ([values]) => values.counts[campaignId]);
    const dispatch = useNonNullContextSelector(ReleaseContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (requestInit?: RequestInit) => {
            const errorMessage = 'Could not get campaign counts';
            try {
                dispatch({ type: 'loadCampaignCounts', payload: campaignId });
                const results = await Promise.allSettled([
                    microwave.getInvites({ campaign_id: campaignId, is_posted: false, page_size: 0 }, requestInit),
                    microwave.getInvites({ campaign_id: campaignId, is_posted: true, page_size: 0 }, requestInit),
                ]);

                if (results.every((result) => result.status === 'rejected')) {
                    dispatch({
                        type: 'errorLoadingCampaignCounts',
                        payload: { campaignId, error: errorMessage },
                    });
                }

                const [contacted, posted] = results.map((result) =>
                    result.status === 'fulfilled' && result.value.status === 200 ? result.value.data.count : null
                );

                dispatch({ type: 'campaignCountsInitialized', payload: { campaignId, data: { contacted, posted } } });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                dispatch({ type: 'errorLoadingCampaignCounts', payload: { campaignId, error: errorMessage } });
            }
        },
        [campaignId, dispatch]
    );

    const isInitialized = state?.status === 'success' || state?.status === 'error';
    useAbortableEffect(
        (signal) => {
            if (isImperative || isInitialized) {
                return;
            }
            fetchData({ signal }).catch(() => {});
        },
        [isImperative, isInitialized, fetchData]
    );

    return {
        data: state?.data,
        status: state?.status,
        error: state?.error,
        fetchData,
        reset: () => dispatch({ type: 'resetCampaignCounts', payload: campaignId }),
    };
}
