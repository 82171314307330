import useContacted from '../useContacted';
import makeCreatorsByIdDataHook from 'Modules/Advertising/Microwave/hooks/dataHooks/useCreatorsByIdDataHook';
import { ReleaseContext } from '../../../ReleaseContext';
import { useMemo, useEffect } from 'react';
import { mapStringListToArray } from 'helpers';
import { isNumber } from 'utility/utility';
import uniq from 'lodash/uniq';
import { InvitesTableRow } from '../../InvitesTable/InvitesTable';
import { makeInstagramUserImagesDataHook } from 'Modules/Instagram/hooks/dataHooks/useInstagramUserImagesDataHook';
import { UrlState } from './useInstagramContactedUrlState';
import { microwave } from '@round/api';
import {
    isExcludeCondition,
    isIncludeCondition,
    mapConditionsToApi,
    mapExcludeConditionsToApi,
    parseConditionsFromUrlString,
} from 'Modules/Tags/components/Filters/helpers';
import { MakeRequired } from 'utility/utility.types';
import useInstagramAudios from '../../../hooks/useInstagramAudios';

const useCreators = makeCreatorsByIdDataHook(ReleaseContext, ([state]) => state.supplementaryCreators);
const useInstagramUserImages = makeInstagramUserImagesDataHook(ReleaseContext, ([state]) => state.instagram.userImages);

export default function useInstagramContacted(...[params]: Parameters<typeof useContacted>) {
    const { data, status, ...restOfState } = useContacted(params);
    const { data: creatorsData, fetchData: fetchCreatorsData } = useCreators({ isDisabled: true });
    const { data: audiosData, fetchData: fetchAudiosData } = useInstagramAudios({ isImperative: true });
    const { data: instagramUserImagesData, fetchData: fetchInstagramUserImages } = useInstagramUserImages();

    const creatorsToFetch = useMemo(() => {
        return (data?.results.map((i) => i.influencer_id) ?? [])
            .filter((i) => {
                const data = creatorsData[i];
                return !data || (data.status !== 'success' && data.status !== 'loading');
            })
            .join(',');
    }, [creatorsData, data?.results]);

    useEffect(() => {
        const creatorIds = mapStringListToArray(creatorsToFetch).map(Number);
        if (creatorIds.length) {
            fetchCreatorsData(creatorIds).catch(() => {});
        }
    }, [fetchCreatorsData, creatorsToFetch]);

    const audiosToFetch = useMemo(() => {
        return uniq(
            (data?.results.map((i) => i.instagram_audio_id) ?? []).filter(isNumber).filter((i) => {
                const data = audiosData[i];
                return !data || (data.status !== 'success' && data.status !== 'loading');
            })
        ).join(',');
    }, [audiosData, data?.results]);

    useEffect(() => {
        const audioIds = mapStringListToArray(audiosToFetch).map(Number);
        if (audioIds.length) {
            fetchAudiosData(audioIds).catch(() => {});
        }
    }, [fetchAudiosData, audiosToFetch]);

    const areInvitesInitialized = status === 'success';
    useEffect(() => {
        const influencerIds = data?.results.map((invite) => invite.influencer_id).filter(isNumber) ?? [];
        const instagramUserIds = influencerIds.map((id) => creatorsData[id]?.data?.instagram_user_id).filter(isNumber);
        const userIdsWithImages = Object.keys(instagramUserImagesData).map(Number);
        const userIdsToFetchImagesFor = instagramUserIds.filter((userId) => !userIdsWithImages.includes(userId));
        if (!areInvitesInitialized || !userIdsToFetchImagesFor.length) {
            return;
        }

        fetchInstagramUserImages(userIdsToFetchImagesFor).catch(() => {});
    }, [areInvitesInitialized, creatorsData, data?.results, fetchInstagramUserImages, instagramUserImagesData]);

    return {
        data,
        status,
        ...restOfState,
        creatorsData,
        audiosData,
        instagramUserImagesData,
        getIsAccountDataLoading: (row: InvitesTableRow | {}) => {
            if (!Object.keys(row).length) {
                return true;
            }

            const invite = row as InvitesTableRow;
            const creatorData = creatorsData[invite.influencer_id];
            const isImageLoading = creatorData?.data?.instagram_user_id
                ? instagramUserImagesData[creatorData.data.instagram_user_id]?.status === 'loading'
                : null;
            return Boolean(creatorData && (creatorData.status === 'loading' || isImageLoading));
        },
    };
}

export const mapLocalStateToApiParams = ({
    showOnlySelected,
    selected,
    content_tags,
    page,
    page_size,
    influencer_location_id,
    chase_email_count,
    ordering,
    campaign_id,
}: UrlState & { campaign_id: number; selected?: string[] }): MakeRequired<
    microwave.GetInvitesParams,
    'campaign_id'
> => ({
    page,
    page_size,
    ordering,
    campaign_id,
    // We will disable other filters if showOnlySelected is enabled to ensure all selected influencers are shown
    ...(showOnlySelected
        ? { id: selected?.toString() }
        : {
              chase_email_count,
              influencer_location_id,
              content_tags: JSON.stringify(
                  mapConditionsToApi(parseConditionsFromUrlString(content_tags).filter(isIncludeCondition))
              ),
              exclude_content_tags: JSON.stringify(
                  mapExcludeConditionsToApi(parseConditionsFromUrlString(content_tags).filter(isExcludeCondition))
              ),
          }),
});
