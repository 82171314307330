import React from 'react';
import styles from './CreateMicrowaveInfluencer.module.css';
import Button from 'ui/Button/Button';
import Modal from 'ui-new/Modals/Modal/Modal';
import { Formik, FormikHelpers } from 'formik';
import { microwave, postInstagramUser, youtube } from '@round/api';
import { showNotification } from '../../../../../helpers';
import { mapApiErrorsToFormikErrors } from '../../../../../utility/utility';
import MicrowaveInfluencerForm, {
    microwaveInfluencerFormValidationSchema,
    MicrowaveInfluencerFormValues,
} from '../InfluencerForm/MicrowaveInfluencerForm';

const initialValues: MicrowaveInfluencerFormValues = {
    tiktokUser: null,
    instagramUser: null,
    youtubeChannel: null,
    email: '',
};

type Props = {
    isOpen: boolean;
    closeModal: () => void;
    onCreated: () => void;
};

const CreateMicrowaveInfluencer = ({ isOpen, closeModal, onCreated }: Props) => {
    const handleSubmit = async (
        values: MicrowaveInfluencerFormValues,
        helpers: FormikHelpers<MicrowaveInfluencerFormValues>
    ) => {
        helpers.setSubmitting(true);
        let instagramUserId = values.instagramUser?.value;

        if (values.isInstagramUserCreateMode && values.instagramUserIdentifier) {
            try {
                const instagramUserResponse = await postInstagramUser({
                    identifier: values.instagramUserIdentifier,
                });

                if (instagramUserResponse.status === 400) {
                    helpers.setFieldError(
                        'instagramUserIdentifier',
                        Array.isArray(instagramUserResponse.data.identifier)
                            ? instagramUserResponse.data.identifier.join('. ')
                            : instagramUserResponse.data.identifier
                    );
                    helpers.setSubmitting(false);
                    return;
                }

                instagramUserId = instagramUserResponse.data.id;
            } catch {
                showNotification('Could not create instagram user', 'error');
                return;
            }
        }

        let youtubeChannelId = values.youtubeChannel?.value;

        if (values.isYoutubeChannelCreateMode && values.youtubeChannelIdentifier) {
            try {
                const youtubeChannelResponse = await youtube.postYoutubeChannel({
                    identifier: values.youtubeChannelIdentifier,
                });

                if (youtubeChannelResponse.status === 400) {
                    helpers.setFieldError(
                        'youtubeChannelIdentifier',
                        Array.isArray(youtubeChannelResponse.data.identifier)
                            ? youtubeChannelResponse.data.identifier.join('. ')
                            : youtubeChannelResponse.data.identifier
                    );
                    helpers.setSubmitting(false);
                    return;
                }

                youtubeChannelId = youtubeChannelResponse.data.id;
            } catch {
                showNotification('Could not create youtube channel', 'error');
                return;
            }
        }

        try {
            const response = await microwave.postAdminInfluencer({
                tiktok_user_id: values.tiktokUser?.value,
                instagram_user_id: instagramUserId,
                youtube_channel_id: youtubeChannelId,
                email: values.email,
            });

            if (response.status === 201) {
                helpers.resetForm();
                closeModal();
                onCreated();
                showNotification('Created', 'info');
                return;
            }

            if (Array.isArray(response.data)) {
                showNotification(response.data.join('. '), 'error');
                return;
            }

            helpers.setErrors(
                mapApiErrorsToFormikErrors({
                    ...response.data,
                    tiktokUser: response.data.tiktok_user_id,
                    instagramUser: response.data.instagram_user_id,
                    youtubeChannel: response.data.youtube_channel_id,
                })
            );
        } catch {
            showNotification('Could not create influencer', 'error');
        }
    };

    return (
        <Modal className={styles.modal} closeOnOverlayClick isOpen={isOpen} onClose={closeModal}>
            <Modal.Header>Create Influencer</Modal.Header>
            <Formik
                validationSchema={microwaveInfluencerFormValidationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ isValid, dirty, isSubmitting, submitForm }) => (
                    <>
                        <MicrowaveInfluencerForm />

                        <Modal.Actions className={styles.actions}>
                            <Button
                                appearance="primary"
                                isLoading={isSubmitting}
                                disabled={!isValid || !dirty}
                                onClick={submitForm}
                            >
                                Submit
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default CreateMicrowaveInfluencer;
