import { instagramQueries, microwave } from '@round/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { microwaveQueries } from '@round/api';
import uniq from 'lodash/uniq';
import { isNumber } from 'utility/utility';

const paymentRequestQuery = microwaveQueries.paymentRequest;
const influencerQuery = microwaveQueries.influencer;
const releaseQuery = microwaveQueries.release;

export default function useApproveInstagramPosts(params: microwave.GetPaymentRequestsParams) {
    const { data, status, error } = useQuery({
        ...paymentRequestQuery.options.list({
            ...params,
            platform: 'instagram',
            ordering: `-release_id${params.ordering ? `,${params.ordering}` : ''}`,
        }),
        staleTime: 60 * 1000,
    });

    const influencerIds = uniq(data?.results.map((result) => result.influencer_id)) || [];
    const { data: influencers, isFetching: areInfluencersFetching, error: influencersError } = useQuery({
        ...influencerQuery.options.list({ id: influencerIds.join(','), page_size: influencerIds.length }),
        enabled: (query) => influencerIds.some((id) => !query.state.data?.results.map((inf) => inf.id).includes(id)),
        select: (data) => data.results,
        staleTime: 60 * 1000,
        placeholderData: (query) => query,
    });

    const releaseIds = uniq(data?.results.map((result) => result.release_id)) || [];
    const { data: releases, isFetching: areReleasesFetching, error: releasesError } = useQuery({
        ...releaseQuery.options.list({ id: releaseIds.join(','), page_size: releaseIds.length }),
        enabled: (query) => releaseIds.some((id) => !query.state.data?.results.map((inf) => inf.id).includes(id)),
        select: (data) => data.results,
        staleTime: 60 * 1000,
        placeholderData: (query) => query,
    });

    const audioIds = uniq(data?.results.map((result) => result.instagram_audio_id).filter(isNumber)) || [];
    const { data: audios, isFetching: areAudiosFetching, error: audiosError } = useQuery({
        ...instagramQueries.audio.options.list({ id: audioIds.join(','), page_size: audioIds.length }),
        enabled: (query) => audioIds.some((id) => !query.state.data?.results.map((inf) => inf.id).includes(id)),
        select: (data) => data.results,
        staleTime: 60 * 1000,
        placeholderData: (query) => query,
    });

    return {
        data,
        status,
        error: paymentRequestQuery.utils.list.getErrorResponse(error) || error?.message,
        influencers,
        influencersError: influencerQuery.utils.list.getErrorResponse(influencersError) || influencersError?.message,
        getIsInfluencerLoading: (id: number) =>
            !influencers?.map((inf) => inf.id).includes(id) && areInfluencersFetching,
        releases,
        releasesError: releaseQuery.utils.list.getErrorResponse(releasesError) || releasesError?.message,
        getIsReleaseLoading: (id: number) => !releases?.map((rel) => rel.id).includes(id) && areReleasesFetching,
        audios,
        audiosError: instagramQueries.audio.utils.list.getErrorResponse(audiosError) || audiosError?.message,
        getIsAudioLoading: (id: number | null) =>
            typeof id === 'number' && !audios?.map((aud) => aud.id).includes(id) && areAudiosFetching,
    };
}

const mutationKey = ['microwave-post-approval', 'update-payment-request'];

export const useUpdatePaymentRequest = (id: number) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data: microwave.PatchPaymentRequestBody) => microwave.patchPaymentRequest(id, data),
        mutationKey: [...mutationKey, id],
        onSuccess: async (response) => {
            const ongoingRelatedMutations = queryClient.isMutating({ mutationKey });
            const isOnlyActiveMutation = ongoingRelatedMutations === 1;

            // We only refresh data after the last ongoing mutation has finished to avoid unnecessary refetches and UI disruptions.
            if (response.status === 200 && isOnlyActiveMutation) {
                // Only invalidate the active query to trigger a "background" refetch and keep data unrelated to mutation on the page,
                // but we'll reset the inactive queries to clear their data and make sure it's up to date / loading if the user e.g. changes the filters.
                // This is related to how we are treating pending fetches to be fully loading and display skeletons in the table, but background refetches to be
                // more "silent" and not show skeletons.
                await Promise.allSettled([
                    queryClient.invalidateQueries({ queryKey: paymentRequestQuery.keys.lists(), type: 'active' }),
                    queryClient.resetQueries({ queryKey: paymentRequestQuery.keys.lists(), type: 'inactive' }),
                ]);
            }
        },
    });
};
