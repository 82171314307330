import { microwave } from '@round/api';
import { useDataState } from '@round/utils';

export function useSendChaseEmails() {
    const [state, setState] = useDataState<microwave.PostEmailsResponseData, string[]>({
        status: 'idle',
        data: null,
        error: null,
    });

    const send = async (emailData: microwave.PostEmailsDataItem[]) => {
        const genericError = ['Could not send emails. Please try again later'];
        try {
            setState('loading');
            const response = await microwave.postEmails(emailData);

            if (response.status === 201) {
                setState('success', { data: response.data });
                return response;
            }

            // If the response is 400, but the response data is an object with success or failure keys, we still consider it a success,
            // as it means that the request was successful, but all emails failed to send.
            if (typeof response.data === 'object' && 'success' in response.data && 'failure' in response.data) {
                setState('success', { data: response.data });
                return response;
            }

            if (Array.isArray(response.data)) {
                setState('error', { error: response.data });
                return response;
            }

            setState('error', { error: genericError });

            return response;
        } catch (e) {
            setState('error', { error: genericError });
            throw e;
        }
    };

    const reset = () => {
        setState('idle', { data: null });
    };

    return {
        ...state,
        send,
        reset,
    };
}
