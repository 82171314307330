import useNonNullContext from 'Hooks/useNonNullContext';
import { InstagramCreatorsContext, InstagramCreatorsDispatchContext } from './InstagramCreatorsContext';
import { useCallback, useContext } from 'react';
import { InfluencerPlanContext } from '../InfluencerPlanContext';
import { getInstagramAudios } from '@round/api';

export default function useInstagramInfluencerPlanAudios() {
    const { influencerPlan, fetchInfluencerPlan } = useContext(InfluencerPlanContext);
    const state = useNonNullContext(InstagramCreatorsContext);
    const dispatch = useNonNullContext(InstagramCreatorsDispatchContext);

    const fetchData = useCallback(
        async (audioIds: number[], requestInit?: RequestInit) => {
            if (!audioIds.length) {
                dispatch({ type: 'instagramInfluencerPlanAudiosInitialized', payload: [] });
                return;
            }

            try {
                dispatch({ type: 'loadInstagramInfluencerPlanAudios' });
                const response = await getInstagramAudios({ id: audioIds.join() }, requestInit);
                if (response.status === 404) {
                    dispatch({
                        type: 'setInstagramInfluencerPlanAudiosError',
                        payload: response.data.detail || 'No audios found',
                    });
                    return;
                }

                dispatch({ type: 'instagramInfluencerPlanAudiosInitialized', payload: response.data.results });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({
                    type: 'setInstagramInfluencerPlanAudiosError',
                    payload: 'Could not fetch instagram audios',
                });
            }
        },
        [dispatch]
    );

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            if (!influencerPlan) {
                return;
            }

            return fetchData(influencerPlan.instagram_audios, requestInit);
        },
        [fetchData, influencerPlan]
    );

    const refreshAudios = async () => {
        if (!influencerPlan) {
            return;
        }

        const plan = await fetchInfluencerPlan(influencerPlan.release.id);
        if (!plan) {
            return;
        }

        return fetchData(plan.instagram_audios);
    };

    return {
        init,
        refreshAudios,
        audios: state.instagramInfluencerPlanAudios,
        error: state.instagramInfluencerPlanAudiosError,
        isLoading: state.areInstagramInfluencerPlanAudiosLoading,
        isInitialized: state.areInstagramInfluencerPlanAudiosInitialized,
    };
}
