import { useSessionStorage } from '@round/utils';

export function useSelectedContacted(campaignId: number) {
    const [selected, setSelected] = useSessionStorage<string[]>(
        `mw-ig-contacted-selected-${campaignId}`,
        (selected) => selected || []
    );

    return [selected || [], setSelected] as const;
}
