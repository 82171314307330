import { microwave } from '@round/api';
import { CellContext, RowData } from '@tanstack/react-table';
import { getTableMetaHelper } from '@round/ui-kit';
import { buildCampaignDetailsUrl } from 'Modules/Advertising/Microwave/helpers';
import ReleaseBadge from '../../ReleaseBadge/ReleaseBadge';

export type Meta<TRow extends RowData> = {
    isLoading: boolean;
    getIsReleaseLoading?: (row: TRow) => boolean;
};

const getTableMeta = getTableMetaHelper<Meta<any>>();

const ReleaseCell = <TRow extends RowData>({ getValue, row, table }: CellContext<TRow, microwave.Release | null>) => {
    const { isLoading, getIsReleaseLoading } = getTableMeta(table);

    const release = getValue();

    return (
        <ReleaseBadge
            releaseDetails={{
                name: release?.name,
                artistName: release?.brand_name,
                imageUrl: release?.brand_image,
                linkUrl: release ? buildCampaignDetailsUrl(release.id) : undefined,
            }}
            isLoading={isLoading || getIsReleaseLoading?.(row.original)}
        />
    );
};

export default ReleaseCell;
