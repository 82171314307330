import { useSessionUrlState } from '@round/utils';
import { useEffect } from 'react';
import useRelease from '../../Release/useRelease';

type UrlState = {
    /**
     * Dash separated string to describe open campaign tabs.
     * @property {number} campaignId - id of microwave campaign
     * @property {'creators' | 'posted' | 'contacted'} tab - selected tab name
     * @property {'o' | undefined} open - tab open/expanded flag
     * @example
     *      // ${campaignId}-${tabName}-${open?}
     *      '1400-creators-o'
     * */
    campaignTabs?: string;
};

type HookConfig = {
    shouldPersist?: boolean;
};

export default function useInstagramCampaignsUrlState({ shouldPersist }: HookConfig = {}) {
    const { data: release } = useRelease();

    const { url: urlData, setSearchParams, clearSession, persistSession } = useSessionUrlState<UrlState>(
        'mw-release-page-instagram-campaigns',
        (prevUrlData, sessionData, helpers) => {
            return prevUrlData.searchParams.campaignTabs
                ? prevUrlData
                : helpers.merge(prevUrlData, {
                      searchParams: { campaignTabs: sessionData?.searchParams.campaignTabs },
                  });
        }
    );

    useEffect(() => {
        return () => {
            if (shouldPersist) {
                persistSession({ searchParams: { campaignTabs: urlData.searchParams.campaignTabs } });
            }
        };
    }, [persistSession, shouldPersist, urlData.searchParams.campaignTabs]);

    useEffect(() => {
        return () => {
            if (!release?.id) {
                clearSession();
            }
        };
    }, [clearSession, release?.id]);

    return {
        state: urlData,
        setSearchParams,
    };
}
