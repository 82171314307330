import React from 'react';
import { TiktokInfluencerPlanAudio } from 'App.types';
import BadgeDropdown from '../../../components/BadgeDropdown/BadgeDropdown';
import { ReactComponent as MusicNoteIcon } from 'assets/MusicNote.svg';
import styles from './Audios.module.css';

type AudiosProps = {
    loading?: boolean;
    audios: TiktokInfluencerPlanAudio[];
    onAudioClick?: (audio: TiktokInfluencerPlanAudio) => void;
    disabled?: boolean;
};

const Audios = ({ audios, onAudioClick, loading, disabled }: AudiosProps) => {
    const handleItemClick = (audio: TiktokInfluencerPlanAudio) => {
        if (typeof onAudioClick === 'function') {
            onAudioClick(audio);
        }
    };

    return (
        <BadgeDropdown disabled={disabled} loading={loading} value={audios.length} label="Audios" Icon={MusicNoteIcon}>
            {(setShow) => (
                <ul className={styles.list}>
                    {audios.map((audio) => (
                        <li
                            key={audio.id}
                            className={styles.listItem}
                            onClick={() => {
                                handleItemClick(audio);
                                setShow(false);
                            }}
                        >
                            <span className={styles.audio}>{audio.title}</span>
                        </li>
                    ))}
                </ul>
            )}
        </BadgeDropdown>
    );
};

export default Audios;
