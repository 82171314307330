import { useCallback, useContext, useMemo } from 'react';
import { InfluencerPlanContext } from '../InfluencerPlanContext';
import useNonNullContext from 'Hooks/useNonNullContext';
import { TiktokCreatorsDispatchContext, TiktokCreatorsStateContext } from './TiktokCreatorsContext';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { fetchTiktokInfluencerPlanAudio } from '../../InfluencerPlan.api';
import { getTiktokAudios } from '@round/api';
import uniq from 'lodash/uniq';
import { MicroCampaignContext } from '../../../Microwave/MicroCreators/contexts/MicroCampaign';
import { isNumber } from 'utility/utility';

type HookConfig = {
    isImperative?: boolean;
};

export default function useCreatorPlanTiktokAudios({ isImperative = false }: HookConfig = {}) {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const state = useNonNullContext(TiktokCreatorsStateContext);
    const dispatch = useNonNullContext(TiktokCreatorsDispatchContext);
    const areInfluencerPlanAudiosInitialized = Boolean(
        state.tiktokInfluencerPlanAudiosInitialized || state.tiktokInfluencerPlanAudiosLoadingError
    );

    // hook is used outside of MW Campaign Provider (mobile version)
    // so reading directly from context rather than useMicroCampaigns hook
    // to not throw an error when context is not found
    const microwaveCampaignContext = useContext(MicroCampaignContext);
    const tiktokMicrowaveCampaignAudios = useMemo(
        () =>
            microwaveCampaignContext?.campaigns
                .filter((c) => c.platform === 'tiktok')
                .map((c) => c.tiktok_audio_id)
                .filter(isNumber) ?? [],
        [microwaveCampaignContext?.campaigns]
    );

    const fetchData = useCallback(
        async (requestInit?: RequestInit) => {
            if (!influencerPlan?.id || areInfluencerPlanAudiosInitialized) {
                return;
            }

            try {
                dispatch({ type: 'loadTiktokInfluencerPlanAudios' });
                const response = await fetchTiktokInfluencerPlanAudio(influencerPlan.id, requestInit);
                dispatch({ type: 'tiktokInfluencerPlanAudiosInitialized', payload: response });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                dispatch({
                    type: 'setTiktokInfluencerPlanAudiosLoadingError',
                    payload: "Sorry, we're experiencing technical issues",
                });
            }
        },
        [areInfluencerPlanAudiosInitialized, dispatch, influencerPlan?.id]
    );

    useAbortableEffect(
        (signal) => {
            if (!isImperative) {
                fetchData({ signal });
            }
        },
        [fetchData, isImperative]
    );

    useAbortableEffect(
        (signal) => {
            async function fetchTiktokAudios() {
                const planAudioIds = uniq(
                    state.tiktokInfluencerPlanAudios
                        .map((audio) => audio.audio_id)
                        .concat(state.tiktokInfluencerPostGroups.map((g) => g.audio_id))
                        .filter((id): id is number => typeof id === 'number')
                        .concat(tiktokMicrowaveCampaignAudios)
                );

                const existingAudioIds = state.tiktokAudios.map((a) => a.id);
                const audiosToFetch = planAudioIds.filter((id) => !existingAudioIds.includes(id));
                if (!audiosToFetch.length) {
                    return;
                }

                try {
                    dispatch({ type: 'loadTiktokAudios' });
                    const response = await getTiktokAudios(
                        { id: audiosToFetch.join(), page_size: audiosToFetch.length },
                        { signal }
                    );
                    dispatch({ type: 'tiktokAudiosInitialized', payload: response.data.results });
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        return;
                    }

                    dispatch({
                        type: 'setTiktokAudiosLoadingError',
                        payload: "Sorry, we're experiencing technical issues",
                    });
                }
            }

            if (!isImperative) {
                fetchTiktokAudios();
            }
        },
        [
            dispatch,
            isImperative,
            state.tiktokAudios,
            state.tiktokInfluencerPlanAudios,
            state.tiktokInfluencerPostGroups,
            tiktokMicrowaveCampaignAudios,
        ]
    );

    const reset = () => dispatch({ type: 'resetTiktokInfluencerPlanAudios' });

    return {
        initialized: state.tiktokInfluencerPlanAudiosInitialized,
        loading: state.tiktokInfluencerPlanAudiosLoading,
        error: state.tiktokInfluencerPlanAudiosLoadingError,
        influencerPlanAudios: state.tiktokInfluencerPlanAudios,
        tiktokAudios: state.tiktokAudios,
        tiktokAudiosLoading: state.tiktokAudiosLoading,
        tiktokAudiosLoadingError: state.tiktokAudiosLoadingError,
        fetchData,
        resetInfluencerPlanAudios: reset,
    };
}
