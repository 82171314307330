import { BaseFilterObject, GenericDropdownOption } from 'App.types';
import { mergeStyles, StylesConfig, ValueType, NamedProps as SelectProps } from 'react-select';
import Dropdown from 'ui-new/Dropdown/Dropdown';
import FilterBadgeClearable from 'ui/Filters/FilterBadge/FilterBadgeClearable/FilterBadgeClearable';
import FilterHeader from 'ui/Filters/FilterHeader/FilterHeader';
import { Select } from '@round/ui-kit';
import { ReactNode } from 'react';
import Option from 'ui-new/Select/components/Option';
import Control from './Control/Control';
import styles from './SelectFilter.module.css';

export type Props<T extends GenericDropdownOption<any>, IsMulti extends boolean = false> = {
    isDisabled?: boolean;
    isOpen: boolean;
    onClose: () => void;
    onOpen: () => void;
    onClear: () => void;
    onRemove: () => void;
    renderValue?: (
        value: ValueType<T, IsMulti>,
        props: Omit<Props<T, IsMulti>, 'onClear' | 'onRemove' | 'renderValue' | 'onClose' | 'onOpen'>
    ) => ReactNode;
} & Pick<BaseFilterObject, 'name' | 'icon'> &
    Omit<
        SelectProps<T, IsMulti>,
        | 'name'
        | 'menuIsOpen'
        | 'closeMenuOnSelect'
        | 'blurInputOnSelect'
        | 'menuPortalTarget'
        | 'controlShouldRenderValue'
        | 'hideSelectedOptions'
        | 'isClearable'
    >;

const selectStyles: StylesConfig = {
    control: (base, props) => ({
        ...base,
        border: '1px solid #D1D5DB',
        borderRadius: '0.75rem',
        boxShadow: 'none',
        outline: 'none',
        fontFamily: 'Inter, sans-serif',
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '1.25',
        display: props.selectProps.isSearchable ? 'flex' : 'none',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        columnGap: '0.625rem',
        padding: '0.625rem 0.875rem',
        ':hover': {
            padding: '0.625rem 0.875rem',
        },
    }),
    valueContainer: (base) => ({
        ...base,
        padding: 'none',
    }),
    placeholder: (base) => ({
        ...base,
        color: '#8D8D8D',
    }),
    menu: (base) => ({
        ...base,
        boxShadow: 'none',
        position: 'static',
        border: 'none',
        width: '16.25rem',
    }),
    menuList: (base) => ({
        ...base,
        fontFamily: 'Inter, sans-serif',
        lineHeight: 1.25,
        color: '#111111',
        '::-webkit-scrollbar': {
            width: '0.375rem',
            height: '0.375rem',
            background: 'transparent',
        },
        '::-webkit-scrollbar-thumb': {
            background: '#D1D5DB',
            borderRadius: '0.5rem',
        },
    }),
    option: (base) => ({
        ...base,
        background: 'white',
        paddingLeft: 0,
        paddingRight: 0,
        ':hover': {
            background: '#F3F4F6',
        },
    }),
};

const defaultRenderValue: Props<any, any>['renderValue'] = (value, props) => {
    if (props.isMulti) {
        return (
            <>
                <span className={styles.countBadge}>{value?.length ?? 0}</span>
                <span>{props.name}</span>
            </>
        );
    }

    return (
        <>
            {props.name}: {props.getOptionLabel && value ? props.getOptionLabel(value) : value?.label ?? ''}
        </>
    );
};

const SelectFilter = <T extends GenericDropdownOption<any>, IsMulti extends boolean = false>({
    onClear,
    onRemove,
    name,
    icon,
    isOpen,
    onClose,
    onOpen,
    renderValue = defaultRenderValue,
    isDisabled,
    ...props
}: Props<T, IsMulti>) => {
    const mergedStyles = props.styles ? mergeStyles(selectStyles, props.styles) : selectStyles;
    const hasValue = props.isMulti ? Boolean((props.value as ValueType<T, true>)?.length) : Boolean(props.value);

    return (
        <Dropdown
            isOpen={isOpen}
            onClose={onClose}
            control={
                <FilterBadgeClearable isDisabled={isDisabled} onClick={onOpen} onClear={onRemove}>
                    {renderValue(props.value, { ...props, name, icon, isOpen })}
                </FilterBadgeClearable>
            }
        >
            <FilterHeader name={name} icon={icon} hasValue={hasValue} onReset={onClear} />

            <Select
                menuIsOpen
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
                menuPortalTarget={null}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                placeholder="Search"
                {...props}
                styles={mergedStyles}
                components={{ DropdownIndicator: null, Control, Option, ...props.components }}
            />
        </Dropdown>
    );
};

export default SelectFilter;
