import React from 'react';
import Badge, { Props as BadgeProps } from 'ui-new/Badges/Badge/Badge';

export type FilterBadgeProps = {
    renderLeft?: React.ReactNode;
    renderRight?: React.ReactNode;
} & Pick<BadgeProps, 'onClick' | 'isDisabled' | 'className' | 'children'>;

const FilterBadge = ({ children, renderLeft, renderRight, className, onClick, isDisabled }: FilterBadgeProps) => {
    return (
        <Badge
            isDisabled={isDisabled}
            className={className}
            onClick={(e) => {
                if (!isDisabled) {
                    onClick?.(e);
                }
            }}
        >
            {renderLeft}
            {children}
            {renderRight}
        </Badge>
    );
};

export default FilterBadge;
