import { GenericDropdownOption } from '../App.types';
import countries from './countries';

export const countryOptions: GenericDropdownOption<string>[] = Object.entries(countries).map(([code, name]) => ({
    value: code,
    label: name,
}));

export const DEFAULT_INITIAL_PAGE_SIZE_OPTION: GenericDropdownOption<number> = {
    label: '10',
    value: 10,
};

export const defaultPageSizeOptions: GenericDropdownOption<number>[] = [
    DEFAULT_INITIAL_PAGE_SIZE_OPTION,
    { label: '25', value: 25 },
    { label: '35', value: 35 },
    { label: '50', value: 50 },
];

export const getPageSizeOptions = (pageSizes: number[]): GenericDropdownOption<number>[] =>
    pageSizes.map((size) => ({ value: size, label: size.toString() }));

export const MAX_PAYPAL_API_PAYMENT_AMOUNT = 1000;
