import {
    buildCoreKeyFactory,
    buildDetailKeyFactories,
    buildErrorResponseGetter,
    buildListKeyFactories,
    parseQueryKey,
} from '../../queries.helpers';
import { queryOptions, skipToken } from '@tanstack/react-query';
import { getInfluencerLocation, getInfluencerLocations, GetInfluencerLocationsParams } from './locations.api';
import { ExpectedResponseError } from '../../utils';

export const CORE_KEY = 'influencer-location';

export const keys = {
    all: buildCoreKeyFactory(CORE_KEY),
    ...buildListKeyFactories<GetInfluencerLocationsParams, typeof CORE_KEY>(CORE_KEY),
    ...buildDetailKeyFactories<number, typeof CORE_KEY>(CORE_KEY),
};

export const options = {
    list: (params: GetInfluencerLocationsParams) =>
        queryOptions({
            queryKey: keys.list(params),
            queryFn: async ({ signal, queryKey }) => {
                const { params } = parseQueryKey(queryKey);

                const response = await getInfluencerLocations(params, { signal });
                if (response.status === 200) {
                    return response.data;
                }

                throw new ExpectedResponseError('Failed to fetch influencer locations', response);
            },
            staleTime: Infinity,
            gcTime: Infinity,
        }),
    detail: (id: number | undefined | null) =>
        queryOptions({
            queryKey: typeof id === 'number' ? keys.detail(id) : keys.details(),
            queryFn:
                typeof id !== 'number'
                    ? skipToken
                    : async ({ signal }) => {
                          const response = await getInfluencerLocation(id, { signal });
                          if (response.status === 200) {
                              return response.data;
                          }

                          throw new ExpectedResponseError('Failed to fetch influencer location', response);
                      },
            staleTime: Infinity,
            gcTime: Infinity,
        }),
};

export const utils = {
    detail: {
        getErrorResponse: buildErrorResponseGetter<Awaited<ReturnType<typeof getInfluencerLocation>>>(),
    },
};
