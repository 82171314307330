import React, { useLayoutEffect, useState } from 'react';
import useFilterManager, { Filter, Options } from './useFilterManager';
import styles from './Filters.module.css';
import FilterMenu from './FiltersMenu/FiltersMenu';
import cn from 'classnames';

type Props<T extends string> = {
    sessionKey: string;
    filters: Filter<T>[];
    options?: Options<T>;
    className?: string;
    isDisabled?: boolean;
    hint?: string;
};

const Filters = <T extends string>({ sessionKey, filters, options, className, isDisabled, hint }: Props<T>) => {
    const manager = useFilterManager(sessionKey, filters, options);
    const [filtersContainer, setFiltersContainer] = useState<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        if (!filtersContainer) {
            return;
        }

        const observer = new MutationObserver((mutations) => {
            const [mutation] = mutations;

            if (mutation.addedNodes.length === 1) {
                const addedElement = mutation.addedNodes.item(0) as HTMLElement | null;
                addedElement?.scrollIntoView(false);
            }
        });

        observer.observe(filtersContainer, { childList: true });

        return () => observer.disconnect();
    }, [filtersContainer]);

    return (
        <div className={cn(styles.container, className)}>
            <div ref={setFiltersContainer} className={styles.filters}>
                {manager.selectedFilters.map((filter) => {
                    return (
                        <React.Fragment key={filter.name}>
                            {filter.render({
                                isOpen: manager.isOpen(filter.name),
                                open: () => manager.setOpen(filter.name, true),
                                close: () => manager.setOpen(filter.name, false),
                                remove: () => manager.setSelected(filter.name, false),
                                isDisabled,
                            })}
                        </React.Fragment>
                    );
                })}
            </div>

            <FilterMenu
                isDisabled={isDisabled}
                hint={hint}
                filters={manager.unselectedFilters}
                onSelect={(filter) => {
                    manager.setSelected(filter.name, true);
                    manager.setOpen(filter.name, true);
                }}
            />
        </div>
    );
};

export default Filters;
