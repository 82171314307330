import { Image, SkeletonizedValue } from '@round/ui-kit';
import styles from './ReleaseBadge.module.css';
import cn from 'classnames';

type Details = Partial<{
    name: string;
    artistName: string;
    imageUrl: string;
    linkUrl?: string;
}>;

type Props = {
    releaseDetails: Details | null;
    isLoading?: boolean;
    className?: string;
};

const ReleaseBadge = ({ releaseDetails, isLoading, className }: Props) => (
    <div className={cn(styles.container, className)}>
        <Image src={releaseDetails?.imageUrl} className={styles.image} loading={isLoading} />
        <div>
            <SkeletonizedValue isInitialized={!isLoading} width="10rem">
                <a
                    className={styles.title}
                    onClick={(e) => e.stopPropagation()}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={releaseDetails?.linkUrl}
                >
                    {releaseDetails?.name ?? '-'}
                </a>
            </SkeletonizedValue>
            <SkeletonizedValue isInitialized={!isLoading} width="10rem">
                <div>{releaseDetails?.artistName ?? '-'}</div>
            </SkeletonizedValue>
        </div>
    </div>
);

export default ReleaseBadge;
