import { ApiResponse, PaginatedRequest, ClientErrorApiResponse, ClientErrorResponseCode } from './types';

export enum MonitoredAudioTrackingTypes {
    Basic = 1,
    Enhanced = 2,
    Engagement = 3,
}

export const MonitoredAudioTrackingTypeLabels = new Map([
    [MonitoredAudioTrackingTypes.Basic, 'Normal'],
    [MonitoredAudioTrackingTypes.Engagement, 'Engagement'],
    [MonitoredAudioTrackingTypes.Enhanced, 'Enhanced'],
]);

export function buildApiUrl(url: string) {
    let resultUrl = url;
    const origin = process.env.REACT_APP_API_DOMAIN || '';

    if (!resultUrl.startsWith(origin)) {
        resultUrl = `${origin}${url}`;
    }

    return resultUrl;
}

export const getPaginatedIdQueryParam = <TIdentifier extends number | string>(ids: TIdentifier[], pageSize: number) => {
    let idBatches = [];
    for (var i = 0; i < ids.length; i += pageSize) {
        idBatches.push(ids.slice(i, i + pageSize));
    }

    return idBatches;
};

export const createBatchedParamsArrayFromIds = <K extends number | string, P extends {}>(
    ids: K[],
    getParams: (batch: K[]) => P extends Partial<PaginatedRequest> ? never : P,
    batchSize: number = 50
): (P & {
    page_size: number;
})[] => {
    const batches = getPaginatedIdQueryParam(ids, batchSize).map((batch) => {
        return { ...getParams(batch), page_size: batch.length };
    });

    return batches;
};

export class ExpectedResponseError<
    TResponse extends ClientErrorApiResponse<ApiResponse<any, ClientErrorResponseCode>>
> extends Error {
    response: TResponse;

    constructor(message: string, response: TResponse) {
        super(message);
        this.response = response;
        Object.setPrototypeOf(this, ExpectedResponseError.prototype);
    }
}

export function isExpectedResponseError<TResponse extends ClientErrorApiResponse<any>>(
    error: Error | ExpectedResponseError<TResponse> | null | undefined
): error is ExpectedResponseError<ClientErrorApiResponse<TResponse>> {
    return error instanceof ExpectedResponseError;
}
