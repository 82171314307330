import { queryOptions } from '@tanstack/react-query';
import {
    buildListKeyFactories,
    buildCoreKeyFactory,
    buildDetailKeyFactories,
    parseQueryKey,
    buildErrorResponseGetter,
} from '../../queries.helpers';
import { getInfluencer, getMicrowaveInfluencers, GetMicrowaveInfluencersParams } from './influencers.api';
import { ExpectedResponseError } from '../../utils';

export const CORE_KEY = 'microwave-influencer';

export const keys = {
    all: buildCoreKeyFactory(CORE_KEY),
    ...buildListKeyFactories<GetMicrowaveInfluencersParams, typeof CORE_KEY>(CORE_KEY),
    ...buildDetailKeyFactories<number, typeof CORE_KEY>(CORE_KEY),
};

export const options = {
    list: (params: GetMicrowaveInfluencersParams) =>
        queryOptions({
            queryKey: keys.list(params),
            queryFn: async ({ signal, queryKey }) => {
                const { params } = parseQueryKey(queryKey);

                // We haven't computed any expected error types in the response yet, so we'll just return as is
                return (await getMicrowaveInfluencers(params, { signal })).data;
            },
        }),
    detail: (id: number) =>
        queryOptions({
            queryKey: keys.detail(id),
            queryFn: async ({ signal, queryKey }) => {
                const { params: id } = parseQueryKey(queryKey);

                const response = await getInfluencer(id, { signal });

                if (response.status === 200) {
                    return response.data;
                }

                throw new ExpectedResponseError('Failed to fetch influencers', response);
            },
        }),
};

export const utils = {
    list: {
        getErrorResponse: buildErrorResponseGetter<Awaited<ReturnType<typeof getMicrowaveInfluencers>>>(),
    },
    detail: {
        getErrorResponse: buildErrorResponseGetter<Awaited<ReturnType<typeof getInfluencer>>>(),
    },
};
