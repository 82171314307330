import useInstagramPosted from './useInstagramPosted';
import InstagramPostedTable from './InstagramPostedTable/InstagramPostedTable';
import { useMemo } from 'react';
import { mapOrderingToTableSorting, mapTableSortingToOrdering } from 'ui/WrapperTable/helpers';
import useInstagramPostedUrlState from './useInstagramPostedUrlState';

type Props = {
    campaignId: number;
};

const InstagramPosted = ({ campaignId }: Props) => {
    const { state: urlState, mergeSearchParams } = useInstagramPostedUrlState({ campaignId, shouldPersist: true });

    const {
        data,
        status,
        error,
        creatorsData,
        audiosData,
        instagramUserImagesData,
        getIsAccountDataLoading,
        reset,
    } = useInstagramPosted({ params: { campaign_id: campaignId, ...urlState } });

    const rows = useMemo(() => {
        return (data?.results ?? []).map((invite) => {
            const creator = creatorsData[invite.influencer_id]?.data ?? null;
            return {
                ...invite,
                creator,
                audio: invite.instagram_audio_id ? audiosData[invite.instagram_audio_id]?.data ?? null : null,
                image: creator?.instagram_user_id
                    ? instagramUserImagesData[creator.instagram_user_id]?.data ?? null
                    : null,
            };
        });
    }, [audiosData, creatorsData, data?.results, instagramUserImagesData]);

    return (
        <InstagramPostedTable
            data={rows}
            page={urlState.page}
            setPage={(page) => {
                mergeSearchParams({ page });
                reset();
            }}
            pageSize={urlState.page_size}
            setPageSize={(pageSize) => {
                mergeSearchParams({ page_size: pageSize });
                reset();
            }}
            count={data?.count ?? 0}
            noDataLabel={status === 'error' ? error : 'No data'}
            meta={{ isLoading: status === 'loading' || status === 'idle', getIsAccountDataLoading }}
            sorting={mapOrderingToTableSorting([urlState.ordering])}
            onSortingChange={(sorting) => mergeSearchParams({ ordering: mapTableSortingToOrdering(sorting) })}
        />
    );
};

export default InstagramPosted;
