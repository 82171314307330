import React, { useState } from 'react';
import styles from './EditMicrowaveInfluencer.module.css';
import Button from 'ui/Button/Button';
import Modal from 'ui-new/Modals/Modal/Modal';
import { Formik, FormikHelpers } from 'formik';
import { microwave, postInstagramUser, youtube } from '@round/api';
import { showNotification } from '../../../../../helpers';
import { mapApiErrorsToFormikErrors } from '../../../../../utility/utility';
import { mapTiktokUserToOption } from 'Modules/TikTok/hooks/useTiktokUserSelect';
import MicrowaveInfluencerForm, {
    microwaveInfluencerFormValidationSchema,
    MicrowaveInfluencerFormValues,
} from '../InfluencerForm/MicrowaveInfluencerForm';
import useInstagramUser from 'Modules/Instagram/hooks/useInstagramUser';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { useTiktokUser } from 'Modules/TikTok/hooks/useTiktokUser';
import { mapYoutubeChannelToOption } from 'Modules/Youtube/hooks/useYoutubeChannelSelect';

type Props = {
    influencer: microwave.AdminInfluencer | null;
    isOpen: boolean;
    closeModal: () => void;
    onUpdated: () => void;
};

const EditMicrowaveInfluencer = ({ influencer, isOpen, closeModal, onUpdated }: Props) => {
    const {
        user: instagramUser,
        isInitialized: isInstagramUserInitialized,
        init,
        reset: resetInstagramUser,
    } = useInstagramUser(influencer?.instagram_user_id);

    useAbortableEffect(
        (signal) => {
            if (!isInstagramUserInitialized && influencer?.instagram_user_id) {
                init({ signal });
            }
        },
        [init, isInstagramUserInitialized, influencer?.instagram_user_id]
    );

    const {
        data: tiktokUser,
        status: tiktokUserFetchStatus,
        fetchData: fetchTiktokUser,
        reset: resetTiktokUser,
    } = useTiktokUser();

    const isTiktokUserInitialized = tiktokUserFetchStatus === 'success' || tiktokUserFetchStatus === 'error';

    useAbortableEffect(
        (signal) => {
            if (!isTiktokUserInitialized && influencer?.tiktok_user_id) {
                fetchTiktokUser(influencer.tiktok_user_id, { signal }).catch(() => {});
            }
        },
        [fetchTiktokUser, influencer?.tiktok_user_id, isTiktokUserInitialized]
    );

    const [youtubeChannel, setYoutubeChannel] = useState<youtube.Channel | null>(null);
    const [isYoutubeChannelInitialized, setIsYoutubeChannelInitialized] = useState(false);

    useAbortableEffect(
        (signal) => {
            if (
                !isYoutubeChannelInitialized &&
                influencer?.youtube_channel_id &&
                influencer?.youtube_channel_id !== youtubeChannel?.id
            ) {
                youtube
                    .getChannel(influencer?.youtube_channel_id, { signal })
                    .then((response) => {
                        if (response.status === 200) {
                            setYoutubeChannel(response.data);
                        }

                        setIsYoutubeChannelInitialized(true);
                    })
                    .catch((e) => {
                        if (e instanceof Error && e.name === 'AbortError') {
                            return;
                        }

                        setIsYoutubeChannelInitialized(true);
                    });
            }
        },
        [influencer?.youtube_channel_id, isYoutubeChannelInitialized, youtubeChannel?.id]
    );

    const initialValues: MicrowaveInfluencerFormValues = {
        tiktokUser: tiktokUser ? mapTiktokUserToOption(tiktokUser) : null,
        instagramUser: instagramUser ? { value: instagramUser?.id, label: instagramUser.username } : null,
        youtubeChannel: youtubeChannel ? mapYoutubeChannelToOption(youtubeChannel) : null,
        email: influencer?.email ?? '',
    };

    const handleSubmit = async (
        values: MicrowaveInfluencerFormValues,
        helpers: FormikHelpers<MicrowaveInfluencerFormValues>
    ) => {
        if (!influencer?.id) {
            return;
        }

        let instagramUserId = values.instagramUser?.value;

        if (values.isInstagramUserCreateMode && values.instagramUserIdentifier) {
            try {
                const instagramUserResponse = await postInstagramUser({
                    identifier: values.instagramUserIdentifier,
                });

                if (instagramUserResponse.status === 400) {
                    helpers.setFieldError(
                        'instagramUserIdentifier',
                        Array.isArray(instagramUserResponse.data.identifier)
                            ? instagramUserResponse.data.identifier.join('. ')
                            : instagramUserResponse.data.identifier
                    );
                    helpers.setSubmitting(false);
                    return;
                }

                instagramUserId = instagramUserResponse.data.id;
            } catch {
                showNotification('Could not create instagram user', 'error');
                return;
            }
        }

        let youtubeChannelId = values.youtubeChannel?.value;

        if (values.isYoutubeChannelCreateMode && values.youtubeChannelIdentifier) {
            try {
                const youtubeChannelResponse = await youtube.postYoutubeChannel({
                    identifier: values.youtubeChannelIdentifier,
                });

                if (youtubeChannelResponse.status === 400) {
                    helpers.setFieldError(
                        'youtubeChannelIdentifier',
                        Array.isArray(youtubeChannelResponse.data.identifier)
                            ? youtubeChannelResponse.data.identifier.join('. ')
                            : youtubeChannelResponse.data.identifier
                    );
                    helpers.setSubmitting(false);
                    return;
                }

                youtubeChannelId = youtubeChannelResponse.data.id;
            } catch {
                showNotification('Could not create youtube channel', 'error');
                return;
            }
        }

        const cleanedValues = {
            tiktok_user_id: values.tiktokUser?.value,
            instagram_user_id: instagramUserId,
            youtube_channel_id: youtubeChannelId,
            email: values.email,
        };

        const changedValues = Object.fromEntries(
            Object.entries(cleanedValues).filter(
                ([key, val]) => key in influencer && influencer[key as keyof microwave.AdminInfluencer] !== val
            )
        );

        try {
            helpers.setSubmitting(true);
            const response = await microwave.patchAdminInfluencer(influencer?.id, changedValues);
            if (response.status === 200) {
                helpers.resetForm();
                handleClose();
                onUpdated();
                showNotification('Updated', 'info');
                return;
            }

            if (Array.isArray(response.data)) {
                showNotification(response.data.join('. '), 'error');
                return;
            }

            helpers.setErrors(
                mapApiErrorsToFormikErrors({
                    ...response.data,
                    tiktokUser: response.data.tiktok_user_id,
                    instagramUser: response.data.instagram_user_id,
                    youtubeChannel: response.data.youtube_channel_id,
                })
            );
        } catch {
            showNotification('Could not update influencer', 'error');
        }
    };

    const handleClose = () => {
        resetInstagramUser();
        resetTiktokUser();
        setYoutubeChannel(null);
        setIsYoutubeChannelInitialized(false);
        closeModal();
    };

    return (
        <Modal className={styles.modal} closeOnOverlayClick isOpen={isOpen} onClose={handleClose}>
            <Modal.Header>Edit Influencer</Modal.Header>
            <Formik
                validationSchema={microwaveInfluencerFormValidationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isValid, dirty, isSubmitting, submitForm }) => (
                    <>
                        <MicrowaveInfluencerForm />

                        <Modal.Actions className={styles.actions}>
                            <Button
                                appearance="primary"
                                isLoading={isSubmitting}
                                disabled={!isValid || !dirty}
                                onClick={submitForm}
                            >
                                Submit
                            </Button>
                        </Modal.Actions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};

export default EditMicrowaveInfluencer;
