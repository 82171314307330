import { ControlledPopover, Select } from '@round/ui-kit';
import { Dispatch, SetStateAction, useState } from 'react';
import styles from './CreatorbaseProjectLinkDropdown.module.css';
import useOnClickOutside from 'Hooks/useOnClickOutside';
import useProjectSelect from './hooks/useProjectSelect';
import { StylesConfig } from 'react-select';

type TargetProps = {
    ref: Dispatch<SetStateAction<HTMLElement | null>>;
    onClick: () => void;
};

type Props = {
    children: (targetProps: TargetProps) => React.ReactNode;
    className?: string;
};

const selectStyles: StylesConfig = {
    container: (base) => ({
        ...base,
        width: '100%',
    }),
    control: (base) => ({
        ...base,
        margin: '0 0.5rem',
    }),
    menu: (base) => ({
        ...base,
        boxShadow: 'none',
        position: 'static',
        border: 'none',
        borderTop: '1px solid #D1D5DB',
        borderBottom: '1px solid #D1D5DB',
        borderRadius: '0',
        width: '100%',
    }),
};

const CreatorbaseProjectLinkDropdown = ({ children, className }: Props) => {
    const { props: selectProps } = useProjectSelect({});

    const [targetRef, setTargetRef] = useState<HTMLElement | null>(null);
    const [popoutRef, setPopoutRef] = useState<HTMLDivElement | null>(null);

    const [isOpen, setIsOpen] = useState(false);

    useOnClickOutside(popoutRef, () => setIsOpen(false));

    return (
        <>
            {children({ ref: setTargetRef, onClick: () => setIsOpen(!isOpen) })}

            <ControlledPopover
                ref={setPopoutRef}
                anchorElement={targetRef}
                isOpen={isOpen}
                options={{ placement: 'bottom', modifiers: [{ name: 'offset', options: { offset: [0, 8] } }] }}
            >
                <div className={styles.container}>
                    <Select
                        {...selectProps}
                        styles={selectStyles}
                        placeholder="Search projects..."
                        isSearchable
                        menuPortalTarget={null}
                        menuPlacement="bottom"
                        components={{ DropdownIndicator: null }}
                        menuIsOpen
                    />
                </div>
            </ControlledPopover>
        </>
    );
};

export default CreatorbaseProjectLinkDropdown;
