import { AppServer } from '../../mirage';
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { ReleaseStats } from './releaseStats.types';
import { createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    microwaveReleaseStats: Model as ModelDefinition<ReleaseStats>,
};

export const serializers = {
    microwaveReleaseStats: makeSerializer<ReleaseStats>([]),
};

export const factories = {
    microwaveReleaseStats: createFactory<ReleaseStats>({
        cpm: 0,
        amount_spent: 0,
        engagement_rate: 0,
        post_count: 0,
        total_views: 0,
    }),
};

export function handleRequests(server: AppServer) {
    server.get('/api/microwave/releases/:releaseId/stats/', (schema) => {
        schema.create('microwaveReleaseStats');
        return schema.first('microwaveReleaseStats');
    });
}
