import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './Badge.module.css';

export type Props = { isDisabled?: boolean; isInteractive?: boolean } & DetailedHTMLProps<
    HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
>;

const Badge = forwardRef<HTMLSpanElement, Props>(
    (
        { isDisabled, onClick, onAuxClick, isInteractive = (!!onClick || !!onAuxClick) && !isDisabled, ...props },
        ref
    ) => (
        <span
            ref={ref}
            {...props}
            className={cn(
                styles.badge,
                { [styles.disabled]: isDisabled, [styles.interactive]: isInteractive },
                props.className
            )}
            onClick={onClick}
            onAuxClick={onAuxClick}
        >
            {props.children}
        </span>
    )
);

export default Badge;
