import { useFormikContext } from 'formik';
import BaseErrorMessage from 'ui-new/ErrorMessage/ErrorMessage';

type ErrorMessageProps = { name: string };

const ErrorMessage = ({ name }: ErrorMessageProps) => {
    const error = useFormikContext<any>().getFieldMeta(name).error;

    if (!error) {
        return null;
    }

    return <BaseErrorMessage>{error}</BaseErrorMessage>;
};

export default ErrorMessage;
