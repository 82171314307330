import { microwave, PaginatedApiResponseData } from '@round/api';
import { DataState, ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

type State = Record<number, DataState<PaginatedApiResponseData<microwave.MicrowaveInfluencer> | null> | undefined>;

type Actions =
    | ReducerActionWithPayload<'creatorsIdle', number>
    | ReducerActionWithPayload<'loadCreators', number>
    | ReducerActionWithPayload<
          'creatorsInitialized',
          {
              campaignId: number;
              data: PaginatedApiResponseData<microwave.MicrowaveInfluencer>;
          }
      >
    | ReducerActionWithPayload<'errorLoadingCreators', { campaignId: number; message: string }>
    | ReducerAction<'resetCreators'>
    | ReducerActionWithPayload<'resetCampaignCreators', number>
    | ReducerActionWithPayload<'updateCreator', microwave.MicrowaveInfluencer>;

export const initialState: State = {};

export const reducer = createReducer<State, Actions>({
    creatorsIdle: (state, { payload: campaignId }) => ({
        ...state,
        [campaignId]: {
            data: state[campaignId]?.data ?? null,
            error: null,
            status: 'idle',
        },
    }),
    loadCreators: (state, { payload: campaignId }) => ({
        ...state,
        [campaignId]: {
            data: state[campaignId]?.data ?? null,
            status: 'loading',
            error: null,
        },
    }),
    errorLoadingCreators: (state, { payload: { campaignId, message } }) => ({
        ...state,
        [campaignId]: {
            data: state[campaignId]?.data ?? null,
            error: message,
            status: 'error',
        },
    }),
    creatorsInitialized: (state, { payload: { campaignId, data } }) => {
        return {
            ...state,
            [campaignId]: {
                data,
                error: null,
                status: 'success',
            },
        };
    },
    resetCreators: () => initialState,
    resetCampaignCreators: (state, { payload: campaignId }) => {
        return {
            ...state,
            [campaignId]: undefined,
        };
    },
    updateCreator: (state, { payload: creator }) => {
        return Object.entries(state).reduce((newState, [campaignId, data]) => {
            return {
                ...newState,
                [Number(campaignId)]: {
                    ...data,
                    data: data?.data
                        ? {
                              ...data.data,
                              results: data.data.results.map((c) => (c.id === creator.id ? creator : c)),
                          }
                        : data?.data,
                },
            } as State;
        }, {} as State);
    },
});
