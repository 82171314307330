import { microwave } from '@round/api';
import { OptionsParams, useSelect, UseSingleValueSelectParams } from 'Hooks/useSelect';
export type ReleaseOption = {
    value: number;
    label: string;
    brandName: string;
    clientName: string;
    image: string;
};

const mapToOption = (release: microwave.Release): ReleaseOption => ({
    value: release.id,
    label: release.name,
    brandName: release.brand_name,
    clientName: release.client_name,
    image: release.brand_image,
});

export const buildFetchOptions = (
    releaseParams: Omit<microwave.GetReleasesParams, 'page' | 'page_size' | 'search'>
) => (params: OptionsParams, requestInit?: RequestInit) => fetchOptions({ ...releaseParams, ...params }, requestInit);

const fetchOptions = async (params: microwave.GetReleasesParams, requestInit?: RequestInit) => {
    const response = await microwave.getReleases(params, requestInit);

    if (response.status !== 200) {
        throw new Error('Could not fetch releases');
    }

    return {
        ...response.data,
        results: response.data.results.map((release) => {
            return mapToOption(release);
        }),
    };
};

const fetchInitialValue = async (id: number, requestInit?: RequestInit) => {
    const response = await microwave.getRelease(id, requestInit);

    if (response.status !== 200) {
        throw new Error('Could not find initial release');
    }

    return mapToOption(response.data);
};

type Params = Omit<UseSingleValueSelectParams<ReleaseOption>, 'isMulti' | 'fetchOptions'> & {
    fetchOptions?: typeof fetchOptions;
};

export default function useReleaseSelect(params: Params) {
    return useSelect({
        ...params,
        isMulti: false,
        fetchOptions: params.fetchOptions || fetchOptions,
        fetchInitialValue,
    });
}
