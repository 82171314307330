import styles from './CountBadge.module.css';
import cn from 'classnames';
import Spinner from 'ui-new/Loading/Spinner/Spinner';

type Props = {
    count: number | string;
    isLoading?: boolean;
    className?: string;
};

const CountBadge = ({ count, isLoading, className }: Props) => {
    return (
        <span className={cn(styles.badge, className)}>
            {isLoading ? <Spinner className={styles.spinner} /> : count}
        </span>
    );
};

export default CountBadge;
