import { DataState, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

export type CountsData = {
    contacted: number | null;
    posted: number | null;
};

export type State = { [campaignId: number]: DataState<CountsData | null> | undefined };

type Actions =
    | ReducerActionWithPayload<'loadCampaignCounts', number>
    | ReducerActionWithPayload<'errorLoadingCampaignCounts', { campaignId: number; error: string }>
    | ReducerActionWithPayload<'campaignCountsInitialized', { campaignId: number; data: CountsData }>
    | ReducerActionWithPayload<'resetCampaignCounts', number>;

export const initialState: State = {};

export const reducer = createReducer<State, Actions>({
    loadCampaignCounts: (state, { payload: campaignId }) => ({
        ...state,
        [campaignId]: {
            data: state[campaignId]?.data ?? null,
            status: 'loading',
            error: null,
        },
    }),
    errorLoadingCampaignCounts: (state, { payload: { campaignId, error } }) => ({
        ...state,
        [campaignId]: {
            data: state[campaignId]?.data ?? null,
            error: error,
            status: 'error',
        },
    }),
    campaignCountsInitialized: (state, { payload: { campaignId, data } }) => ({
        ...state,
        [campaignId]: {
            data: data,
            status: 'success',
            error: null,
        },
    }),
    resetCampaignCounts: (state, { payload: campaignId }) => {
        return {
            ...state,
            [campaignId]: undefined,
        };
    },
});
