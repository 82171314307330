import React, { useCallback, useEffect, useState } from 'react';
import Modal, { ModalContent, ModalFooter, ModalTitle } from '../../../../../ui/General/Modal/Modal';
import { Formik, FormikHelpers, Form, Field } from 'formik';
import FormField from '../../../../../ui/DataEntry/FormField/FormField';
import Label from '../../../../../ui/DataEntry/Label/Label';
import Button from '../../../../../ui/Buttons/Button/Button';
import styles from './EditBriefModal.module.css';
import { InfluencerPostGroup, YoutubeInfluencerPostGroup } from '@round/api';
import { showNotification } from '../../../../../helpers';
import cn from 'classnames';

type BriefValues = Partial<Pick<InfluencerPostGroup, 'brief' | 'brief_turnaround'>>;

type EditBriefModalProps = {
    isModalOpen: boolean;
    updateBrief: (groupId: number, data: BriefValues) => Promise<any>;
    closeModal: () => void;
    group: InfluencerPostGroup | YoutubeInfluencerPostGroup | null;
};

const EditBriefModal = ({ isModalOpen, updateBrief, closeModal, group }: EditBriefModalProps) => {
    const [isConfirmExitModalOpen, setIsConfirmExitModalOpen] = useState(false);
    const [initialValues, setInitialValues] = useState<BriefValues>({
        brief: null,
        brief_turnaround: null,
    });

    useEffect(() => {
        if (!isModalOpen || !group) {
            setInitialValues({
                brief: null,
                brief_turnaround: null,
            });
            return;
        }
        setInitialValues({
            brief: group.brief,
            brief_turnaround: group.brief_turnaround,
        });
    }, [isModalOpen, group]);

    const handleSubmit = useCallback(
        async (values: BriefValues, { setSubmitting }: FormikHelpers<BriefValues>) => {
            if (!group) {
                return;
            }

            try {
                setSubmitting(true);
                await updateBrief(group.id, {
                    brief: values.brief ? values.brief : null,
                    brief_turnaround: values.brief_turnaround ? values.brief_turnaround : null,
                });
                closeModal();
            } catch {
                showNotification('Error updating brief', 'error');
            } finally {
                setSubmitting(false);
            }
        },
        [updateBrief, closeModal, group]
    );

    const [isFormDirty, setIsFormDirty] = useState(false);

    const handleClose = useCallback(() => {
        if (!isFormDirty) {
            closeModal();
            return;
        }
        setIsConfirmExitModalOpen(true);
    }, [isFormDirty, closeModal]);

    const handleConfirmExit = useCallback(() => {
        setIsConfirmExitModalOpen(false);
        closeModal();
    }, [closeModal]);

    return (
        <>
            <Modal
                isOpen={isModalOpen}
                closeOnOverlayClick
                onClose={handleClose}
                modalClassName={cn(styles.modal, { [styles.hidden]: isConfirmExitModalOpen })}
            >
                <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                    {({ submitForm, isSubmitting, dirty }) => (
                        <>
                            {dirty ? setIsFormDirty(true) : setIsFormDirty(false)}
                            <ModalTitle>Edit Brief</ModalTitle>
                            <ModalContent>
                                <Form className={styles.form}>
                                    <FormField>
                                        <Label htmlFor="brief">Brief</Label>
                                        <Field
                                            name="brief"
                                            as="textarea"
                                            placeholder="Write brief"
                                            className={styles.briefField}
                                            rows="10"
                                            maxLength="150000"
                                        />
                                    </FormField>
                                    <FormField>
                                        <Label htmlFor="brief_turnaround">Turnaround</Label>
                                        <Field
                                            name="brief_turnaround"
                                            as="textarea"
                                            placeholder="Write turnaround"
                                            className={styles.turnaroundField}
                                            rows="1"
                                            maxLength="1500"
                                        />
                                    </FormField>
                                </Form>
                            </ModalContent>
                            <ModalFooter align="left">
                                <Button
                                    type="filled"
                                    color="black"
                                    loading={isSubmitting}
                                    onClick={submitForm}
                                    disabled={!dirty}
                                >
                                    Save
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </Formik>
            </Modal>
            <Modal isOpen={isConfirmExitModalOpen} onClose={() => setIsConfirmExitModalOpen(false)}>
                <ModalTitle>Edit Brief</ModalTitle>
                <ModalContent>This brief has unsaved changes. Are you sure you want to leave?</ModalContent>
                <ModalFooter align="left">
                    <Button type="filled" color="negative" onClick={handleConfirmExit}>
                        Continue
                    </Button>
                    <Button type="filled" color="black" onClick={() => setIsConfirmExitModalOpen(false)}>
                        Return
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default EditBriefModal;
