import { queryOptions } from '@tanstack/react-query';
import {
    buildListKeyFactories,
    buildCoreKeyFactory,
    buildDetailKeyFactories,
    parseQueryKey,
    buildErrorResponseGetter,
} from '../../queries.helpers';
import { ExpectedResponseError } from '../../utils';
import { getInstagramAudio, getInstagramAudios, GetInstagramAudiosParams } from './audios.api';

export const CORE_KEY = 'instagram-audio';

export const keys = {
    all: buildCoreKeyFactory(CORE_KEY),
    ...buildListKeyFactories<GetInstagramAudiosParams, typeof CORE_KEY>(CORE_KEY),
    ...buildDetailKeyFactories<number, typeof CORE_KEY>(CORE_KEY),
};

export const options = {
    list: (params: GetInstagramAudiosParams) =>
        queryOptions({
            queryKey: keys.list(params),
            queryFn: async ({ signal, queryKey }) => {
                const { params } = parseQueryKey(queryKey);

                const response = await getInstagramAudios(params, { signal });

                if (response.status === 200) {
                    return response.data;
                }

                throw new ExpectedResponseError('Failed to fetch instagram audios', response);
            },
        }),
    detail: (id: number) =>
        queryOptions({
            queryKey: keys.detail(id),
            queryFn: async ({ signal, queryKey }) => {
                const { params: id } = parseQueryKey(queryKey);

                const response = await getInstagramAudio(id, { signal });

                if (response.status === 200) {
                    return response.data;
                }

                throw new ExpectedResponseError('Failed to fetch instagram audio', response);
            },
        }),
};

export const utils = {
    list: {
        getErrorResponse: buildErrorResponseGetter<Awaited<ReturnType<typeof getInstagramAudios>>>(),
    },
    detail: {
        getErrorResponse: buildErrorResponseGetter<Awaited<ReturnType<typeof getInstagramAudio>>>(),
    },
};
