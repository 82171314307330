import { microwave } from '@round/api';
import styles from './DecisionCell.module.css';
import { getTableMetaHelper, Skeleton } from '@round/ui-kit';
import Button from 'ui/Button/Button';
import cn from 'classnames';
import { CellContext } from '@tanstack/react-table';
import { showNotification } from 'helpers';
import { useUpdatePaymentRequest } from '../../../useApproveInstagramPosts';

type BaseMeta = {
    isLoading: boolean | undefined;
};

export type Meta = {
    activeStatusFilter?: microwave.PaymentRequestStatus;
};

const getDecisionLabels = (status: microwave.PaymentRequestStatus | undefined) => ({
    decision: !status ? 'update' : status === 'APPROVED' ? 'approve' : 'reject',
    decisionPast: !status ? 'update' : status === 'APPROVED' ? 'approved' : 'rejected',
});

/**
 * Cell component for the decision column in the table
 *
 * **Note**: Requires accessor for the payment request status
 */
const DecisionCell = <TRow extends microwave.PaymentRequest, TMeta extends BaseMeta & Meta>({
    getValue,
    row,
    table,
}: CellContext<TRow, microwave.PaymentRequestStatus>) => {
    const getTableMeta = getTableMetaHelper<TMeta>();
    const { isLoading: isDataLoading, activeStatusFilter } = getTableMeta(table);

    const {
        mutate: updatePaymentRequest,
        status: updateStatus,
        data: updateResponseData,
        variables,
    } = useUpdatePaymentRequest(row.original.id);

    const isApproveLoading = updateStatus === 'pending' && variables?.status === 'APPROVED';
    const isRejectLoading = updateStatus === 'pending' && variables?.status === 'REJECTED';

    const handleUpdatePaymentRequest = (data: microwave.PatchPaymentRequestBody) =>
        updatePaymentRequest(data, {
            onSuccess: (response, requestData) => {
                const labels = getDecisionLabels(requestData.status);

                if (response.status === 200) {
                    showNotification(`Request ${labels.decisionPast} successfully`, 'info');
                    return;
                }

                let errorMessage = `Couldn't ${labels.decision} request`;

                if (response.status === 400) {
                    errorMessage = response.data['status']?.toString() || errorMessage;
                }

                showNotification(errorMessage, 'error');
            },
            onError: (e, data) => {
                const decision = getDecisionLabels(data.status).decision;
                showNotification(`Couldn't ${decision} request`, 'error');
            },
        });

    if (isDataLoading) {
        return <Skeleton />;
    }

    const value = updateResponseData?.status === 200 ? updateResponseData.data.status : getValue();
    // After the mutation a background refresh of table data will ocurr. If the row status post-mutation is
    // different to the active status filter it will dissapear when the refresh finishes.
    // We will disable further action on it to reduce UI noise.
    const shouldDisableFurtherAction = updateStatus === 'success' && activeStatusFilter && value !== activeStatusFilter;

    return (
        <div className={styles.container}>
            <Button
                appearance="primary"
                className={cn(styles.button, { [styles.disabled]: value === 'APPROVED' || shouldDisableFurtherAction })}
                disabled={isApproveLoading || value === 'APPROVED' || shouldDisableFurtherAction}
                onClick={() => handleUpdatePaymentRequest({ status: 'APPROVED' })}
                isLoading={isApproveLoading}
            >
                Approve
            </Button>
            <Button
                appearance="contrast"
                className={cn(styles.button, { [styles.disabled]: value === 'REJECTED' || shouldDisableFurtherAction })}
                disabled={isRejectLoading || value === 'REJECTED' || shouldDisableFurtherAction}
                onClick={() => handleUpdatePaymentRequest({ status: 'REJECTED' })}
                isLoading={isRejectLoading}
            >
                Reject
            </Button>
        </div>
    );
};

export default DecisionCell;
