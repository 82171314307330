import { Model, Response } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';
import { AppServer, RouteHandlerContext } from '../../mirage';
import { Invite } from './invites.types';
import { getRandomPlatform } from '../microwave.mirage.helpers';
import { PatchInviteData } from './invites.api';

export const models = {
    microwaveInvite: Model as ModelDefinition<Invite>,
};

export const serializers = {
    microwaveInvite: makeSerializer<Invite>([]),
};

export const factories = {
    microwaveInvite: createFactory<Invite>({
        campaign_id: (index: number) => index,
        chase_email_count: 0,
        deadline: new Date().toISOString(),
        platform: getRandomPlatform(),
        post_create_time: null,
        post_url: null,
        influencer_email_address: (index: number) => `email${index}@email.com`,
        influencer_id: (index: number) => index,
        instagram_audio_id: null,
        last_contacted_at: new Date().toISOString(),
        tiktok_audio_id: null,
    }),
};

export function handleRequests(server: AppServer) {
    const requestUrl = '/api/microwave/invites/';
    server.get(`${requestUrl}:id`, (schema, request) => {
        const invite = schema.find('microwaveInvite', request.params.id);
        if (!invite) {
            return new Response(404, {}, { detail: 'Not found.' });
        }
        return invite;
    });

    server.get(requestUrl, function (this: RouteHandlerContext, schema, request) {
        const invites = schema.all('microwaveInvite');
        return buildPaginatedResponse(invites, {
            url: requestUrl,
            queryParams: request.queryParams,
            serialize: (resource) => this.serialize(resource, 'microwaveInvite'),
        });
    });

    server.patch(`${requestUrl}/:id/`, (schema, request) => {
        const invite = schema.find('microwaveInvite', request.params.id);
        if (!invite) {
            return new Response(404, {}, { detail: 'Not found.' });
        }

        const data = JSON.parse(request.requestBody) as PatchInviteData;
        invite.update(data);
        return invite;
    });
}
