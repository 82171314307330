import { ScrollView, TableProps } from '@round/ui-kit';
import { RowData, Table } from '@tanstack/react-table';
import { useState } from 'react';
import WrapperTable from '../WrapperTable';
import PaginationToolbar from '../../PaginationTable/PaginationToolbar/PaginationToolbar';
import styles from './WrapperPaginationTable.module.css';
import { GenericDropdownOption } from 'App.types';

export type WrapperPaginationTableProps<TableData> = Omit<TableProps<TableData>, 'instance' | 'onPaginationChange'> & {
    count: number;
    page: number;
    setPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
    isLoading?: boolean;
    pageSizeOptions?: GenericDropdownOption<number>[];
};

const WrapperPaginationTable = <TableData extends RowData>({
    page = 1,
    setPage,
    pageSize = 10,
    setPageSize,
    count,
    isLoading,
    data,
    pageSizeOptions,
    ...props
}: WrapperPaginationTableProps<TableData>) => {
    const [table, setTable] = useState<Table<TableData> | null>(null);

    // skeletons are rendered in table cells,
    // so we need some rows to display skeletons on initial loading
    const initialLoading = !data.length && isLoading;
    const emptyRows = new Array(pageSize || 0).fill({}) as TableData[];
    const rows = initialLoading ? emptyRows : data;

    return (
        <>
            <ScrollView>
                <WrapperTable
                    instance={setTable}
                    {...props}
                    data={rows}
                    pagination={{
                        pageIndex: page - 1,
                        pageSize: pageSize,
                    }}
                    onPaginationChange={({ pageIndex, pageSize: newPageSize }) => {
                        const newPage = pageIndex + 1;
                        if (newPage !== page) {
                            setPage(newPage);
                        }

                        if (pageSize !== newPageSize) {
                            setPageSize(newPageSize);
                        }
                    }}
                />
            </ScrollView>
            {table && (
                <PaginationToolbar
                    className={styles.paginationToolbar}
                    pageSize={pageSize}
                    setPageSize={table.setPageSize}
                    page={page}
                    setPage={(page) => table.setPageIndex(page - 1)}
                    count={count}
                    pageSizeOptions={pageSizeOptions}
                />
            )}
        </>
    );
};

export default WrapperPaginationTable;
