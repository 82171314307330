import CrossButton from 'ui/General/CrossButton/CrossButton';
import FilterBadge, { FilterBadgeProps } from '../FilterBadge';

type Props = Omit<FilterBadgeProps, 'renderRight'> & {
    onClear: () => void;
};

const FilterBadgeClearable = ({ onClear, children, ...props }: Props) => {
    return (
        <FilterBadge
            {...props}
            renderRight={
                !props.isDisabled && (
                    <CrossButton
                        onClickCapture={(e) => {
                            e.stopPropagation();
                            if (!props.isDisabled) {
                                onClear();
                            }
                        }}
                    />
                )
            }
        >
            {children}
        </FilterBadge>
    );
};

export default FilterBadgeClearable;
