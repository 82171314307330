const FEATURE_FLAG_STORAGE_KEY = 'feature_flags';
export const FEATURE_FLAGS = [
    'fb_custom_audience_targeting',
    'mobile_advertising',
    'microwave_campaign_details_page',
    'microwave_tiktok_campaigns_management',
    'microwave_instagram_approvals',
    'planner_filter',
    'email_preview_detailed_results',
] as const;

export type FeatureFlagName = typeof FEATURE_FLAGS[number];
export type FeatureFlags = Record<FeatureFlagName, boolean>;

export const getFlags = (): FeatureFlags => {
    const val = localStorage.getItem(FEATURE_FLAG_STORAGE_KEY);
    return JSON.parse(val ?? '{}');
};

export const saveFlags = (flags: FeatureFlags) => {
    localStorage.setItem(FEATURE_FLAG_STORAGE_KEY, JSON.stringify(flags));
};
