import { DataState, useAbortableEffect } from '@round/utils';
import { users } from '@round/api';
import { ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';
import { makeErrorStateSlice, makeLoadingStateSlice, makeSuccessStateSlice } from 'utility/dataState';
import { BaseDataHookContextValue } from 'utility/dataHook';
import { Context } from '@fluentui/react-context-selector';
import { ContextSelector, useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { useCallback } from 'react';

type State = {
    [userId: number]: DataState<users.UserPlanner | null> | undefined;
};

export const initialState: State = {};

type Actions =
    | ReducerActionWithPayload<'plannerUsersDataHookLoad', number[]>
    | ReducerActionWithPayload<'plannerUsersDataHookError', { userIds: number[]; error: string }>
    | ReducerActionWithPayload<'plannerUsersDataHookSuccess', { userIds: number[]; users: users.UserPlanner[] }>;

export const reducer = createReducer<State, Actions>({
    plannerUsersDataHookLoad: (state, { payload: userIds }) => ({
        ...state,
        ...makeLoadingStateSlice(userIds),
    }),
    plannerUsersDataHookError: (state, { payload: { userIds, error } }) => ({
        ...state,
        ...makeErrorStateSlice(userIds, error),
    }),
    plannerUsersDataHookSuccess: (state, { payload: { users, userIds } }) => ({
        ...state,
        ...makeSuccessStateSlice(userIds, (userId) => users.find((u) => u.id === userId) ?? null),
    }),
});

type HookConfig = {
    userIds?: number[];
    isImperative?: boolean;
};

export const makePlannerUsersDataHook = <TValue extends BaseDataHookContextValue<Actions>>(
    context: Context<TValue>,
    selector: ContextSelector<TValue, State>
) => {
    return ({ userIds, isImperative = false }: HookConfig) => {
        const state = useNonNullContextSelector(context, selector);
        const dispatch = useNonNullContextSelector(context, ([, dispatch]) => dispatch);

        const fetchData = useCallback(
            async (userIds: number[], requestInit?: RequestInit) => {
                if (!userIds.length) {
                    dispatch({ type: 'plannerUsersDataHookSuccess', payload: { userIds: [], users: [] } });
                    return;
                }

                try {
                    dispatch({ type: 'plannerUsersDataHookLoad', payload: userIds });
                    const response = await users.getPlannerUsers(
                        { id: userIds.toString(), page_size: userIds.length },
                        requestInit
                    );

                    dispatch({
                        type: 'plannerUsersDataHookSuccess',
                        payload: { userIds, users: response.data.results },
                    });
                    return response;
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        throw e;
                    }

                    dispatch({
                        type: 'plannerUsersDataHookError',
                        payload: { userIds, error: 'Could not load users' },
                    });
                    throw e;
                }
            },
            [dispatch]
        );

        useAbortableEffect(
            (signal) => {
                if (isImperative || !userIds?.length) {
                    return;
                }

                fetchData(userIds, { signal }).catch(() => {});
            },
            [fetchData, isImperative, userIds]
        );

        return {
            data: state,
            fetchData,
        };
    };
};
