import { creatorbase } from '@round/api';
import { GenericDropdownOption } from 'App.types';
import { OptionsParams, UseSingleValueSelectParams, useSelect } from 'Hooks/useSelect';

export type ProjectOption = GenericDropdownOption<number>;

export const mapProjectToOption = (project: creatorbase.Project): ProjectOption => ({
    value: project.id,
    label: project.name,
});

const fetchOptions = async (params: OptionsParams, requestInit?: RequestInit) => {
    const response = await creatorbase.getProjects(
        { page: params.page, page_size: params.page_size, search: params.search },
        requestInit
    );

    if (response.status === 404 || response.status === 403) {
        throw new Error(response.data.detail);
    }

    return {
        ...response.data,
        results: response.data.results.map((project) => mapProjectToOption(project)),
    };
};

type Params = Omit<UseSingleValueSelectParams<ProjectOption>, 'fetchOptions' | 'isMulti'>;

export default function useProjectSelect(params: Params) {
    return useSelect<ProjectOption>({ fetchOptions, isMulti: false, ...params });
}
