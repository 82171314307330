import InstagramCreatorsFilters from './Filters/InstagramCreatorsFilters';
import InstagramCreatorsTable, { InstagramCreatorsTableRow } from './InstagramCreatorsTable/InstagramCreatorsTable';
import useInstagramCreators, { mapUrlStateToApiParams } from './useInstagramCreators';
import { useMemo, useState } from 'react';
import useMicrowaveContentTags from '../../hooks/useMicrowaveContentTags';
import styles from './InstagramCreators.module.css';
import InitialEmailPreviewModal from '../../EmailPreview/InitialEmailPreviewModal';
import EmailPreviewButton from '../../components/EmailPreviewButton/EmailPreviewButton';
import useInstagramCreatorsUrlState from './useInstagramCreatorsUrlState';
import { mapArrayToRowSelectionState } from '@round/ui-kit';
import { useCounts } from '../../Invites/Counts/useCounts';
import { mapOrderingToTableSorting, mapTableSortingToOrdering } from 'ui/WrapperTable/helpers';

type Props = {
    campaignId: number;
};

const InstagramCreators = ({ campaignId }: Props) => {
    const { state: urlState, mergeSearchParams } = useInstagramCreatorsUrlState({ campaignId, shouldPersist: true });

    const { data, status, error, reset, userImages, getIsAccountDataLoading, updateCreator } = useInstagramCreators(
        campaignId,
        mapUrlStateToApiParams(campaignId, urlState)
    );

    const { data: contentTags } = useMicrowaveContentTags();

    const { reset: resetCounts } = useCounts({ campaignId: campaignId, isImperative: true });

    const rows: InstagramCreatorsTableRow[] = useMemo(
        () =>
            data?.results.map((creator) => {
                const image = creator.instagram_user_id ? userImages[creator.instagram_user_id]?.data ?? null : null;
                const creatorTags = contentTags?.filter((tag) => creator.content_tags.includes(tag.id)) ?? [];

                return {
                    ...creator,
                    instagramUserImage: image,
                    contentTagsOptions: creatorTags.map((tag) => ({ value: tag.id, label: tag.name })),
                };
            }) ?? [],
        [contentTags, data?.results, userImages]
    );

    const [isEmailPreviewModalOpen, setIsEmailPreviewModalOpen] = useState(false);
    const isEmailPreviewDisabled = urlState.selected.length === 0;

    return (
        <>
            <menu className={styles.container}>
                <InstagramCreatorsFilters
                    value={urlState}
                    onChange={(filterVals, shouldRefreshData = true) => {
                        if (shouldRefreshData === false) {
                            mergeSearchParams({ ...filterVals });
                            return;
                        }
                        mergeSearchParams({ ...filterVals, page: 1 });
                        reset();
                    }}
                />

                <EmailPreviewButton
                    onClick={() => setIsEmailPreviewModalOpen(true)}
                    disabled={isEmailPreviewDisabled}
                    hint={isEmailPreviewDisabled ? 'Select users to preview email' : undefined}
                />
            </menu>

            <InstagramCreatorsTable
                data={rows}
                count={data?.count ?? 0}
                page={urlState.page}
                setPage={(page) => {
                    mergeSearchParams({ page });
                    reset();
                }}
                pageSize={urlState.page_size}
                setPageSize={(pageSize) => {
                    mergeSearchParams({ page_size: pageSize });
                    reset();
                }}
                noDataLabel={!data?.results.length && (status === 'error' ? error : 'No data')}
                getRowId={(row, index) => (row.id ? `${row.id}` : `skeleton-${index}`)}
                rowSelection={mapArrayToRowSelectionState(urlState.selected)}
                onRowSelectionChange={(selection) => mergeSearchParams(() => ({ selected: Object.keys(selection) }))}
                meta={{
                    isLoading: status === 'loading',
                    getIsAccountDataLoading: (row) => getIsAccountDataLoading(row.instagram_user_id),
                    updateCreator,
                }}
                sorting={mapOrderingToTableSorting(urlState.ordering ? [urlState.ordering as string] : [])}
                onSortingChange={(sorting) => {
                    mergeSearchParams({ ordering: mapTableSortingToOrdering(sorting) });
                    reset();
                }}
            />

            <InitialEmailPreviewModal
                isOpen={isEmailPreviewModalOpen}
                onClose={() => setIsEmailPreviewModalOpen(false)}
                campaignId={campaignId}
                influencerIds={urlState.selected}
                platform="instagram"
                onSent={(results) => {
                    const influencersEmailedSuccessfullyIds = results.success;
                    const remainingSelected = urlState.selected?.filter(
                        (id) => !influencersEmailedSuccessfullyIds.includes(id)
                    );

                    mergeSearchParams((prevUrl) => ({
                        selected: remainingSelected,
                        showOnlySelected: remainingSelected.length > 0 ? prevUrl.showOnlySelected : undefined,
                    }));
                    reset();

                    if (influencersEmailedSuccessfullyIds.length) {
                        resetCounts();
                    }
                }}
            />
        </>
    );
};

export default InstagramCreators;
