import { CellContext, RowData } from '@tanstack/react-table';
import { getTableMetaHelper } from '@round/ui-kit';
import AudioBadge, { AudioData } from '../../AudioBadge/AudioBadge';

export type Meta<TRow extends RowData> = Partial<{
    isLoading: boolean;
    getIsAudioLoading: (row: TRow) => boolean;
}>;

const getTableMeta = getTableMetaHelper<Meta<any>>();

const AudioCell = <TRow extends RowData>({ getValue, row, table }: CellContext<TRow, AudioData | null>) => {
    const { isLoading, getIsAudioLoading } = getTableMeta(table);

    return <AudioBadge audio={getValue()} isLoading={isLoading || getIsAudioLoading?.(row.original)} />;
};

export default AudioCell;
