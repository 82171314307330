import { encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    ForbiddenResponse,
    NotFoundResponse,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { call } from '../../helpers';
import { Platform } from '../creatorbase.types';
import { Campaign, PublicCampaign, WriteTiktokCampaignDetails, WriteInstagramCampaignDetails } from './campaigns.types';
import { isPublicReportRequestInit, PublicReportRequestInit } from '../../influencer/utils';

export type GetCampaignsOrderingKeys = 'team' | 'id';
export type GetCampaignsParams = Partial<
    PaginatedRequest & {
        id: string;
        ordering: string;
        platform: Platform;
        project_id: number;
        public_report_id: string;
        report_id: string;
        'project-platform-order': string;
    }
>;

type BaseGetCampaignsResponse<T extends Campaign | PublicCampaign> =
    | ApiResponse<PaginatedApiResponseData<T>, 200>
    | ForbiddenResponse
    | NotFoundResponse;

type GetCampaignsResponse = BaseGetCampaignsResponse<Campaign>;
type GetPublicCampaignsResponse = BaseGetCampaignsResponse<PublicCampaign>;

export async function getCampaigns(
    params: GetCampaignsParams,
    requestInit: PublicReportRequestInit
): Promise<GetPublicCampaignsResponse>;
export async function getCampaigns(
    params: GetCampaignsParams,
    requestInit?: RequestInit
): Promise<GetCampaignsResponse>;
export async function getCampaigns(
    params: GetCampaignsParams,
    requestInit?: RequestInit | PublicReportRequestInit
): Promise<GetPublicCampaignsResponse | GetCampaignsResponse> {
    const shouldBypassTokens = requestInit && isPublicReportRequestInit(requestInit);

    const response = await call(`/api/creatorbase/campaigns/${encodeUrlSearchParams(params)}`, requestInit, {
        shouldBypassTokens,
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get campaigns');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getAllCampaigns(
    params: Omit<GetCampaignsParams, keyof PaginatedRequest>,
    requestInit?: RequestInit
) {
    return fetchAll<Campaign>(`/api/creatorbase/campaigns/${encodeUrlSearchParams(params)}`, requestInit);
}

type GetCampaignResponse = ApiResponse<Campaign, 200> | ForbiddenResponse | NotFoundResponse;

export async function getCampaign(campaignId: number, requestInit?: RequestInit): Promise<GetCampaignResponse> {
    const response = await fetchWithToken(`/api/creatorbase/campaigns/${campaignId}/`, requestInit);

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not get campaign');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type PostCampaignBody = Pick<Campaign, 'currency_id' | 'project_id' | 'name'> &
    Partial<Pick<Campaign, 'team_members'>> &
    CampaignCostFields &
    (YoutubeCampaignFields | InstagramCampaignFields | TiktokCampaignFields);

// API requires null if post cost editable is true
type CampaignCostFields = { cost: null; is_post_cost_editable: true } | { cost: number; is_post_cost_editable: false };

// we have to send different set of data depending on a platform
type YoutubeCampaignFields = { platform: 'youtube' };
type InstagramCampaignFields = { platform: 'instagram'; instagram_details: WriteInstagramCampaignDetails };
type TiktokCampaignFields = { platform: 'tiktok'; tiktok_details: WriteTiktokCampaignDetails };

type PlatformAudioFieldError = {
    instagram_details: { audio_identifier: string | string[] };
    tiktok_details: { audio_identifier: string | string[] };
};

export type PostCampaignErrorResponse = ApiResponseError<PostCampaignBody> & Partial<PlatformAudioFieldError>;

type PostCampaignResponse =
    | ApiResponse<Campaign, 201>
    | ApiResponse<PostCampaignErrorResponse, 400>
    | ForbiddenResponse;

export async function postCampaign(data: PostCampaignBody): Promise<PostCampaignResponse> {
    const response = await fetchWithToken('/api/creatorbase/campaigns/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400 || response.status === 403) {
        return { status: response.status, data: await response.json() };
    }

    if (!response.ok) {
        throw new Error('Could not create a campaign');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export type PatchCampaignBody = Pick<
    Campaign,
    'cost' | 'currency_id' | 'is_post_cost_editable' | 'name' | 'team_members'
>;

type PatchCampaignResponse =
    | ApiResponse<Campaign, 200>
    | ApiResponse<ApiResponseError<PatchCampaignBody>, 400>
    | NotFoundResponse
    | ForbiddenResponse;

export async function patchCampaign(
    campaignId: number,
    data: Partial<PatchCampaignBody>
): Promise<PatchCampaignResponse> {
    const response = await fetchWithToken(`/api/creatorbase/campaigns/${campaignId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404 || response.status === 403 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not update campaign');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type DeleteCampaignResponse = ApiResponse<null, 204> | NotFoundResponse | ForbiddenResponse;

export async function deleteCampaign(campaignId: number): Promise<DeleteCampaignResponse> {
    const response = await fetchWithToken(`/api/creatorbase/campaigns/${campaignId}/`, {
        method: 'DELETE',
    });

    if (response.status === 404 || response.status === 403) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not delete campaign');
    }

    return {
        status: 204,
        data: null,
    };
}
