import { ReactNode } from 'react';
import styles from './CampaignDetailsLayout.module.css';
import IconButton from 'ui-new/Buttons/IconButton/IconButton';
import { ReactComponent as ArrowRightIcon } from 'assets/ArrowRight.svg';
import { useNavigate } from 'react-router-dom';

type Props = {
    children?: ReactNode;
};

const CampaignDetailsLayout = ({ children }: Props) => {
    const navigate = useNavigate();

    return (
        <main className={styles.container}>
            <div className={styles.actions}>
                <IconButton className={styles.goBackButton} onClick={() => navigate(-1)}>
                    <ArrowRightIcon className={styles.icon} />
                </IconButton>
            </div>

            {children}
        </main>
    );
};

export default CampaignDetailsLayout;
