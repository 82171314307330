import Modal, { ModalContent, ModalFooter, ModalProps, ModalTitle } from 'ui/General/Modal/Modal';
import { Button } from '@round/ui-kit';
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from 'formik';
import useNonNullContext from 'Hooks/useNonNullContext';
import { InstagramCreatorsContext } from '../../contexts/InstagramCreatorsContext/InstagramCreatorsContext';
import { TiktokCreatorsContext } from '../../contexts/TiktokCreatorsContext/TiktokCreatorsContext';
import FormField from 'ui/DataEntry/FormField/FormField';
import Label from 'ui/DataEntry/Label/Label';
import styles from './InfluencerPostGroupAudioModal.module.css';
import { findOrCreateTiktokAudio } from 'Modules/TikTok/TikTok.services';
import { findOrCreateInstagramAudio } from 'Modules/Instagram/Instagram.helpers';
import useInstagramInfluencerPlanAudios from '../../contexts/InstagramCreatorsContext/useInstagramInfluencerPlanAudios';
import { InfluencerPostGroup } from '@round/api';
import { buildInstagramAudioUrl, buildTiktokMusicUrl } from 'helpers';

type InfluencerPostGroupPlatformWithAudio = 'instagram' | 'tiktok';

type Props = Pick<ModalProps, 'isOpen' | 'onClose'> & {
    platform: InfluencerPostGroupPlatformWithAudio;
    group: InfluencerPostGroup | null;
    onSuccess: (updatedGroup: InfluencerPostGroup) => void;
};

type FormValues = {
    identifier: string;
};

const InfluencerPostGroupAudioModal = ({ isOpen, onClose, platform, group, onSuccess }: Props) => {
    const { updateInstagramInfluencerPostGroup } = useNonNullContext(InstagramCreatorsContext);
    const { updateTiktokInfluencerPostGroup, tiktokAudios } = useNonNullContext(TiktokCreatorsContext);
    const { audios: instagramAudios } = useInstagramInfluencerPlanAudios();

    const tiktokAudio = platform === 'tiktok' ? tiktokAudios.find((a) => a.id === group?.audio_id) ?? null : null;
    const instagramAudio =
        platform === 'instagram' ? instagramAudios.find((a) => a.id === group?.audio_id) ?? null : null;

    const tiktokAudioIdentifier =
        platform === 'tiktok' && tiktokAudio ? buildTiktokMusicUrl(tiktokAudio.title, tiktokAudio.tiktok_id) : '';
    const instagramAudioIdentifier =
        platform === 'instagram' && instagramAudio ? buildInstagramAudioUrl(instagramAudio.instagram_id) : '';

    const resolveAudioId = async (
        identifier: string
    ): Promise<{ success: true; audioId: number | null } | { success: false; error: string }> => {
        if (!identifier) {
            return { success: true, audioId: null };
        }

        const findOrCreateAudio = platform === 'instagram' ? findOrCreateInstagramAudio : findOrCreateTiktokAudio;
        return findOrCreateAudio(identifier).then((result) => {
            if (result.success) {
                return { ...result, audioId: result.data.id };
            }

            return result;
        });
    };

    const formik = useFormik<FormValues>({
        initialValues: { identifier: platform === 'tiktok' ? tiktokAudioIdentifier : instagramAudioIdentifier },
        onSubmit: async (values, { setFieldError, resetForm }) => {
            if (!group) {
                return;
            }

            try {
                const updateGroup =
                    platform === 'instagram' ? updateInstagramInfluencerPostGroup : updateTiktokInfluencerPostGroup;

                const audioResult = await resolveAudioId(values.identifier);
                if (!audioResult.success) {
                    setFieldError('identifier', audioResult.error);
                    return;
                }

                const response = await updateGroup(group.id, { audio_id: audioResult.audioId });
                if (!response) {
                    return;
                }

                resetForm();
                onClose?.();
                onSuccess?.(response.data);
            } catch (e) {
                const message = e instanceof Error ? e.message : 'Could not create group';
                setFieldError('identifier', message);
            }
        },
        enableReinitialize: true,
    });

    const handleClose = () => {
        onClose?.();
        formik.resetForm();
    };

    return (
        <Modal closeOnOverlayClick isOpen={isOpen} onClose={handleClose}>
            <ModalTitle>Group Audio</ModalTitle>

            <ModalContent>
                <FormikProvider value={formik}>
                    <Form>
                        <FormField>
                            <Label htmlFor="identifier">Audio</Label>
                            <Field
                                id="identifier"
                                name="identifier"
                                type="text"
                                placeholder="Enter audio url or identifier"
                            />

                            <ErrorMessage name="identifier">
                                {(msg) => <span className={styles.errorMessage}>{msg}</span>}
                            </ErrorMessage>
                        </FormField>
                    </Form>
                </FormikProvider>
            </ModalContent>

            <ModalFooter align="right">
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    type="filled"
                    color="black"
                    loading={formik.isSubmitting}
                    disabled={!formik.dirty || !formik.isValid}
                    onClick={formik.submitForm}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default InfluencerPostGroupAudioModal;
