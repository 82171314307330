import { microwave } from '@round/api';
import { DomainFiltersProps, getInitialFilters } from 'ui-new/Filters/helpers';
import { Filter } from 'ui-new/Filters/useFilterManager';
import { ReactComponent as HashIcon } from 'assets/icons/Hash.svg';
import { ReactComponent as GlobeIcon } from 'assets/Globe.svg';
import TextFilter from 'ui-new/Filters/TextFilter/TextFilter';
import Filters from 'ui-new/Filters/Filters';
import { getNonEmptyKeys } from 'utility/utility';
import styles from './InstagramContactedFilters.module.css';
import LocationFilter from 'ui-new/Filters/LocationFilter/LocationFilter';
import { ContentTagsFilterIcon } from 'Modules/Tags/components/Filters/ContentTagsFilter/ContentTagsFilter';
import MicrowaveContentTagsFilter from '../../../../components/MicrowaveContentTagsFilter/MicrowaveContentTagsFilter';
import CheckboxFilter from 'ui-new/Filters/CheckboxFilter/CheckboxFilter';

export type FilterParams = Pick<
    microwave.GetInvitesParams,
    'chase_email_count' | 'influencer_location_id' | 'content_tags'
> & {
    showOnlySelected?: boolean;
};

const PERMANENT_FILTERS: (keyof FilterParams)[] = ['showOnlySelected'];

type Props = DomainFiltersProps<FilterParams> & {
    selected: string[];
};

const InstagramContactedFilters = ({ value, onChange, selected }: Props) => {
    const filters: Filter<keyof FilterParams>[] = [
        {
            name: 'showOnlySelected',
            label: 'Show only selected',
            icon: () => null,
            render(this) {
                return (
                    <CheckboxFilter
                        isDisabled={!selected.length}
                        label={this.label}
                        value={value.showOnlySelected}
                        onChange={(value) => onChange({ showOnlySelected: value || undefined })}
                    />
                );
            },
        },
        {
            name: 'chase_email_count',
            label: '# of chase emails',
            icon: () => <HashIcon className={styles.filterIcon} />,
            render(this, helpers) {
                const Icon = this.icon;
                return (
                    <TextFilter
                        isDisabled={helpers.isDisabled}
                        type="number"
                        name={this.label}
                        icon={<Icon />}
                        value={value.chase_email_count?.toString() ?? ''}
                        onChange={(val) => onChange({ chase_email_count: val ? Number(val) : undefined })}
                        onClear={() => {
                            onChange({ chase_email_count: undefined });
                            helpers.remove();
                        }}
                        placeholder="Enter # of chase emails"
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                    />
                );
            },
        },
        {
            name: 'influencer_location_id',
            label: 'Location',
            icon: () => <GlobeIcon className={styles.filterIcon} />,
            render(this, helpers) {
                const Icon = this.icon;
                return (
                    <LocationFilter
                        isDisabled={helpers.isDisabled}
                        label={this.label}
                        icon={<Icon />}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        initialValueId={value.influencer_location_id}
                        onChange={(locationId) => {
                            onChange({ influencer_location_id: locationId });
                        }}
                        onRemove={() => {
                            onChange({ influencer_location_id: undefined });
                            helpers.remove();
                        }}
                        onClear={() => {
                            onChange({ influencer_location_id: undefined });
                        }}
                    />
                );
            },
        },
        {
            name: 'content_tags',
            label: 'Tags',
            icon: () => ContentTagsFilterIcon,
            render(this, helpers) {
                return (
                    <MicrowaveContentTagsFilter
                        isDisabled={helpers.isDisabled}
                        label="Tags"
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        onRemove={() => {
                            onChange({ content_tags: undefined });
                            helpers.remove();
                        }}
                        value={value.content_tags}
                        onChange={(tags, shouldRefreshData) => onChange({ content_tags: tags }, shouldRefreshData)}
                    />
                );
            },
        },
    ];

    const areFiltersDisabled = !!value.showOnlySelected;
    return (
        <Filters
            sessionKey="mw-ig-contacted-tab-filters"
            isDisabled={areFiltersDisabled}
            hint={areFiltersDisabled ? 'Displaying only selected rows' : undefined}
            filters={filters}
            options={{
                initialFilters: (state) => [...PERMANENT_FILTERS, ...getInitialFilters(state, getNonEmptyKeys(value))],
            }}
        />
    );
};

export default InstagramContactedFilters;
