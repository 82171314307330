import { call } from '../../helpers';
import { ApiResponse, ApiResponseError } from '../../types';

export type PostEmailsDataItem = {
    email_address?: string;
    email_template_id: number;
    invite_id: string;
};

export type PostEmailsResponseData = {
    success: string[];
    failure: {
        [id: number]: ApiResponseError<PostEmailsDataItem> | string[];
    }[];
};

type PostEmailsResponse =
    | ApiResponse<PostEmailsResponseData, 201>
    | ApiResponse<PostEmailsResponseData, 400>
    | ApiResponse<ApiResponseError<PostEmailsDataItem>, 400>
    | ApiResponse<string[], 400>;

export async function postEmails(data: PostEmailsDataItem[], signal?: AbortSignal): Promise<PostEmailsResponse> {
    const response = await call('/api/microwave/emails/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        signal,
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not send emails');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}
