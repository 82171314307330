import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReleaseContext } from '../ReleaseContext';
import { useCallback } from 'react';
import { microwave } from '@round/api';
import { useAbortableEffect } from '@round/utils';

export default function useCreators(campaignId: number, params: microwave.GetMicrowaveInfluencersParams) {
    const state = useNonNullContextSelector(ReleaseContext, ([values]) => values.creators[campaignId]);
    const dispatch = useNonNullContextSelector(ReleaseContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (params: microwave.GetMicrowaveInfluencersParams, requestInit?: RequestInit) => {
            dispatch({ type: 'loadCreators', payload: campaignId });

            try {
                const response = await microwave.getMicrowaveInfluencers(params, requestInit);
                if (response.status !== 200) {
                    dispatch({
                        type: 'errorLoadingCreators',
                        payload: { campaignId, message: 'Could not load creators' },
                    });
                    return response;
                }

                dispatch({ type: 'creatorsInitialized', payload: { campaignId, data: response.data } });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'creatorsIdle', payload: campaignId });
                    throw e;
                }

                dispatch({ type: 'errorLoadingCreators', payload: { campaignId, message: 'Could not load creators' } });
                throw e;
            }
        },
        [dispatch, campaignId]
    );

    const isInitialized = state?.status === 'success' || state?.status === 'error';
    useAbortableEffect(
        (signal) => {
            if (isInitialized) {
                return;
            }

            fetchData(
                {
                    page: params.page || 1,
                    page_size: params.page_size,
                    search: params.search,
                    has_instagram_account: params.has_instagram_account,
                    content_tags: params.content_tags,
                    exclude_content_tags: params.exclude_content_tags,
                    hashtag_ids: params.hashtag_ids,
                    rating: params.rating,
                    min_tiktok_post_count: params.min_tiktok_post_count,
                    max_tiktok_post_count: params.max_tiktok_post_count,
                    min_instagram_post_count: params.min_instagram_post_count,
                    max_instagram_post_count: params.max_instagram_post_count,
                    min_tiktok_post_invite_count: params.min_tiktok_post_invite_count,
                    max_tiktok_post_invite_count: params.max_tiktok_post_invite_count,
                    min_instagram_post_invite_count: params.min_instagram_post_invite_count,
                    max_instagram_post_invite_count: params.max_instagram_post_invite_count,
                    min_instagram_follower_count: params.min_instagram_follower_count,
                    max_instagram_follower_count: params.max_instagram_follower_count,
                    min_tiktok_post_rate: params.min_tiktok_post_rate,
                    min_instagram_post_rate: params.min_instagram_post_rate,
                    exclude_invited_to_campaign: params.exclude_invited_to_campaign,
                    location_id: params.location_id,
                    id: params.id,
                    exclude_posted_with_instagram_audio: params.exclude_posted_with_instagram_audio,
                    posted_with_instagram_audio: params.posted_with_instagram_audio,
                    ordering: params.ordering,
                },
                { signal }
            ).catch(() => {});
        },
        [
            params.page,
            params.page_size,
            params.search,
            params.has_instagram_account,
            params.content_tags,
            params.exclude_content_tags,
            params.hashtag_ids,
            params.rating,
            params.min_tiktok_post_count,
            params.max_tiktok_post_count,
            params.min_instagram_post_count,
            params.max_instagram_post_count,
            params.min_tiktok_post_invite_count,
            params.max_tiktok_post_invite_count,
            params.min_instagram_post_invite_count,
            params.max_instagram_post_invite_count,
            params.min_tiktok_post_rate,
            params.min_instagram_post_rate,
            fetchData,
            params.exclude_invited_to_campaign,
            params.location_id,
            isInitialized,
            params.id,
            params.exclude_posted_with_instagram_audio,
            params.posted_with_instagram_audio,
            params.min_instagram_follower_count,
            params.max_instagram_follower_count,
            params.ordering,
        ]
    );

    const reset = useCallback(() => dispatch({ type: 'resetCampaignCreators', payload: campaignId }), [
        dispatch,
        campaignId,
    ]);

    const updateCreator = useCallback(
        async (...[creatorId, data]: Parameters<typeof microwave.patchMicrowaveInfluencer>) => {
            const response = await microwave.patchMicrowaveInfluencer(creatorId, data);

            if (response.status === 200) {
                dispatch({ type: 'updateCreator', payload: response.data });
            }

            return response;
        },
        [dispatch]
    );

    return {
        data: state?.data,
        status: state?.status,
        error: state?.error,
        fetchData,
        reset,
        updateCreator,
    };
}
