import { microwave } from '@round/api';
import { DomainFiltersProps, getInitialFilters } from 'ui-new/Filters/helpers';
import { Filter } from 'ui-new/Filters/useFilterManager';
import { ReactComponent as StatusIcon } from 'assets/icons/Status.svg';
import { ReactComponent as ReleaseIcon } from 'assets/icons/AudioSquare.svg';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import Filters from 'ui-new/Filters/Filters';
import { getNonEmptyKeys } from 'utility/utility';
import styles from './ApproveInstagramPostsFilters.module.css';
import SelectFilter from 'ui-new/Filters/SelectFilter/SelectFilter';
import { microPaymentRequestStatusOptions as statusOptions } from 'Modules/Advertising/Microwave/constants';
import useReleaseSelect from 'Modules/Advertising/Microwave/hooks/useReleaseSelect';
import useMicrowaveInfluencerSelect from 'Modules/Advertising/Microwave/hooks/useMicrowaveInfluencerSelect';
import MicrowaveInfluencerOptionLabel from 'Modules/Advertising/Microwave/components/Filters/MicrowaveInfluencerFilter/components/OptionLabel/MicrowaveInfluencerOptionLabel';

export type FilterParams = Pick<microwave.GetPaymentRequestsParams, 'status' | 'release_id' | 'influencer_id'>;

type Props = DomainFiltersProps<FilterParams>;

const ApproveInstagramPostsFilters = ({ value, onChange }: Props) => {
    const { props: releaseSelectProps } = useReleaseSelect({
        initialValueId: value.release_id,
    });

    const { props: influencerSelectProps } = useMicrowaveInfluencerSelect({
        initialValueId: value.influencer_id,
    });

    const filters: Filter<keyof FilterParams>[] = [
        {
            name: 'status',
            label: 'Status',
            icon: () => <StatusIcon className={styles.filterIcon} />,
            render(this, helpers) {
                const Icon = this.icon;
                return (
                    <SelectFilter
                        name={this.label}
                        icon={<Icon />}
                        value={statusOptions.find((option) => option.value === value.status)}
                        options={statusOptions}
                        onChange={(opt) => onChange({ status: opt?.value })}
                        components={{ Control: () => null }}
                        onClear={() => {
                            onChange({ status: undefined });
                        }}
                        onRemove={() => {
                            onChange({ status: undefined });
                            helpers.remove();
                        }}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        onClose={helpers.close}
                        isSearchable={false}
                    />
                );
            },
        },
        {
            name: 'release_id',
            label: 'Release',
            icon: () => <ReleaseIcon className={styles.filterIcon} />,
            render(this, helpers) {
                const clearState = () => {
                    onChange({
                        release_id: undefined,
                    });
                    releaseSelectProps.onChange?.(null, { action: 'clear' });
                };

                const Icon = this.icon;
                return (
                    <SelectFilter
                        isDisabled={helpers.isDisabled}
                        icon={<Icon />}
                        {...releaseSelectProps}
                        name={this.label}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        value={releaseSelectProps.value}
                        onChange={(opt, action) => {
                            onChange({
                                release_id: opt?.value,
                            });
                            releaseSelectProps.onChange?.(opt, action);
                        }}
                        onRemove={() => {
                            clearState();
                            helpers.remove();
                        }}
                        onClose={helpers.close}
                        onClear={() => clearState()}
                    />
                );
            },
        },
        {
            name: 'influencer_id',
            label: 'Creator',
            icon: () => <UserIcon className={styles.filterIcon} />,
            render(this, helpers) {
                const clearState = () => {
                    onChange({
                        influencer_id: undefined,
                    });
                    influencerSelectProps.onChange?.(null, { action: 'clear' });
                };

                const Icon = this.icon;
                return (
                    <SelectFilter
                        isDisabled={helpers.isDisabled}
                        icon={<Icon />}
                        {...influencerSelectProps}
                        name={this.label}
                        isOpen={helpers.isOpen}
                        onOpen={helpers.open}
                        value={influencerSelectProps.value}
                        onChange={(opt, action) => {
                            onChange({
                                influencer_id: opt?.value,
                            });
                            influencerSelectProps.onChange?.(opt, action);
                        }}
                        onRemove={() => {
                            clearState();
                        }}
                        formatOptionLabel={(option, { context }) => {
                            if (context === 'value') {
                                return null;
                            }
                            return <MicrowaveInfluencerOptionLabel {...option} context={context} />;
                        }}
                        getOptionLabel={(option) => option.instagramUniqueId || option.emailAddress}
                        onClose={helpers.close}
                        onClear={() => clearState()}
                    />
                );
            },
        },
    ];

    return (
        <Filters
            sessionKey="mw-ig-approve-posts-filters"
            filters={filters}
            options={{
                initialFilters: (state) => [...getInitialFilters(state, getNonEmptyKeys(value))],
            }}
        />
    );
};

export default ApproveInstagramPostsFilters;
