import SelectFilter, { Props as SelectFilterProps } from '../SelectFilter/SelectFilter';
import { ReactComponent as StarIcon } from 'assets/Star.svg';
import Rating from 'ui/Rating/Rating';
import styles from './RatingFilter.module.css';
import { GenericDropdownOption } from 'App.types';
import { getRatingOptions } from 'ui/RatingSelect/RatingSelect';
import RatingStarButton from 'ui-new/Rating/RatingStarButton/RatingStarButton';
import { StylesConfig } from 'react-select';

type Props<TIsMulti extends boolean = false> = Omit<
    SelectFilterProps<GenericDropdownOption<number>, TIsMulti>,
    'options' | 'icon'
> & {
    maxRating: number;
    isDisabled?: boolean;
};

const selectStyles: StylesConfig = {
    menu: (provided) => ({
        ...provided,
        maxWidth: '10rem',
    }),
};

export const RatingFilterIcon = () => <StarIcon className={styles.icon} />;

const RatingFilter = <TIsMulti extends boolean = false>({
    value,
    onChange,
    isOpen,
    onOpen,
    onClose,
    onRemove,
    onClear,
    isMulti,
    maxRating,
    name = 'Rating',
    isDisabled,
}: Props<TIsMulti>) => {
    const options = getRatingOptions(maxRating);
    return (
        <SelectFilter
            isDisabled={isDisabled}
            icon={<RatingFilterIcon />}
            name={name}
            isOpen={isOpen}
            onOpen={onOpen}
            options={options}
            value={value}
            onChange={onChange}
            formatOptionLabel={(option) => (
                <Rating rating={option.value} maxRating={maxRating} renderStarButton={RatingStarButton} />
            )}
            onRemove={() => {
                onChange?.(undefined, { action: 'clear' });
                onRemove();
            }}
            onClear={() => {
                onChange?.(undefined, { action: 'clear' });
                onClear();
            }}
            onClose={onClose}
            isSearchable={false}
            isMulti={isMulti}
            styles={selectStyles}
        />
    );
};

export default RatingFilter;
