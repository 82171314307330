import { microwave } from '@round/api';
import FullScreenModal from 'ui/FullScreenModal/FullScreenModal';
import styles from './EmailResults.module.css';
import { ReactComponent as TickIcon } from 'assets/icons/TickCircle.svg';
import { ReactComponent as FailureIcon } from 'assets/icons/CloseCircle.svg';
import { ReactComponent as ChevronDown } from 'assets/ChevronDown.svg';
import { useMicrowaveInfluencers } from 'Modules/Advertising/Microwave/hooks/useMicrowaveInfluencers';
import { useAbortableEffect } from '@round/utils';
import { useState } from 'react';
import cn from 'classnames';
import { SkeletonizedValue } from '@round/ui-kit';
import { useFeatureFlags } from 'utility/featureFlags/FeatureFlagsContext';

type Props = {
    results: microwave.PostCampaignInvitesResponseData | microwave.PostEmailsResponseData;
};

const EmailResults = ({ results }: Props) => {
    // Hiding this as will implement it in a separate project
    // Note that this will be used in the context of chase emails (success: [...inviteIds]),
    // so fetching only influencers is not sufficient
    const shouldShowDetailedResults = useFeatureFlags().isEnabled('email_preview_detailed_results');

    const [isDetailsOpen, setIsDetailsOpen] = useState(false);

    const influencerIdsQuery = results.failure?.map((failure) => Object.keys(failure)[0]).join(',');
    const {
        state: { data: influencers, status: influencersStatus },
        init: initInfluencers,
    } = useMicrowaveInfluencers({
        page: 1,
        page_size: influencerIdsQuery?.split(',').length,
        id: influencerIdsQuery,
    });

    const areInfluencersInitialized = influencersStatus === 'success' || influencersStatus === 'error';
    useAbortableEffect(
        (signal) => {
            if (areInfluencersInitialized || !isDetailsOpen) {
                return;
            }
            initInfluencers({ signal });
        },
        [initInfluencers, areInfluencersInitialized, isDetailsOpen]
    );

    const successfulCount = results.success.length;
    const failedCount = results.failure.length;
    return (
        <FullScreenModal.Content className={styles.container}>
            <div className={styles.headlines}>
                <div className={styles.card}>
                    <div className={styles.countRow}>
                        <TickIcon className={cn(styles.success, { [styles.faded]: successfulCount === 0 })} />
                        <div>{successfulCount}</div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div className={styles.countRow}>
                        <FailureIcon
                            className={cn(styles.failure, {
                                [styles.faded]: failedCount === 0,
                            })}
                        />
                        <div>{failedCount}</div>
                    </div>
                </div>
            </div>

            {shouldShowDetailedResults && failedCount > 0 && (
                <details
                    open={isDetailsOpen}
                    onToggle={() => setIsDetailsOpen(!isDetailsOpen)}
                    className={cn(styles.failureDetails, styles.card)}
                >
                    <summary>
                        Failed items
                        <ChevronDown
                            className={cn(styles.chevron, {
                                [styles.chevronOpen]: isDetailsOpen,
                            })}
                        />
                    </summary>
                    <ul className={styles.failuresList}>
                        {influencersStatus === 'error' ? (
                            <div className={styles.failure}>Failed to load influencers</div>
                        ) : (
                            results?.failure?.map((failureObject) => {
                                const influencerId = Object.keys(failureObject)[0];
                                const influencer = influencers?.find(
                                    (influencer) => influencer.id === Number(influencerId)
                                );

                                return (
                                    <li key={influencer?.email || influencerId}>
                                        <SkeletonizedValue isInitialized={areInfluencersInitialized}>
                                            {influencer?.email || '-'}
                                        </SkeletonizedValue>
                                    </li>
                                );
                            })
                        )}
                    </ul>
                </details>
            )}
        </FullScreenModal.Content>
    );
};

export default EmailResults;
