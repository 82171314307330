import { creatorbase, music, Song } from '@round/api';
import { GenericDropdownOption } from 'App.types';
import { OptionsParams, UseMultiValueSelectParams, UseSingleValueSelectParams, useSelect } from 'Hooks/useSelect';
import { isNumber } from 'utility/utility';

export type SongOption = GenericDropdownOption<number> & {
    image: string;
    artistName: string;
};

export const mapSongToOption = (song: creatorbase.Song, musicSong?: music.Song): SongOption => ({
    value: song.id,
    label: musicSong?.title || song.title,
    image: musicSong?.image ?? '',
    artistName: musicSong?.artists.map((a) => a.name).join(', ') || song.artist_name,
});

const fetchOptions = async (params: OptionsParams, requestInit?: RequestInit) => {
    const response = await creatorbase.getSongs(
        { page: params.page, page_size: params.page_size, search: params.search },
        requestInit
    );

    if (response.status === 404 || response.status === 403) {
        throw new Error(response.data.detail);
    }

    const musicSongIds = response.data.results.map((s) => s.music_song_id).filter(isNumber);

    let musicSongs: Song[] = [];

    if (!!musicSongIds.length) {
        const musicSongsResponse = await music.getSongs({
            id: musicSongIds.toString(),
            page_size: musicSongIds.length,
        });
        musicSongs = musicSongsResponse.status === 200 ? musicSongsResponse.data.results : [];
    }

    return {
        ...response.data,
        results: response.data.results.map((song) => {
            const musicSong = musicSongs.find((m) => m.id === song.music_song_id);
            return mapSongToOption(song, musicSong);
        }),
    };
};

const fetchInitialValue = async (id: number, requestInit?: RequestInit) => {
    const response = await creatorbase.getSong(id, requestInit);

    if (response.status === 404 || response.status === 403) {
        throw new Error(response.data.detail);
    }

    const song = response.data;
    let musicSong;

    if (response.data.music_song_id) {
        const musicSongResponse = await music.getSong(response.data.music_song_id);
        musicSong = musicSongResponse.status === 200 ? musicSongResponse.data : undefined;
    }

    return mapSongToOption(song, musicSong);
};

const fetchInitialValues = async (id: number[], requestInit?: RequestInit) => {
    const response = await creatorbase.getSongs({ id: id.toString(), page_size: id.length }, requestInit);

    if (response.status === 404 || response.status === 403) {
        throw new Error(response.data.detail);
    }

    const musicSongIds = response.data.results.map((s) => s.music_song_id).filter(isNumber);

    let musicSongs: Song[] = [];

    if (!!musicSongIds.length) {
        const musicSongsResponse = await music.getSongs({
            id: musicSongIds.toString(),
            page_size: musicSongIds.length,
        });
        musicSongs = musicSongsResponse.status === 200 ? musicSongsResponse.data.results : [];
    }

    return response.data.results.map((song) => {
        const musicSong = musicSongs.find((m) => m.id === song.music_song_id);
        return mapSongToOption(song, musicSong);
    });
};

type Params = Omit<UseSingleValueSelectParams<SongOption>, 'fetchOptions' | 'isMulti'>;
type MultiParams = Omit<UseMultiValueSelectParams<SongOption>, 'fetchOptions' | 'isMulti'>;

export function useSongSelect(params: Params) {
    return useSelect({ fetchOptions, fetchInitialValue: fetchInitialValue, isMulti: false, ...params });
}

export function useSongMultiSelect(params: MultiParams) {
    return useSelect<SongOption, true>({
        fetchOptions,
        fetchInitialValue: fetchInitialValues,
        isMulti: true,
        ...params,
    });
}
