import { microwave } from '@round/api';
import { StringSearchParams, toNumberOrUndefined, UrlData, useSessionUrlState } from '@round/utils';
import { useEffect } from 'react';
import { MakeRequired } from 'utility/utility.types';

type UrlStateShape = Pick<
    microwave.GetPaymentRequestsParams,
    'page' | 'page_size' | 'status' | 'release_id' | 'influencer_id'
>;

export type UrlState = MakeRequired<UrlStateShape, 'page' | 'page_size'>;
type UrlStateKey = keyof UrlState;

const initialUrlState: UrlState = {
    page: 1,
    page_size: 10,
    status: 'PENDING',
};

const parseUrlState = ({
    searchParams: { page, page_size, status, release_id, influencer_id },
}: UrlData<StringSearchParams<UrlState>>): UrlState => ({
    page: page ? Number(page) : initialUrlState.page,
    page_size: page_size ? Number(page_size) : initialUrlState.page_size,
    status: status && microwave.isValidPaymentRequestStatus(status) ? status : undefined,
    release_id: toNumberOrUndefined(release_id),
    influencer_id: toNumberOrUndefined(influencer_id),
});

type HookConfig = {
    shouldPersist?: boolean;
};

export default function useApproveInstagramPostsUrlState({ shouldPersist }: HookConfig = {}) {
    const { persistSession, ...urlState } = useSessionUrlState<UrlState>(
        'mw-ig-approve-posts',
        (url, session, { merge }) => {
            const urlKeys = Object.keys(parseUrlState({ searchParams: {}, hash: '' })) as UrlStateKey[];

            const hasAnyParamSet = Object.keys(url.searchParams).some((k) => urlKeys.includes(k as UrlStateKey));
            if (hasAnyParamSet) {
                return url;
            }

            return merge(url, { searchParams: { ...initialUrlState, ...session?.searchParams } });
        }
    );

    useEffect(() => {
        return () => {
            if (shouldPersist) {
                persistSession({ searchParams: parseUrlState(urlState.url) });
            }
        };
    }, [persistSession, shouldPersist, urlState]);

    return {
        state: parseUrlState(urlState.url),
        mergeSearchParams: urlState.mergeSearchParams,
    };
}
