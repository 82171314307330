import useUrlState, {
    BaseSearchParams,
    InitHelpers,
    StringSearchParams,
    UrlData,
    WriteSearchParamsData,
} from './useUrlState';
import { useCallback } from 'react';

type UrlInit<T extends BaseSearchParams = {}> =
    | Partial<UrlData<WriteSearchParamsData<T>>>
    | ((
          prevUrlData: UrlData<StringSearchParams<T>>,
          sessionData: UrlData<T> | null,
          helpers: InitHelpers
      ) => Partial<UrlData<WriteSearchParamsData<T>>>);

export default function useSessionUrlState<T extends BaseSearchParams = {}>(sessionKey: string, init?: UrlInit<T>) {
    const sessionValue = sessionStorage.getItem(sessionKey);
    const urlState = useUrlState<T>(
        typeof init === 'function'
            ? (prevUrl, helpers) => {
                  return init(prevUrl, sessionValue ? JSON.parse(sessionValue) : null, helpers);
              }
            : init
    );

    const persistSession = useCallback(
        (url: Partial<UrlData<WriteSearchParamsData<T>>>) => {
            window.sessionStorage.setItem(sessionKey, JSON.stringify(url));
        },
        [sessionKey]
    );

    const clearSession = useCallback(() => {
        sessionStorage.removeItem(sessionKey);
    }, [sessionKey]);

    return {
        ...urlState,
        persistSession,
        clearSession,
    };
}
