export type CampaignStats = {
    amountSpent: number;
    engagementRate: number;
    postCount: number;
    totalViews: number;
};

export type CampaignTab = 'creators' | 'posted' | 'contacted';
const defaultTab = 'creators';

export const getCampaignTabStateFromUrlSearchParam = (
    campaignId: number,
    urlSearch: string = ''
): [tab: CampaignTab, isOpen: boolean | undefined] => {
    const campaignTabSection = urlSearch.split(',').find((section) => section.startsWith(`${campaignId}-`));

    if (!campaignTabSection) {
        return [defaultTab, undefined];
    }

    const [, tab, isOpen] = campaignTabSection.split('-');
    return [tab as CampaignTab, isOpen === 'o'];
};

export const setCampaignTabStateInUrlSearchParam = (
    campaignId: number,
    urlSearch: string = '',
    isOpen: boolean,
    tab?: 'creators' | 'posted' | 'contacted'
): string => {
    const currentCampaignTabSection = urlSearch.split(',').find((section) => section.startsWith(`${campaignId}-`));
    const currentConfig = currentCampaignTabSection ? currentCampaignTabSection.split('-') : [];

    const nextTab = tab || (currentConfig[1] as CampaignTab) || defaultTab;

    const newConfig = [campaignId, nextTab, isOpen ? 'o' : ''].filter(Boolean);

    return currentCampaignTabSection
        ? urlSearch.replace(currentCampaignTabSection, newConfig.join('-'))
        : `${urlSearch},${newConfig.join('-')}`;
};
