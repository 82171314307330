import { InstagramUserImage } from '@round/api';
import { SkeletonTableCell } from '@round/ui-kit';
import { ColumnDef } from '@tanstack/react-table';
import { formatNumberToKNotation, toDecimalPoint } from '@round/utils';
import AccountCell, { AccountData } from 'Modules/Advertising/Microwave/components/cells/AccountCell/AccountCell';
import { buildInstagramUserUrl, numberWithCommas } from 'helpers';
import CreatorsTable, { CreatorsTableRow, Props as CreatorsTableProps } from '../../CreatorsTable/CreatorsTable';
import LocationCell from '../../CreatorsTable/LocationCell/LocationCell';
import TagsCell from '../../CreatorsTable/TagsCell/TagsCell';
import RatingCell from '../../CreatorsTable/RatingCell/RatingCell';
import { SelectCell, SelectHeader } from 'ui-new/TableComponents/SelectCell/SelectCell';

export type InstagramCreatorsTableRow = CreatorsTableRow & {
    instagramUserImage: InstagramUserImage | null;
};

type Props = Omit<CreatorsTableProps<InstagramCreatorsTableRow>, 'columns'>;

const InstagramCreatorsTable = (props: Props) => {
    const columns: ColumnDef<InstagramCreatorsTableRow, any>[] = [
        {
            id: 'select',
            header: SelectHeader,
            cell: SelectCell,
        },
        {
            id: 'account',
            header: 'Account',
            accessorFn: ({ instagramUserImage, instagram_user_username }): AccountData => ({
                image:
                    instagramUserImage?.avatar_thumb.cached_url || instagramUserImage?.avatar_thumb.original_url || '',
                username: instagram_user_username || '',
                title: instagram_user_username || '',
                profileUrl: buildInstagramUserUrl(instagram_user_username || ''),
            }),
            cell: AccountCell,
        },
        {
            id: 'instagram_follower_count',
            header: 'Follower count',
            enableSorting: true,
            accessorFn: (row) => formatNumberToKNotation(row.follower_count),
            cell: SkeletonTableCell,
        },
        {
            accessorKey: 'location',
            header: 'Location',
            cell: LocationCell,
        },
        {
            accessorKey: 'contentTagsOptions',
            header: 'Tags',
            cell: TagsCell,
        },
        {
            id: 'avgPostViews',
            header: 'Avg micro post views',
            accessorFn: (row) =>
                row.instagram_avg_microwave_post_views ? numberWithCommas(row.instagram_avg_microwave_post_views) : '-',
            cell: SkeletonTableCell,
        },
        {
            id: 'postRate',
            header: 'Post rate',
            accessorFn: (row) =>
                row.instagram_post_rate ? `${toDecimalPoint(Number(row.instagram_post_rate) * 100, 2)}%` : '-',
            cell: SkeletonTableCell,
        },
        {
            id: 'pastPosts',
            header: 'Past posts',
            accessorFn: (row) => numberWithCommas(row.instagram_post_count),
            cell: SkeletonTableCell,
        },
        {
            id: 'pastInvites',
            header: 'Past invites',
            accessorFn: (row) => numberWithCommas(row.instagram_post_invite_count),
            cell: SkeletonTableCell,
        },
        {
            accessorKey: 'rating',
            header: 'Rating',
            cell: RatingCell,
        },
        {
            accessorKey: 'email',
            header: 'Email',
            cell: SkeletonTableCell,
        },
    ];

    return (
        <CreatorsTable
            columns={columns}
            enableMultiRowSelection
            enableRowSelection
            enableSorting
            manualSorting
            {...props}
        />
    );
};

export default InstagramCreatorsTable;
