import { DataState } from '@round/utils';
import { microwave } from '@round/api';
import { ReducerAction, ReducerActionWithPayload } from 'App.types';
import { createReducer } from 'helpers';

type State = DataState<microwave.ReleaseStats>;
type Actions =
    | ReducerAction<'loadReleaseStats'>
    | ReducerActionWithPayload<'releaseStatsInitialized', microwave.ReleaseStats>
    | ReducerActionWithPayload<'errorLoadingReleaseStats', string>
    | ReducerAction<'resetReleaseStats'>;

export const initialState: State = {
    data: null,
    status: 'idle',
    error: null,
};

export const reducer = createReducer<State, Actions>({
    loadReleaseStats: (state) => ({
        ...state,
        status: 'loading',
        error: null,
    }),
    errorLoadingReleaseStats: (state, { payload: error }) => ({
        ...state,
        status: 'error',
        error,
    }),
    releaseStatsInitialized: (state, { payload: stats }) => ({
        ...state,
        data: stats,
        status: 'success',
        error: null,
    }),
    resetReleaseStats: () => initialState,
});
