import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './MicroCreators.module.css';
import {
    Button,
    DropdownOptionWithSubLabel,
    Modal,
    Select,
    Skeleton,
    TabNav,
    TabNavItem,
    TabPanel,
    TabPanels,
    Tabs,
} from '@round/ui-kit';
import Outreach from './Tabs/Outreach/Outreach';
import Contacted from './Tabs/Contacted/Contacted';
import useUrlState from '../../../../Hooks/useUrlState';
import useCreatorPlanTiktokAudios from '../../InfluencerPlan/contexts/TiktokCreatorsContext/useCreatorPlanTiktokAudios';
import { InfluencerPlanContext } from '../../InfluencerPlan/contexts/InfluencerPlanContext';
import LoadingSpinner from '../../../../SharedComponents/LoadingSpinner/LoadingSpinner';
import EmailConfiguration from './EmailConfiguration/EmailConfiguration';
import { ReactComponent as SettingIcon } from '../../../../assets/Setting.svg';
import Creators from './Tabs/Creators/Creators';
import { MicrowaveContentTagsProvider } from './contexts/MicrowaveContentTags';
import { omit } from 'lodash';
import Posted from './Tabs/Contacted/Posted';
import useAbortableEffect from '../../../../Hooks/useAbortableEffect';
import { showNotification } from '../../../../helpers';
import { useTikTokContactedCounts } from './contexts/TiktokMicroCampaignStats';
import { useMicroCampaigns } from './contexts/MicroCampaign';

type MicroCreatorsSection = 'outreach' | 'creators' | 'contacted' | 'posted';

type UrlState = {
    section: MicroCreatorsSection;
    selectedAudio?: number;
};

const emailAudioSectionTabs: MicroCreatorsSection[] = ['outreach', 'creators', 'contacted'];

const MicroCreators = () => {
    const [urlState, setUrlState] = useUrlState<UrlState>({ section: 'outreach' });
    const { influencerPlan, initialized: influencerPlanInitialized } = useContext(InfluencerPlanContext);
    const { tiktokAudios, tiktokAudiosLoading: audiosLoading, tiktokAudiosLoadingError } = useCreatorPlanTiktokAudios();
    const [isEmailConfigurationModalOpen, setIsEmailConfigurationModalOpen] = useState(false);

    const audioOptions = tiktokAudios.map<DropdownOptionWithSubLabel<number>>((audio) => ({
        value: audio.id,
        label: audio.title,
        subLabel: audio.tiktok_id,
    }));

    const selectedAudio = audioOptions.find((o) => o.value === Number(urlState.selectedAudio));
    const loading = !influencerPlanInitialized || audiosLoading;

    useEffect(() => {
        if (
            !urlState.selectedAudio &&
            audioOptions.length &&
            emailAudioSectionTabs.includes(urlState.section as MicroCreatorsSection)
        ) {
            setUrlState({ selectedAudio: audioOptions[0].value });
        }
    }, [audioOptions, setUrlState, urlState.section, urlState.selectedAudio]);

    const handleTabNavigation = (section: string | undefined) => {
        const urlString = sessionStorage.getItem(`micro_${section}_url_state`);
        const stateToRestore = urlString ? omit(JSON.parse(urlString), ['selectedAudio', 'section']) : {};
        setUrlState(
            (prev) => ({
                section: section as MicroCreatorsSection,
                ...stateToRestore,
                selectedAudio: prev.selectedAudio,
            }),
            { replace: true }
        );
    };

    const resetPreservedUrlState = useCallback(() => {
        sessionStorage.removeItem('micro_creators_url_state');
        sessionStorage.removeItem('micro_outreach_url_state');
    }, []);

    useEffect(() => {
        resetPreservedUrlState();
    }, [influencerPlan?.id, resetPreservedUrlState]);

    const {
        init: initContactedCounts,
        isContactedCountInitialized,
        unPostedCount,
        postedCount,
    } = useTikTokContactedCounts(influencerPlan?.id);

    useAbortableEffect(
        (signal) => {
            if (!isContactedCountInitialized) {
                initContactedCounts({ signal }).catch(() => {
                    showNotification('Could not fetch contacted counts', 'error');
                });
            }
        },
        [initContactedCounts, isContactedCountInitialized]
    );

    const { campaigns: microCampaigns } = useMicroCampaigns(influencerPlan?.release.id);
    const campaignId = microCampaigns.find((campaign) => campaign.platform === 'tiktok')?.id;

    return (
        <>
            <div className={styles.container}>
                <Tabs activeTab={urlState.section} onChange={handleTabNavigation}>
                    <div className={styles.header}>
                        <TabNav>
                            <TabNavItem className={styles.navItem} name="outreach">
                                Outreach
                            </TabNavItem>
                            <TabNavItem className={styles.navItem} name="creators">
                                Creators
                            </TabNavItem>
                            <TabNavItem className={styles.navItem} name="contacted">
                                Contacted
                                {isContactedCountInitialized ? (
                                    <div className={styles.contactedCountBadge}>{unPostedCount}</div>
                                ) : (
                                    <Skeleton circle width="1rem" height="1rem" />
                                )}
                            </TabNavItem>
                            <TabNavItem className={styles.navItem} name="posted">
                                Posted
                                {isContactedCountInitialized ? (
                                    <div className={styles.contactedCountBadge}>{postedCount}</div>
                                ) : (
                                    <Skeleton circle width="1rem" height="1rem" />
                                )}
                            </TabNavItem>
                        </TabNav>

                        <div className={styles.emailSectionActions}>
                            {emailAudioSectionTabs.includes(urlState.section as MicroCreatorsSection) && (
                                <>
                                    <Button
                                        disabled={!influencerPlan}
                                        className={styles.emailConfigurationButton}
                                        onClick={() => setIsEmailConfigurationModalOpen(true)}
                                    >
                                        <SettingIcon className={styles.emailConfigurationButtonIcon} />
                                        Email configuration
                                    </Button>

                                    <Select
                                        className={styles.audioSelect}
                                        placeholder="Select audio..."
                                        isLoading={audiosLoading}
                                        noOptionsMessage={() => tiktokAudiosLoadingError ?? 'No options'}
                                        options={audioOptions}
                                        value={selectedAudio}
                                        onChange={(o) => {
                                            setUrlState({ selectedAudio: o?.value });
                                            resetPreservedUrlState();
                                        }}
                                        getOptionLabel={(option) => `${option.label} - ${option.subLabel}`}
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    <div className={styles.content}>
                        {loading && (
                            <div className={styles.centered}>
                                <LoadingSpinner />
                            </div>
                        )}

                        {influencerPlan && !loading && (
                            <MicrowaveContentTagsProvider>
                                <TabPanels>
                                    <TabPanel name="outreach">
                                        {!selectedAudio && <div className={styles.centered}>Please, select audio</div>}
                                        {selectedAudio && (
                                            <Outreach audioId={selectedAudio.value} planId={influencerPlan.id} />
                                        )}
                                    </TabPanel>

                                    <TabPanel name="creators">
                                        {!selectedAudio && <div className={styles.centered}>Please, select audio</div>}
                                        {selectedAudio && (
                                            <Creators
                                                audioId={selectedAudio.value}
                                                planId={influencerPlan.id}
                                                campaignId={campaignId}
                                            />
                                        )}
                                    </TabPanel>

                                    <TabPanel name="contacted">
                                        {!selectedAudio && <div className={styles.centered}>Please, select audio</div>}
                                        {selectedAudio && (
                                            <Contacted planId={influencerPlan.id} audioId={selectedAudio?.value} />
                                        )}
                                    </TabPanel>

                                    <TabPanel name="posted">
                                        <Posted planId={influencerPlan.id} />
                                    </TabPanel>
                                </TabPanels>
                            </MicrowaveContentTagsProvider>
                        )}
                    </div>
                </Tabs>
            </div>

            <Modal
                isOpen={isEmailConfigurationModalOpen}
                closeOnOverlayClick
                onClose={() => setIsEmailConfigurationModalOpen(false)}
            >
                <EmailConfiguration />
            </Modal>
        </>
    );
};

export default MicroCreators;
