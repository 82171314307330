import useCreators from '../useCreators';
import { ReleaseContext } from '../../ReleaseContext';
import { makeInstagramUserImagesDataHook } from 'Modules/Instagram/hooks/dataHooks/useInstagramUserImagesDataHook';
import { isNumber } from 'utility/utility';
import { useEffect } from 'react';
import { UrlState } from './useInstagramCreatorsUrlState';
import { microwave } from '@round/api';
import {
    isExcludeCondition,
    isIncludeCondition,
    mapConditionsToApi,
    mapExcludeConditionsToApi,
    parseConditionsFromUrlString,
} from 'Modules/Tags/components/Filters/helpers';
import { toDecimalPoint } from '@round/utils';

const useInstagramUserImages = makeInstagramUserImagesDataHook(ReleaseContext, ([state]) => state.instagram.userImages);

export default function useInstagramCreators(...[campaignId, params]: Parameters<typeof useCreators>) {
    const { data, status, ...restOfState } = useCreators(campaignId, { ...params, has_instagram_account: true });
    const { data: instagramUserImagesData, fetchData: fetchInstagramUserImages } = useInstagramUserImages();

    const areCreatorsSuccessfullyInitialized = status === 'success';
    useEffect(() => {
        const instagramUserIds = data?.results.map((creator) => creator.instagram_user_id).filter(isNumber) ?? [];
        const userIdsWithImages = Object.keys(instagramUserImagesData).map(Number);
        const userIdsToFetchImagesFor = instagramUserIds.filter((userId) => !userIdsWithImages.includes(userId));
        if (!areCreatorsSuccessfullyInitialized || !userIdsToFetchImagesFor.length) {
            return;
        }

        fetchInstagramUserImages(userIdsToFetchImagesFor).catch(() => {});
    }, [areCreatorsSuccessfullyInitialized, data?.results, fetchInstagramUserImages, instagramUserImagesData]);

    return {
        data,
        status,
        ...restOfState,
        userImages: instagramUserImagesData,
        getIsAccountDataLoading: (userId: number | null) => {
            return userId !== null && instagramUserImagesData[userId]?.status === 'loading';
        },
    };
}

export const mapUrlStateToApiParams = (
    campaignId: number,
    {
        page,
        page_size,
        search,
        selected,
        showOnlySelected,
        exclude_invited_to_campaign,
        location_id,
        content_tags,
        min_tiktok_post_rate,
        hashtag_ids,
        rating,
        min_instagram_post_rate,
        max_tiktok_post_invite_count,
        min_instagram_post_invite_count,
        max_instagram_post_invite_count,
        min_instagram_follower_count,
        max_instagram_follower_count,
        max_instagram_post_count,
        min_tiktok_post_invite_count,
        min_tiktok_post_count,
        max_tiktok_post_count,
        min_instagram_post_count,
        exclude_posted_with_instagram_audio,
        posted_with_instagram_audio,
        ordering,
    }: UrlState
): microwave.GetMicrowaveInfluencersParams => ({
    page,
    page_size,
    search,
    ordering,
    ...(showOnlySelected
        ? { id: selected.join() }
        : {
              location_id,
              min_instagram_post_count,
              max_instagram_post_count,
              min_tiktok_post_invite_count,
              max_tiktok_post_invite_count,
              min_instagram_post_invite_count,
              min_instagram_follower_count,
              max_instagram_follower_count,
              max_instagram_post_invite_count,
              min_tiktok_post_count,
              max_tiktok_post_count,
              hashtag_ids,
              rating,
              exclude_posted_with_instagram_audio,
              posted_with_instagram_audio,
              exclude_invited_to_campaign: exclude_invited_to_campaign ? campaignId : undefined,
              content_tags: JSON.stringify(
                  mapConditionsToApi(parseConditionsFromUrlString(content_tags).filter(isIncludeCondition))
              ),
              exclude_content_tags: JSON.stringify(
                  mapExcludeConditionsToApi(parseConditionsFromUrlString(content_tags).filter(isExcludeCondition))
              ),
              min_tiktok_post_rate: min_tiktok_post_rate
                  ? toDecimalPoint(Number(min_tiktok_post_rate) / 100, 2)
                  : undefined,
              min_instagram_post_rate: min_instagram_post_rate
                  ? toDecimalPoint(Number(min_instagram_post_rate) / 100, 2)
                  : undefined,
          }),
});
