import { AppServer, RouteHandlerContext } from '../../mirage';
import { belongsTo, Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import { TiktokInfluencerPost } from './tiktokInfluencerPost.types';
import { buildPaginatedResponse, createFactory, makeSerializer } from '../../mirage.helpers';

export const models = {
    tiktokInfluencerPost: Model.extend({
        video: belongsTo('tiktokInfluencerPlanVideo'),
    }) as ModelDefinition<TiktokInfluencerPost>,
};

export const serializers = {
    tiktokInfluencerPost: makeSerializer<TiktokInfluencerPost>(['video']),
};

export const factories = {
    tiktokInfluencerPost: createFactory<TiktokInfluencerPost>({
        video: null,
        ad_code: '',
        cost: '0.000',
        influencer_id: null,
        tiktok_post_tiktok_id: null,
        tiktok_post: null,
        client_cost: 0.0,
        currency_id: 1,
        estimated_views: null,
        estimated_views_override: null,
        group_id: null,
        post_url: '',
        tiktok_user: null,
        draft_expected_by: null,
        notes: '',
        is_carousel: false,
        payment_status: 'UNPAID',
        plan_id(index: number) {
            return index;
        },
        status: 'briefed',
        xero_invoice_id: null,
    }),
};

export function handleRequests(server: AppServer) {
    const rootUrl = '/api/advertising/viewsets/tiktok-influencer-post/';
    server.get(rootUrl, function (this: RouteHandlerContext, schema, request) {
        const posts = schema.all('tiktokInfluencerPost');
        return buildPaginatedResponse(posts, {
            queryParams: request.queryParams,
            url: rootUrl,
            serialize: (resource) => this.serialize(resource, 'tiktokInfluencerPost'),
        });
    });
}
