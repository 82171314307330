import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';
import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    OrderingValues,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { TiktokMicrowaveInfluencerPost } from './tiktokPosts.types';
import { PaymentMethod, PaymentStatus, PaymentRequestStatus } from '../microwave.types';

export type GetTiktokMicrowaveInfluencerPostsSortableKeys =
    | keyof Pick<TiktokMicrowaveInfluencerPost, 'payment_deadline'>
    | 'plan_id';

export function isGetTiktokMicrowaveInfluencerPostsOrderingValid(
    ordering: string
): ordering is OrderingValues<GetTiktokMicrowaveInfluencerPostsSortableKeys> {
    const validValues = ['plan_id', 'payment_deadline'];
    return !!validValues.find((validValue) => validValue === ordering || `-${validValue}` === ordering);
}

type GetTiktokMicrowaveInfluencerPostsParams = Partial<
    PaginatedRequest & {
        payment_request_status: PaymentRequestStatus;
        payment_status: PaymentStatus;
        payment_requested_at_start: string;
        payment_requested_at_end: string;
        audio_id: number;
        plan_id: number;
        ordering: string;
        preferred_payment_method: PaymentMethod;
        payment_deadline_start: string;
        payment_deadline_end: string;
        influencer_id: number;
        invite_id: string;
    }
>;

export type TiktokMicrowaveInfluencerPostTotals = {
    cost: {
        [currency: number]: number;
    };
};

type GetTiktokMicrowaveInfluencerPostsResponse = ApiResponse<
    PaginatedApiResponseData<TiktokMicrowaveInfluencerPost> & { totals: TiktokMicrowaveInfluencerPostTotals },
    200
>;

export async function getTiktokMicrowaveInfluencerPosts(
    params: GetTiktokMicrowaveInfluencerPostsParams,
    requestInit?: RequestInit
): Promise<GetTiktokMicrowaveInfluencerPostsResponse> {
    const response = await fetchWithToken(`/api/microwave/tiktok-posts/${encodeUrlSearchParams(params)}`, requestInit);

    if (!response.ok) {
        throw new Error('Could not get microwave tiktok posts');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type PostTiktokMicrowaveInfluencerPostApiBody = Pick<TiktokMicrowaveInfluencerPost, 'post_url'> & {
    campaign_id: number;
    payment_details: string | null;
    paypal_email_address: string;
    venmo_email_address: string;
    preferred_payment_method: PaymentMethod;
};

type PostTiktokMicrowaveInfluencerPostResponse =
    | ApiResponse<null, 201>
    | ApiResponse<string[] | ApiResponseError<PostTiktokMicrowaveInfluencerPostApiBody>, 400>;

export async function postTiktokMicrowaveInfluencerPost(
    data: PostTiktokMicrowaveInfluencerPostApiBody
): Promise<PostTiktokMicrowaveInfluencerPostResponse> {
    const response = await fetchWithToken(`/api/microwave/tiktok-posts/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not add post');
    }

    return {
        status: 201,
        data: null,
    };
}

export type TiktokMicrowaveInfluencerPostPatchApiBody = Partial<
    Pick<TiktokMicrowaveInfluencerPost, 'cost' | 'currency_id'>
>;

type MakePaypalPaymentParams = {
    postId: number;
    emailMessage: string;
};

type MakePaypalPaymentResponse =
    | ApiResponse<null, 204>
    | ApiResponse<{ error: string }, 400>
    | ApiResponse<string[], 400>
    | NotFoundResponse;

export async function makePaypalPaymentOnTiktokMicrowaveInfluencerPost(
    { postId, emailMessage }: MakePaypalPaymentParams,
    requestInit?: RequestInit
): Promise<MakePaypalPaymentResponse> {
    const response = await fetchWithToken(`/api/microwave/tiktok-posts/${postId}/make-paypal-payment/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email_message: emailMessage }),
        signal: requestInit?.signal,
    });

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not make paypal payment for post ${postId}`);
    }

    return {
        status: 204,
        data: null,
    };
}

type MakeVenmoPaymentParams = {
    postId: number;
    emailMessage: string;
};

type MakeVenmoPaymentResponse =
    | ApiResponse<null, 204>
    | ApiResponse<{ error: string }, 400>
    | ApiResponse<string[], 400>
    | NotFoundResponse;

export async function makeVenmoPaymentOnTiktokMicrowaveInfluencerPost(
    { postId, emailMessage }: MakeVenmoPaymentParams,
    requestInit?: RequestInit
): Promise<MakeVenmoPaymentResponse> {
    const response = await fetchWithToken(`/api/microwave/tiktok-posts/${postId}/make-venmo-payment/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email_message: emailMessage }),
        signal: requestInit?.signal,
    });

    if (response.status === 400 || response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not make venmo payment for post ${postId}`);
    }

    return {
        status: 204,
        data: null,
    };
}

type MakeBankTransferPaymentResponse = ApiResponse<null, 204> | ApiResponse<string[], 400> | NotFoundResponse;
export async function makeBankTransferPayment(postId: number): Promise<MakeBankTransferPaymentResponse> {
    const response = await fetchWithToken(`/api/microwave/tiktok-posts/${postId}/make-bank-transfer-payment/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status === 404 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not perform operation');
    }

    return {
        status: 204,
        data: null,
    };
}
