import styles from './Release.module.css';
import { Image } from '@round/ui-kit';
import Skeleton from 'react-loading-skeleton';
import ReleaseStats from './ReleaseStats/ReleaseStats';
import { microwave } from '@round/api';

type Props = {
    release: microwave.Release | null;
    isLoading?: boolean;
};

const Release = ({ release, isLoading }: Props) => {
    return (
        <section className={styles.releaseSection}>
            <Image
                className={styles.releaseImage}
                src={release?.brand_image}
                loading={isLoading}
                skeletonCircle={false}
            />

            <div className={styles.releaseTitles}>
                <h1 className={styles.releaseTitle}>{isLoading ? <Skeleton width="15rem" /> : release?.name}</h1>
                <p className={styles.releaseSubTitle}>
                    {isLoading ? <Skeleton width="10rem" /> : `${release?.brand_name} | ${release?.client_name}`}
                </p>
            </div>

            <ReleaseStats className={styles.releaseStats} releaseId={release?.id} />
        </section>
    );
};

export default Release;
