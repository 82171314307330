import { Field, FieldHookConfig, useField } from 'formik';
import styles from './FormField.module.css';
import cn from 'classnames';
import ErrorMessage from './ErrorMessage';

type Props = FieldHookConfig<string> & { placeholder?: string; className?: string; label?: string };

const FormField = ({ label, className, placeholder, disabled, type, ...props }: Props) => {
    const [field, meta] = useField(props);

    const hasError = meta.error;

    return (
        <div className={cn(styles.field, className, { [styles.disabled]: disabled })}>
            <label className={styles.label} htmlFor={props.name}>
                {label}
            </label>
            <Field
                id={props.name}
                type={type}
                disabled={disabled}
                placeholder={placeholder}
                className={cn(styles.input, { [styles.error]: hasError })}
                component={props.component}
                {...field}
            />
            <ErrorMessage name={props.name} />
        </div>
    );
};

export default FormField;
