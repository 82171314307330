import React from 'react';
import useInstagramInfluencerPlanAudios from '../../contexts/InstagramCreatorsContext/useInstagramInfluencerPlanAudios';
import BadgeDropdown from '../../components/BadgeDropdown/BadgeDropdown';
import { ReactComponent as MusicNoteIcon } from 'assets/MusicNote.svg';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import styles from './InstagramInfluencerPlanAudios.module.css';
import cn from 'classnames';
import { InstagramAudio } from '@round/api';
import useUrlState from 'Hooks/useUrlState';

type UrlState = {
    audioId?: number;
};

const getAudioTitle = (audio: InstagramAudio | null) => {
    if (!audio) {
        return '';
    }

    return audio.title ?? audio.instagram_id;
};

const InstagramInfluencerPlanAudios = () => {
    const [, setUrlState] = useUrlState<UrlState>();

    const { audios, error, isLoading, init, isInitialized } = useInstagramInfluencerPlanAudios();

    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                init({ signal });
            }
        },
        [isInitialized, init]
    );

    return (
        <BadgeDropdown loading={isLoading || !isInitialized} value={audios.length} label="Audios" Icon={MusicNoteIcon}>
            {(setShow) => (
                <>
                    {isInitialized && !audios.length && !error && <div className={styles.error}>No audios found</div>}
                    {isInitialized && error && <div className={styles.error}>{error}</div>}
                    {isInitialized && (
                        <ul className={styles.list}>
                            {audios.map((audio) => (
                                <li
                                    key={audio.id}
                                    className={styles.listItem}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setUrlState({ audioId: audio.id });
                                        setShow(false);
                                    }}
                                >
                                    <span className={cn(styles.audio, { [styles.pending]: !audio.title })}>
                                        {getAudioTitle(audio)}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    )}
                </>
            )}
        </BadgeDropdown>
    );
};

export default InstagramInfluencerPlanAudios;
