import { createContext, Dispatch, useCallback, useContext, useMemo, useReducer } from 'react';
import { FCWithChildren } from '../../../../../utility/utility.types';
import { InfluencerPlanContext } from '../InfluencerPlanContext';
import reducer, {
    initialState,
    InstagramCreatorsContextState,
    InstagramInfluencerPostGroupStatsWithId,
    Actions,
} from './reducer';
import * as InfluencerPlanApi from '../../InfluencerPlan.api';
import {
    fetchCreateInstagramInfluencerPost,
    fetchInstagramInfluencerPosts,
    fetchInstagramInfluencerPostResults,
    fetchInstagramUserImages,
    fetchInstagramUserStats,
    getInstagramInfluencerPost,
} from '../../InfluencerPlan.api';
import * as InstagramApi from '../../api/Instagram.api';
import { deleteInstagramInfluencerPostResults, patchInstagramInfluencerPost } from '../../api/Instagram.api';
import { InstagramUserImage } from '../../../../../App.types';
import { showNotification } from '../../../../../helpers';
import { getInstagramInfluencerUsers } from '../../../../Instagram/Instagram.api';
import { InstagramUserStats } from 'Modules/Instagram/Instagram.types';
import {
    InstagramInfluencerPost,
    InstagramInfluencerPostApiBody,
    InstagramInfluencerPostResult,
} from '../../types/Instagram.types';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import { isNumber } from '../../../../../utility/utility';
import {
    deleteInfluencerPostGroup,
    getAllInfluencerPostGroups,
    getInstagramInfluencerPlanStats,
    getInstagramInfluencerPostGroupStats,
    getInstagramPosts,
    InfluencerPostGroup,
    InstagramInfluencerUser,
    patchInfluencerPostGroup,
    postInfluencerPostGroup,
    InstagramPost,
    PostInfluencerPostGroupData,
    ApiResponse,
} from '@round/api';

type InstagramCreatorsContextValues = InstagramCreatorsContextState & {
    prefetch: () => Promise<void>;
    init: () => Promise<void>;
    createInstagramInfluencerPostGroup: (
        data?: Partial<Pick<PostInfluencerPostGroupData, 'audio_id'>>
    ) => Promise<ApiResponse<InfluencerPostGroup, 201> | void>;
    deleteInstagramInfluencerPostGroup: (groupId: number) => Promise<void>;
    updateInstagramInfluencerPostGroup: (
        groupId: number,
        data: Partial<
            Pick<InfluencerPostGroup, 'budget' | 'name' | 'brief' | 'brief_turnaround' | 'is_hidden' | 'audio_id'>
        >
    ) => Promise<ApiResponse<InfluencerPostGroup, 200> | void>;
    increaseInstagramInfluencerPostGroupsOrderingIndex: (groupId: number) => Promise<void>;
    decreaseInstagramInfluencerPostGroupsOrderingIndex: (groupId: number) => Promise<void>;
    createInstagramInfluencerPost: (
        groupId: number,
        post: InfluencerPlanApi.CreateInstagramInfluencerPostBody
    ) => Promise<InfluencerPlanApi.PostInstagramInfluencerPostResponse | void>;
    updateInstagramInfluencerPost: (postId: number, data: Partial<InstagramInfluencerPostApiBody>) => Promise<void>;
    setInstagramInfluencerPost: (post: InstagramInfluencerPost) => void;
    setInstagramInfluencerPostResult: (results: InstagramInfluencerPostResult) => void;
    instagramInfluencerPostResultDeleted: (resultId: number) => void;
    refreshInstagramInfluencerPost: (postId: number) => Promise<void>;
    deleteInstagramInfluencerPost: (postId: number) => Promise<void>;
    setInstagramInfluencerUser: (influencer: InstagramInfluencerUser) => void;
};

export const InstagramCreatorsContext = createContext<InstagramCreatorsContextValues | null>(null);
export const InstagramCreatorsDispatchContext = createContext<Dispatch<Actions> | null>(null);
export const InstagramCreatorsContextProvider: FCWithChildren = ({ children }) => {
    const { influencerPlan } = useContext(InfluencerPlanContext);
    const [state, dispatch] = useReducer(reducer, initialState);

    const prefetch = useCallback(async () => {
        if (!influencerPlan) {
            return;
        }

        try {
            const groups = await getAllInfluencerPostGroups('instagram', influencerPlan.id);
            dispatch({
                type: 'prefetchCompleted',
                payload: { instagramInfluencerPostGroups: groups },
            });
        } catch {
            // no-op
        }
    }, [influencerPlan]);

    const fetchInfluencerUsers = useCallback(async (ids: number[]): Promise<InstagramInfluencerUser[]> => {
        if (!ids.length) {
            return [];
        }

        return getInstagramInfluencerUsers({ id: ids.toString(), page_size: ids.length }).then((r) => r.data.results);
    }, []);

    const fetchInstagramPosts = useCallback(async (ids: number[]): Promise<InstagramPost[]> => {
        if (!ids.length) {
            return [];
        }

        return getInstagramPosts({ id: ids.join(', '), page_size: ids.length }).then((res) => {
            if (res.status !== 200) {
                throw new Error('Could not fetch instagram posts');
            }

            return res.data.results;
        });
    }, []);

    const fetchGroupStats = useCallback(async (groupIds: number[]) => {
        if (!groupIds.length) {
            return [];
        }

        const results = await Promise.allSettled(
            groupIds.map((id) => {
                return getInstagramInfluencerPostGroupStats(id)
                    .then((response) => {
                        if (response.status !== 200) {
                            throw new Error(`Could not fetch influencer post group ${id} stats`);
                        }

                        return { ...response.data, group_id: id } as InstagramInfluencerPostGroupStatsWithId;
                    })
                    .catch((e) => {
                        showNotification(e.message, 'error');
                        throw e;
                    });
            })
        );

        return results
            .filter(
                (result): result is PromiseFulfilledResult<InstagramInfluencerPostGroupStatsWithId> =>
                    result.status === 'fulfilled'
            )
            .map((result) => result.value);
    }, []);

    const fetchPlanStats = useCallback(async (planId: number) => {
        try {
            const response = await getInstagramInfluencerPlanStats(planId);
            if (response.status !== 200) {
                throw new Error(`Could not fetch influencer plan ${planId} stats`);
            }

            return response.data;
        } catch (e) {
            const errorMessage = e instanceof Error ? e.message : 'Could not fetch plan stats';
            showNotification(errorMessage, 'error');
        }
    }, []);

    const init = useCallback(async () => {
        if (!state.isPrefetchCompleted || !influencerPlan) {
            return;
        }

        try {
            const [influencerPosts, postResults, groupStats, planStats] = await Promise.all([
                fetchInstagramInfluencerPosts(influencerPlan.id),
                fetchInstagramInfluencerPostResults({ plan_id: influencerPlan.id.toString() }),
                fetchGroupStats(state.instagramInfluencerPostGroups.map((g) => g.id)),
                fetchPlanStats(influencerPlan.id),
            ]);

            const influencerUserIds = Array.from(new Set(influencerPosts.map((p) => p.influencer_id).filter(isNumber)));
            const instagramPostIds = influencerPosts.map((influencerPost) => influencerPost.post).filter(isNumber);
            const [influencerUsers, instagramPosts] = await Promise.all([
                fetchInfluencerUsers(influencerUserIds),
                fetchInstagramPosts(instagramPostIds),
            ]);

            const instagramUserIds = Array.from(
                new Set(
                    influencerUsers
                        .map((influencer) => influencer.user)
                        .filter(isNumber)
                        .concat(influencerPosts.map((p) => p.instagram_user).filter(isNumber))
                )
            );

            let instagramUserStats: InstagramUserStats[] = [];
            let instagramUserImages: InstagramUserImage[] = [];
            if (instagramUserIds.length) {
                const [userStatsResponse, userImagesResponse] = await Promise.all([
                    fetchInstagramUserStats(instagramUserIds),
                    fetchInstagramUserImages(instagramUserIds),
                ]);
                instagramUserStats = userStatsResponse;
                instagramUserImages = userImagesResponse;
            }

            dispatch({
                type: 'initCompleted',
                payload: {
                    instagramInfluencerPosts: influencerPosts,
                    instagramInfluencerUsers: influencerUsers,
                    instagramInfluencerPostResults: postResults,
                    instagramPosts: instagramPosts,
                    instagramUserStats: instagramUserStats,
                    instagramUserImages: instagramUserImages,
                    instagramInfluencerPostGroupStats: groupStats,
                    instagramInfluencerPlanStats: planStats,
                },
            });
        } catch {
            // no-op
        }
    }, [
        fetchGroupStats,
        fetchInfluencerUsers,
        fetchInstagramPosts,
        fetchPlanStats,
        influencerPlan,
        state.instagramInfluencerPostGroups,
        state.isPrefetchCompleted,
    ]);

    const createInstagramInfluencerPostGroup = useCallback<
        InstagramCreatorsContextValues['createInstagramInfluencerPostGroup']
    >(
        async (data) => {
            if (!influencerPlan) {
                return;
            }

            const groupResponse = await postInfluencerPostGroup('instagram', {
                influencer_plan: influencerPlan.id,
                name: `Influencer Group ${state.instagramInfluencerPostGroups.length + 1}`,
                ordering_index: state.instagramInfluencerPostGroups.length,
                budget: '1.00',
                is_hidden: true,
                ...data,
            });

            if (groupResponse.status === 400) {
                throw new Error('Couldnt create post group');
            }

            if (groupResponse.status === 201) {
                dispatch({ type: 'influencerPostGroupCreated', payload: groupResponse.data });
            }

            return groupResponse;
        },
        [influencerPlan, state.instagramInfluencerPostGroups.length]
    );

    const deleteInstagramInfluencerPostGroup = useCallback(
        async (groupId: number) => {
            const groupToDelete = state.instagramInfluencerPostGroups.find((g) => g.id === groupId);
            if (!groupToDelete) {
                return;
            }

            const groupPosts = state.instagramInfluencerPosts.filter((p) => p.group_id === groupId);
            const postResults = state.instagramInfluencerPostResults.filter((result) =>
                Boolean(groupPosts.find((post) => post.id === result.post))
            );
            await Promise.all(postResults.map((res) => InstagramApi.deleteInstagramInfluencerPostResults(res.id)));
            await Promise.all(groupPosts.map((post) => InstagramApi.deleteInstagramInfluencerPost(post.id)));
            const deleteResponse = await deleteInfluencerPostGroup('instagram', groupId);
            if (deleteResponse.status === 404) {
                throw new Error(`Could not delete group ${groupId}`);
            }
            if (deleteResponse.status === 204) {
                dispatch({ type: 'influencerPostGroupDeleted', payload: groupId });
                const groupsToIncreaseInOrder = state.instagramInfluencerPostGroups.filter(
                    (g) => g.ordering_index > groupToDelete.ordering_index
                );

                const responses = await Promise.all(
                    groupsToIncreaseInOrder.map((group) =>
                        patchInfluencerPostGroup('instagram', group.id, { ordering_index: group.ordering_index - 1 })
                    )
                );

                const updated = responses.filter((r) => r.status === 200).map((r) => r.data as InfluencerPostGroup);
                dispatch({ type: 'influencerPostGroupsBatchUpdated', payload: updated });
            }
        },
        [state.instagramInfluencerPostGroups, state.instagramInfluencerPostResults, state.instagramInfluencerPosts]
    );

    const updateInstagramInfluencerPostGroup: InstagramCreatorsContextValues['updateInstagramInfluencerPostGroup'] = useCallback(
        async (groupId, data) => {
            const response = await patchInfluencerPostGroup('instagram', groupId, data);
            if (response.status === 200) {
                dispatch({ type: 'influencerPostGroupUpdated', payload: response.data });
                showNotification('Group updated', 'info');
                return response;
            }

            if (response.status === 400) {
                throw new Error(`Group not found`);
            }
        },
        []
    );

    const increaseInstagramInfluencerPostGroupsOrderingIndex = useCallback(
        async (groupId: number) => {
            const group = state.instagramInfluencerPostGroups.find((g) => g.id === groupId);
            if (!group) {
                return;
            }

            const response = await patchInfluencerPostGroup('instagram', groupId, {
                ordering_index: group.ordering_index - 1,
            });

            if (response.status === 200) {
                dispatch({ type: 'influencerPostGroupUpdated', payload: response.data });
            }

            if (response.status === 400) {
                throw new Error('Group not found');
            }
        },
        [state.instagramInfluencerPostGroups]
    );

    const decreaseInstagramInfluencerPostGroupsOrderingIndex = useCallback(
        async (groupId: number) => {
            const group = state.instagramInfluencerPostGroups.find((g) => g.id === groupId);
            if (!group) {
                return;
            }

            const response = await patchInfluencerPostGroup('instagram', groupId, {
                ordering_index: group.ordering_index + 1,
            });

            if (response.status === 200) {
                dispatch({ type: 'influencerPostGroupUpdated', payload: response.data });
            }

            if (response.status === 400) {
                throw new Error('Group not found');
            }
        },
        [state.instagramInfluencerPostGroups]
    );

    const createInstagramInfluencerPost = useCallback(
        async (groupId: number, post: InfluencerPlanApi.CreateInstagramInfluencerPostBody) => {
            if (!influencerPlan) {
                return;
            }

            const response = await fetchCreateInstagramInfluencerPost(influencerPlan.id, groupId, post);
            if (response.status === 201) {
                dispatch({ type: 'instagramInfluencerPostCreated', payload: response.data });
                return response;
            }
            return response;
        },
        [influencerPlan]
    );

    const updateInstagramInfluencerPost = useCallback(
        async (postId: number, data: Partial<InstagramInfluencerPostApiBody>) => {
            const response = await patchInstagramInfluencerPost(postId, data);
            if (response.status === 400) {
                throw new Error(`Couldn't update influencer post ${postId}`);
            }

            dispatch({ type: 'instagramInfluencerPostUpdated', payload: response.data });
        },
        []
    );

    const refreshInstagramInfluencerPost = useCallback(async (postId: number) => {
        const response = await getInstagramInfluencerPost(postId);
        dispatch({ type: 'instagramInfluencerPostUpdated', payload: response });
    }, []);

    const deleteInstagramInfluencerPost = useCallback(
        async (postId: number) => {
            const result = state.instagramInfluencerPostResults.find((result) => result.post === postId);
            if (result) {
                await deleteInstagramInfluencerPostResults(result.id);
            }

            await InstagramApi.deleteInstagramInfluencerPost(postId);
            dispatch({ type: 'instagramInfluencerPostDeleted', payload: postId });
        },
        [state.instagramInfluencerPostResults]
    );

    useAbortableEffect(
        (signal) => {
            if (!state.isInitCompleted) {
                return;
            }

            const existingInfluencersIds = state.instagramInfluencerUsers.map((influencer) => influencer.id);
            const influencerUsersToFetch = Array.from(
                new Set(
                    state.instagramInfluencerPosts
                        .map((p) => p.influencer_id)
                        .filter((id): id is number => typeof id === 'number')
                )
            ).filter((influencerId) => !existingInfluencersIds.includes(influencerId));

            if (influencerUsersToFetch.length) {
                getInstagramInfluencerUsers(
                    {
                        id: influencerUsersToFetch.toString(),
                        page_size: influencerUsersToFetch.length,
                    },
                    { signal }
                )
                    .then((response) => {
                        dispatch({ type: 'instagramInfluencerUsersFetched', payload: response.data.results });
                    })
                    .catch(() => {});
            }
        },
        [state.instagramInfluencerPosts, state.instagramInfluencerUsers, state.isInitCompleted]
    );

    const values: InstagramCreatorsContextValues = useMemo(
        () => ({
            ...state,
            prefetch,
            init,
            createInstagramInfluencerPostGroup,
            deleteInstagramInfluencerPostGroup,
            updateInstagramInfluencerPostGroup,
            increaseInstagramInfluencerPostGroupsOrderingIndex,
            decreaseInstagramInfluencerPostGroupsOrderingIndex,
            createInstagramInfluencerPost,
            updateInstagramInfluencerPost,
            refreshInstagramInfluencerPost,
            deleteInstagramInfluencerPost,
            setInstagramInfluencerPost: (post) => dispatch({ type: 'instagramInfluencerPostUpdated', payload: post }),
            setInstagramInfluencerPostResult: (result) =>
                dispatch({ type: 'setInstagramInfluencerPostResult', payload: result }),
            instagramInfluencerPostResultDeleted: (id) =>
                dispatch({ type: 'instagramInfluencerPostResultDeleted', payload: id }),
            setInstagramInfluencerUser: (influencer) =>
                dispatch({ type: 'instagramInfluencerUserUpdated', payload: influencer }),
        }),
        [
            createInstagramInfluencerPost,
            createInstagramInfluencerPostGroup,
            decreaseInstagramInfluencerPostGroupsOrderingIndex,
            deleteInstagramInfluencerPost,
            deleteInstagramInfluencerPostGroup,
            increaseInstagramInfluencerPostGroupsOrderingIndex,
            init,
            prefetch,
            refreshInstagramInfluencerPost,
            state,
            updateInstagramInfluencerPost,
            updateInstagramInfluencerPostGroup,
        ]
    );

    return (
        <InstagramCreatorsContext.Provider value={values}>
            <InstagramCreatorsDispatchContext.Provider value={dispatch}>
                {children}
            </InstagramCreatorsDispatchContext.Provider>
        </InstagramCreatorsContext.Provider>
    );
};
