import Dropdown, { Props as DropdownProps } from 'ui-new/Dropdown/Dropdown';
import FilterBadgeClearable from 'ui/Filters/FilterBadge/FilterBadgeClearable/FilterBadgeClearable';
import FilterHeader from 'ui/Filters/FilterHeader/FilterHeader';
import { CommonFilterProps } from '../helpers';
import { isUndefined } from 'lodash';
import { DebounceInput } from 'react-debounce-input';
import styles from './MinMaxFilter.module.css';
import cn from 'classnames';
import { isNumber } from 'utility/utility';

const DEFAULT_DEBOUNCE_TIMEOUT = 700;

type ChildProps = {
    value: number | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    type: string;
    placeholder: string;
    className: string;
    debounceTimeout: number;
};

type Props = Omit<DropdownProps, 'children' | 'className' | 'control'> &
    CommonFilterProps & {
        min?: number | undefined;
        max?: number | undefined;
        onChange: ({ min, max }: { min: number | undefined; max: number | undefined }) => void;
        debounceTimeout?: number;
        children?: ({ minProps, maxProps }: { minProps: ChildProps; maxProps: ChildProps }) => React.ReactNode;
    };

const MinMaxFilter = ({
    min,
    max,
    onChange,
    isOpen,
    onOpen,
    onClose,
    onRemove,
    onClear,
    label,
    icon,
    debounceTimeout = DEFAULT_DEBOUNCE_TIMEOUT,
    children,
    isDisabled,
}: Props) => {
    const hasBothValues = [min, max].every((value) => typeof value === 'number');
    const hasNeitherValue = [min, max].every(isUndefined);

    const comparitiveSymbol = ((min: number | undefined, max: number | undefined) => {
        if (hasNeitherValue) {
            return '';
        }
        if (min === undefined) {
            return '<';
        }
        if (max === undefined) {
            return '>';
        }
    })(min, max);

    const hasError = hasBothValues && min! > max!;

    const commonProps = {
        type: 'number',
        debounceTimeout,
        className: cn(styles.input, { [styles.error]: hasError }),
    };

    const minProps = {
        value: min,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ min: e.target.value !== '' ? Number(e.target.value) : undefined, max }),
        placeholder: 'Min',
        ...commonProps,
    };

    const maxProps = {
        value: max,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            onChange({ min, max: e.target.value !== '' ? Number(e.target.value) : undefined }),
        placeholder: 'Max',
        ...commonProps,
    };

    return (
        <Dropdown
            isOpen={isOpen}
            onClose={onClose}
            control={
                <FilterBadgeClearable isDisabled={isDisabled} onClick={onOpen} onClear={onRemove}>
                    {label}:
                    <div className={styles.valueLabel}>
                        {/* e.g. > 23 || < 35 || 12 - 87 */}
                        {!!comparitiveSymbol && <span>{comparitiveSymbol}</span>}
                        {typeof min === 'number' && <span>{min}</span>}
                        {(hasBothValues || hasNeitherValue) && <span>-</span>}
                        {typeof max === 'number' && <span>{max}</span>}
                    </div>
                </FilterBadgeClearable>
            }
        >
            <FilterHeader name={label} icon={icon} hasValue={[min, max].some(isNumber)} onReset={onClear} />

            <div className={styles.container}>
                {typeof children === 'function' ? (
                    children({
                        minProps,
                        maxProps,
                    })
                ) : (
                    <>
                        <DebounceInput {...minProps} />
                        <DebounceInput {...maxProps} />
                    </>
                )}
            </div>
        </Dropdown>
    );
};

export default MinMaxFilter;
