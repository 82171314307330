import { microwave } from '@round/api';
import { StringSearchParams, UrlData, useSessionUrlState } from '@round/utils';
import { useEffect } from 'react';
import useRelease from '../../../Release/useRelease';

type UrlState = Required<Pick<microwave.GetInvitesParams, 'page' | 'page_size'>> & {
    ordering: Exclude<microwave.GetInvitesParams['ordering'], undefined>;
};
type UrlStateKey = keyof UrlState;

const initialState: UrlState = {
    page: 1,
    page_size: 10,
    ordering: '-post_create_time',
};

const parseUrlState = ({
    searchParams: { page, page_size, ordering },
}: UrlData<StringSearchParams<UrlState>>): UrlState => ({
    page: page ? Number(page) : initialState.page,
    page_size: page_size ? Number(page_size) : initialState.page_size,
    ordering: (ordering as UrlState['ordering']) ?? initialState.ordering,
});

type HookConfig = {
    campaignId: number;
    shouldPersist?: boolean;
};

export default function useInstagramPostedUrlState({ shouldPersist, campaignId }: HookConfig) {
    const { data: release } = useRelease();

    const { url, mergeSearchParams, clearSession, persistSession } = useSessionUrlState<UrlState>(
        `mw-ig-campaign-posted-params-${campaignId}`,
        (url, session, { merge }) => {
            const urlKeys = Object.keys(parseUrlState({ searchParams: {}, hash: '' })) as UrlStateKey[];

            const hasAnyParamSet = Object.keys(url.searchParams).some((k) => urlKeys.includes(k as UrlStateKey));
            if (hasAnyParamSet) {
                return url;
            }

            return merge(url, { searchParams: { ...initialState, ...session?.searchParams } });
        }
    );

    useEffect(() => {
        return () => {
            if (shouldPersist) {
                persistSession({ searchParams: parseUrlState(url) });
            }
        };
    }, [persistSession, shouldPersist, url]);

    useEffect(() => {
        return () => {
            if (!release?.id) {
                clearSession();
            }
        };
    }, [clearSession, release?.id]);

    return {
        state: parseUrlState(url),
        mergeSearchParams: mergeSearchParams,
    };
}
