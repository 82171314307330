import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReleaseContext } from '../../ReleaseContext';
import { useCallback } from 'react';
import { getPaginatedIdQueryParam, microwave, SuccessfulApiResponse } from '@round/api';
import { useAbortableEffect } from '@round/utils';
import { MakeRequired } from 'utility/utility.types';
import { mapStringListToArray } from 'helpers';
import { omit } from 'lodash';

type Params = MakeRequired<Omit<microwave.GetInvitesParams, 'is_posted'>, 'campaign_id'>;

type Config = {
    params: Params;
    isDisabled?: boolean;
};

const BATCH_SIZE = 25;

const requestDataInBatches = async (ids: string[], params: Omit<Params, 'id'>, requestInit?: RequestInit) => {
    if (!params.page_size || !params.page) {
        throw new Error('page_size is required');
    }

    const paginatedIds = getPaginatedIdQueryParam(ids, BATCH_SIZE);

    const numberOfBatchesToInclude = Math.ceil(params.page_size / BATCH_SIZE);
    const offsetIndex = (params.page - 1) * numberOfBatchesToInclude;
    const rangeOfBatches = paginatedIds.slice(offsetIndex, offsetIndex + numberOfBatchesToInclude);

    let requests = rangeOfBatches.map((ids) =>
        microwave.getInvites({ id: ids.toString(), page: 1, page_size: BATCH_SIZE, is_posted: false }, requestInit)
    );
    const responses = await Promise.all(requests);

    if (responses.some((response) => response.status !== 200)) {
        throw new Error('Batching requests failed');
    }

    const lastResponse = responses[responses.length - 1] as SuccessfulApiResponse<microwave.GetInvitesResponse>;

    const mergedData = {
        ...lastResponse.data,
        count: ids.length,
        results: (responses as SuccessfulApiResponse<microwave.GetInvitesResponse>[]).flatMap((r) => r.data.results),
    };
    return { status: 200, data: mergedData };
};

export default function useContacted({ params, isDisabled }: Config) {
    const state = useNonNullContextSelector(ReleaseContext, ([values]) => values.contacted[params.campaign_id]);
    const dispatch = useNonNullContextSelector(ReleaseContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (params: Params, requestInit?: RequestInit) => {
            const campaignId = params.campaign_id;
            dispatch({ type: 'loadContacted', payload: campaignId });

            try {
                // Since ids are uuids, we are limited in how many we can send in a single request
                // so if the id param is present we will batch the requests
                const ids = mapStringListToArray(params.id);
                const response =
                    ids.length > BATCH_SIZE
                        ? await requestDataInBatches(ids, omit(params, 'id'), requestInit)
                        : await microwave.getInvites({ ...params, is_posted: false }, requestInit);

                if (response.status !== 200) {
                    dispatch({
                        type: 'errorLoadingContacted',
                        payload: { campaignId, message: 'Could not load contacted' },
                    });
                    return response;
                }

                dispatch({
                    type: 'contactedInitialized',
                    payload: {
                        campaignId,
                        data: response.data,
                    },
                });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    dispatch({ type: 'contactedIdle', payload: campaignId });
                    throw e;
                }

                dispatch({
                    type: 'errorLoadingContacted',
                    payload: { campaignId, message: 'Could not load contacted' },
                });
                throw e;
            }
        },
        [dispatch]
    );

    const isInitialized = state?.status === 'success' || state?.status === 'error';
    useAbortableEffect(
        (signal) => {
            if (isDisabled || isInitialized) {
                return;
            }

            fetchData(
                {
                    page: params.page || 1,
                    page_size: params.page_size,
                    campaign_id: params.campaign_id,
                    ordering: params.ordering,
                    chase_email_count: params.chase_email_count,
                    id: params.id,
                    influencer_location_id: params.influencer_location_id,
                    content_tags: params.content_tags,
                    exclude_content_tags: params.exclude_content_tags,
                },
                { signal }
            ).catch(() => {});
        },
        [
            params.page,
            params.page_size,
            params.campaign_id,
            fetchData,
            isDisabled,
            params.ordering,
            params.chase_email_count,
            params.id,
            params.influencer_location_id,
            params.content_tags,
            params.exclude_content_tags,
            isInitialized,
        ]
    );

    const reset = useCallback(() => dispatch({ type: 'resetCampaignContacted', payload: params.campaign_id }), [
        dispatch,
        params.campaign_id,
    ]);

    const update = useCallback<typeof microwave.patchInvite>(
        async (id, data) => {
            const response = await microwave.patchInvite(id, data);
            if (response.status === 200) {
                dispatch({ type: 'updateContacted', payload: { campaignId: params.campaign_id, data: response.data } });
            }

            return response;
        },
        [dispatch, params.campaign_id]
    );

    return {
        data: state?.data,
        status: state?.status,
        error: state?.error,
        fetchData,
        reset,
        update,
    };
}
