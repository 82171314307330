import { encodeUrlSearchParams, fetchAll, fetchWithToken } from '../../helpers';
import { ApiResponse, NotFoundResponse } from '../../types';
import {
    InfluencerPostGroup,
    InstagramInfluencerPostGroupStats,
    TiktokInfluencerPostGroupStats,
} from './InfluencerPostGroup.types';

type Platform = 'tiktok' | 'instagram';
export async function getAllInfluencerPostGroups(
    platform: Platform,
    influencer_plan_id: string | number,
    requestInit?: RequestInit
): Promise<InfluencerPostGroup[]> {
    return fetchAll<InfluencerPostGroup>(
        `/api/advertising/viewsets/${platform}-influencer-post-group/${encodeUrlSearchParams({ influencer_plan_id })}`,
        requestInit
    );
}

export type PostInfluencerPostGroupData = Pick<
    InfluencerPostGroup,
    'influencer_plan' | 'name' | 'budget' | 'ordering_index'
> &
    Partial<Pick<InfluencerPostGroup, 'brief' | 'brief_turnaround' | 'is_hidden' | 'audio_id'>>;
export async function postInfluencerPostGroup(
    platform: Platform,
    data: PostInfluencerPostGroupData
): Promise<ApiResponse<InfluencerPostGroup, 201> | ApiResponse<{ detail: string }, 400>> {
    const response = await fetchWithToken(`/api/advertising/viewsets/${platform}-influencer-post-group/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not create influencer post group');
    }

    return {
        status: 201,
        data: await response.json(),
    };
}

export async function deleteInfluencerPostGroup(
    platform: Platform,
    influencerPostGroupId: number
): Promise<ApiResponse<null, 204> | ApiResponse<{ detail: string }, 404>> {
    const response = await fetchWithToken(
        `/api/advertising/viewsets/${platform}-influencer-post-group/${influencerPostGroupId}/`,
        {
            method: 'DELETE',
        }
    );

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Could not delete influencer post group');
    }

    return { status: 204, data: null };
}

export async function patchInfluencerPostGroup(
    platform: Platform,
    groupId: number,
    data: Partial<
        Pick<
            InfluencerPostGroup,
            'budget' | 'name' | 'ordering_index' | 'brief' | 'brief_turnaround' | 'is_hidden' | 'audio_id'
        >
    >
): Promise<ApiResponse<InfluencerPostGroup, 200> | ApiResponse<{ detail: string }, 400>> {
    const response = await fetchWithToken(`/api/advertising/viewsets/${platform}-influencer-post-group/${groupId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 400) {
        return {
            status: 400,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not patch ${platform} influencer post group ${groupId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetTiktokInfluencerPostGroupStatsResponse = ApiResponse<TiktokInfluencerPostGroupStats, 200> | NotFoundResponse;

export async function getTiktokInfluencerPostGroupStats(
    groupId: number,
    requestInit?: RequestInit
): Promise<GetTiktokInfluencerPostGroupStatsResponse> {
    const response = await fetchWithToken(
        `/api/advertising/tiktok-influencer-post-group/${groupId}/live-stats/`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: 404,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get tiktok influencer post group ${groupId} stats`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

type GetInstagramInfluencerPostGroupStatsResponse =
    | ApiResponse<InstagramInfluencerPostGroupStats, 200>
    | NotFoundResponse;

export async function getInstagramInfluencerPostGroupStats(
    groupId: number,
    requestInit?: RequestInit
): Promise<GetInstagramInfluencerPostGroupStatsResponse> {
    const response = await fetchWithToken(
        `/api/advertising/instagram-influencer-post-group/${groupId}/live-stats/`,
        requestInit
    );

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not get instagram influencer post group ${groupId} stats`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
