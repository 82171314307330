import { getInfluencerPlans, InfluencerPlan } from '@round/api';
import { useDataState } from '@round/utils';
import useAbortableEffect from 'Hooks/useAbortableEffect';
import { useCallback } from 'react';

export function useInfluencerPlan(releaseId: number) {
    const [state, setState] = useDataState<InfluencerPlan | null>({
        status: 'idle',
        error: null,
        data: null,
    });

    const fetchData = useCallback(
        async (releaseId: number, requestInit?: RequestInit) => {
            const setError = () => setState('error', { error: 'Could not get influencer plan' });
            try {
                setState('loading');
                const response = await getInfluencerPlans({ release_id: releaseId }, requestInit);
                if (response.status === 404) {
                    setError();
                    return;
                }

                setState('success', { data: response.data.results[0] || null });
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    return;
                }

                setError();
            }
        },
        [setState]
    );

    const isInitialized = state.status === 'error' || state.status === 'success';
    useAbortableEffect(
        (signal) => {
            if (!isInitialized) {
                fetchData(releaseId, { signal }).catch(() => {});
            }
        },
        [fetchData, isInitialized, releaseId]
    );

    return {
        ...state,
    };
}
