import { useLayoutEffect, useState } from 'react';
import { Filter } from '../useFilterManager';
import styles from './FiltersMenu.module.css';
import { Popover } from '@round/ui-kit';
import IconButton from '../../Buttons/IconButton/IconButton';
import { ReactComponent as FilterIcon } from 'assets/Filter.svg';

type Props<T extends string> = {
    filters: Filter<T>[];
    onSelect: (filter: Filter<T>) => void;
    isDisabled?: boolean;
    hint?: string;
};

const FiltersMenu = <T extends string>({ filters, onSelect, isDisabled, hint }: Props<T>) => {
    const [controlRef, setControlRef] = useState<HTMLButtonElement | null>(null);
    const [dropdownRef, setDropdownRef] = useState<HTMLDivElement | null>(null);
    const [recalcDependency, setRecalcDependency] = useState<Array<number>>([]);

    useLayoutEffect(() => {
        if (!dropdownRef) {
            return;
        }
        const resizeObserver = new ResizeObserver(() => {
            setRecalcDependency([dropdownRef.clientWidth, dropdownRef.clientHeight]);
        });
        resizeObserver.observe(dropdownRef);
        return () => resizeObserver.disconnect();
    }, [dropdownRef]);

    return (
        <>
            <IconButton ref={setControlRef} disabled={isDisabled} hint={hint}>
                <FilterIcon width="1.25rem" height="1.25rem" />
            </IconButton>
            <Popover
                disabled={isDisabled}
                showOn="click"
                anchorElement={controlRef}
                options={{
                    placement: 'bottom-end',
                    modifiers: [
                        { name: 'offset', options: { offset: [0, 8] } },
                        { name: 'recalcDependency', options: recalcDependency },
                    ],
                }}
            >
                {(setShow) => (
                    <>
                        <div className={styles.dropdown} ref={setDropdownRef}>
                            <div className={styles.title}>Filters</div>
                            <div className={styles.list}>
                                {filters.map((filter) => (
                                    <div
                                        key={filter.name}
                                        className={styles.item}
                                        onClick={() => {
                                            onSelect(filter);
                                            setShow(false);
                                        }}
                                    >
                                        <filter.icon className={styles.icon} />
                                        {filter.label}
                                    </div>
                                ))}
                                {!filters.length && <>All filters selected</>}
                            </div>
                        </div>
                    </>
                )}
            </Popover>
        </>
    );
};

export default FiltersMenu;
