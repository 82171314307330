import { CommonFilterProps } from 'ui-new/Filters/helpers';
import { ContentTagsFilter } from 'Modules/Tags/components/Filters/ContentTagsFilter/ContentTagsFilter';
import {
    isExcludeCondition,
    isIncludeCondition,
    mapConditionsToApi,
    mapExcludeConditionsToApi,
    parseConditionsFromUrlString,
    stringifyConditionsToUrlString,
} from 'Modules/Tags/components/Filters/helpers';
import { isEqual } from 'lodash';
import useMicrowaveContentTags from '../../hooks/useMicrowaveContentTags';
import { conditionTypeOptionsWithExclude } from '@round/ui-kit';

type Props = Omit<CommonFilterProps, 'icon' | 'onClear'> & {
    value: string | undefined;
    onChange: (value: string | undefined, shouldRefreshData: boolean) => void;
};

const MicrowaveContentTagsFilter = ({
    label,
    isOpen,
    onOpen,
    onClose,
    onRemove,
    value,
    onChange,
    isDisabled,
}: Props) => {
    const { data: contentTags, status: contentTagsStatus } = useMicrowaveContentTags();
    const isContentTagsLoading = contentTagsStatus === 'loading';
    const contentTagsOptions =
        contentTags?.map((tag) => ({
            label: tag.name,
            value: tag.id,
        })) ?? [];

    return (
        <ContentTagsFilter
            isDisabled={isDisabled}
            name={label}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            onRemove={onRemove}
            options={contentTagsOptions}
            areOptionsLoading={isContentTagsLoading}
            conditionOptions={conditionTypeOptionsWithExclude}
            conditions={parseConditionsFromUrlString(value)}
            onChange={(conditions) => {
                const urlTags = parseConditionsFromUrlString(value);
                const shouldRefreshData =
                    !isEqual(
                        mapConditionsToApi(conditions.filter(isIncludeCondition)),
                        mapConditionsToApi(urlTags.filter(isIncludeCondition))
                    ) ||
                    !isEqual(
                        mapExcludeConditionsToApi(conditions.filter(isExcludeCondition)),
                        mapExcludeConditionsToApi(urlTags.filter(isExcludeCondition))
                    );

                onChange(stringifyConditionsToUrlString(conditions), shouldRefreshData);
            }}
        />
    );
};

export default MicrowaveContentTagsFilter;
