import { InstagramAudio, microwave } from '@round/api';
import { getTableMetaHelper, PopoverOnOverflow, Skeleton, SkeletonTableCell } from '@round/ui-kit';
import { ColumnDef } from '@tanstack/react-table';
import WrapperPaginationTable, {
    WrapperPaginationTableProps,
} from 'ui/WrapperTable/WrapperPaginationTable/WrapperPaginationTable';
import DecisionCell, { Meta as DecisionCellMeta } from './components/DecisionCell/DecisionCell';
import styles from './ApproveInstagramPostsTable.module.css';
import ReleaseCell, { Meta as ReleaseCellMeta } from '../../../components/cells/ReleaseCell/ReleaseCell';
import PostLinkCell from 'Modules/Advertising/Microwave/components/PostLinkCell/PostLinkCell';
import moment from 'moment';
import { buildInstagramAudioUrl, buildInstagramUserUrl } from 'helpers';
import TooltipCard from 'ui/DataDisplay/TooltipCard/TooltipCard';
import AudioCell, { Meta as AudioCellMeta } from 'Modules/Advertising/Microwave/components/cells/AudioCell/AudioCell';
import { AudioPlayerProvider } from 'Modules/AudioPlayer/AudioPlayerContext';
import { AudioData } from 'Modules/Advertising/Microwave/components/AudioBadge/AudioBadge';
import cn from 'classnames';

export type Row = microwave.PaymentRequest & {
    influencer: microwave.MicrowaveInfluencer | null;
    release: microwave.Release | null;
    audio: InstagramAudio | null;
};

type BaseMeta = {
    isLoading?: boolean;
    getIsInfluencerLoading: (row: Row) => boolean;
};

type Meta = BaseMeta & DecisionCellMeta & ReleaseCellMeta<Row> & AudioCellMeta<Row>;

export type Props = Pick<
    WrapperPaginationTableProps<Row>,
    'data' | 'page' | 'setPage' | 'setPageSize' | 'pageSize' | 'count' | 'rowSpanHelper'
> & {
    meta: Meta;
    hasError?: boolean;
};

const getTableMeta = getTableMetaHelper<Meta>();

const ApproveInstagramPostsTable = <TRow extends Row>({ data, hasError, ...props }: Props) => {
    const columns: ColumnDef<Row, any>[] = [
        {
            id: 'release',
            header: 'Release',
            accessorFn: (row) => row.release,
            cell: ReleaseCell,
        },
        {
            id: 'audio',
            header: 'Audio',
            accessorFn: (row): AudioData => ({
                title: row.audio?.title || row.audio?.instagram_id || '-',
                imageUrl: row.audio?.thumbnail_url || '',
                platformAudioUrl: buildInstagramAudioUrl(row.audio?.instagram_id) || '',
            }),
            cell: AudioCell,
        },
        {
            id: 'brief',
            header: 'Brief',
            accessorFn: (row) => row.brief,
            meta: {
                className: styles.briefColumn,
            },
            cell: ({ getValue, table }) => {
                const { isLoading } = getTableMeta(table);

                if (isLoading) {
                    return <Skeleton />;
                }

                return (
                    <PopoverOnOverflow
                        popoverContent={<TooltipCard className={styles.briefPopover}>{getValue()}</TooltipCard>}
                    >
                        {(setRef) => (
                            <div className={styles.briefContainer} ref={setRef}>
                                <p className={styles.brief}>{getValue()}</p>
                            </div>
                        )}
                    </PopoverOnOverflow>
                );
            },
        },
        {
            id: 'postLink',
            header: 'Post',
            accessorFn: (row) => row.post_url,
            cell: ({ getValue, table }) => <PostLinkCell url={getValue()} isLoading={getTableMeta(table).isLoading} />,
        },
        {
            id: 'influencer',
            header: 'Influencer',
            cell: ({ row, table }) => {
                const { isLoading, getIsInfluencerLoading } = getTableMeta(table);

                if (isLoading || getIsInfluencerLoading(row.original)) {
                    return <Skeleton />;
                }

                const username = row.original.influencer?.instagram_user_username;

                if (!username) {
                    return '-';
                }

                return (
                    <a href={buildInstagramUserUrl(username)} target="_blank" rel="noopener noreferrer">
                        @{username}
                    </a>
                );
            },
        },
        {
            id: 'influencerEmail',
            header: 'Influencer email',
            accessorFn: (row) => row.influencer?.email || '-',
            cell: ({ getValue, row, table }) => {
                const { isLoading, getIsInfluencerLoading } = getTableMeta(table);

                if (isLoading || getIsInfluencerLoading(row.original)) {
                    return <Skeleton />;
                }

                return getValue() || '-';
            },
        },
        {
            id: 'postDate',
            header: 'Post date',
            accessorFn: (row) => moment(row.posted_at).format('DD MMM YYYY'),
            cell: SkeletonTableCell,
        },
        {
            id: 'postDeadline',
            header: 'Post deadline',
            accessorFn: (row) => moment(row.post_deadline).format('DD MMM YYYY'),
            cell: ({ row, table }) => {
                const { isLoading } = getTableMeta(table);

                if (isLoading) {
                    return <Skeleton />;
                }

                const isPastDeadline = moment(row.original.posted_at).isAfter(row.original.post_deadline, 'D');

                return (
                    <div className={cn({ [styles.isPastDeadline]: isPastDeadline })}>
                        {moment(row.original.post_deadline).format('DD MMM YYYY')}
                    </div>
                );
            },
        },
        {
            id: 'paymentRequestDate',
            header: 'Payment request date',
            accessorFn: (row) => moment(row.requested_at).format('DD MMM YYYY'),
            cell: SkeletonTableCell,
        },
        {
            id: 'paymentDueDate',
            header: 'Payment due date',
            accessorFn: (row) => moment(row.payment_deadline).format('DD MMM YYYY'),
            cell: SkeletonTableCell,
        },
        {
            id: 'decisionBy',
            header: 'Decision by',
            accessorFn: (row) => row.decision_made_by || '-',
            cell: SkeletonTableCell,
        },
        {
            id: 'decision',
            header: 'Decision',
            accessorKey: 'status',
            cell: DecisionCell,
        },
    ];

    const rows: TRow[] = props.meta.isLoading && !data.length ? new Array(props.pageSize).fill(() => ({})) : data;

    return (
        <AudioPlayerProvider>
            <WrapperPaginationTable
                manualPagination
                data={rows}
                columns={columns}
                rowSpanHelper={{
                    release: (row) => row.original.release_id?.toString(),
                    brief: (row) => row.original.release_id?.toString(),
                    audio: (row) =>
                        row.original.instagram_audio_id && row.original.release_id
                            ? `${row.original.instagram_audio_id.toString()} - ${row.original.release_id.toString()}`
                            : '',
                }}
                columnPinning={{
                    right: ['decision'],
                }}
                className={styles.table}
                noDataLabel={hasError ? 'Something went wrong' : 'No posts found'}
                {...props}
            />
        </AudioPlayerProvider>
    );
};

export default ApproveInstagramPostsTable;
