export type CommonFilterProps = {
    label: string;
    icon: React.ReactNode;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onRemove: () => void;
    onClear: () => void;
    isDisabled?: boolean;
};

export type DomainFiltersProps<T> = {
    value: T;
    onChange: (values: T, shouldRefreshData?: boolean) => void;
};

/**
 * Returns the initial filters based on the current session state, active filters, and default filters.
 */
export const getInitialFilters = <T extends string[]>(state: T | null, activeFilters: T, defaultFilters?: T) => {
    if (!state) {
        return [...activeFilters, ...(defaultFilters ?? [])];
    }

    return [...activeFilters, ...state];
};
