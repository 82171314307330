import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { InfluencerPlanContext } from '../../contexts/InfluencerPlanContext';
import { isAssetsLibraryPlatform } from 'Modules/AssetsLibraryModal/AssetsLibraryModal.types';
import useUrlState from '../../../../../Hooks/useUrlState';
import { asMoney, formatDateLong, showNotification } from 'helpers';
import styles from './Creators.module.css';
import { TiktokBadgeList } from '../BadgeList/BadgeList';
import { ProtectedByUserGroups } from 'SharedComponents/ProtectedByUserGroup/ProtectedByUserGroups';
import { ReactComponent as AssetLibraryIcon } from '../../../../../SharedComponents/svg/AssetLibrary.svg';
import InstagramTable from '../InstagramTable/InstagramTable';
import TiktokTable from '../TiktokTable/TiktokTable';
import AssetsLibraryModal from '../../../../AssetsLibraryModal/containers/AssetsLibraryModal/AssetsLibraryModal';
import useAbortableEffect from '../../../../../Hooks/useAbortableEffect';
import DataCard from '../../../../../ui/DataDisplay/DataCard/DataCard';
import Button from '../../../../../ui/Buttons/Button/Button';
import RefreshCCWIcon from '../../../../../SharedComponents/svg/Icons/RefreshCCWIcon';
import Tabs, { TabNav, TabNavItem, TabPanel } from '../../../../../ui/DataDisplay/Tabs/Tabs';
import { ReactComponent as InstagramIcon } from '../../assets/Instagram.svg';
import { ReactComponent as TiktokIcon } from '../../assets/Tiktok.svg';
import { ReactComponent as YoutubeIcon } from 'assets/icons/platform/YoutubeSolid.svg';
import { ReactComponent as EditIcon } from '../../../assets/EditIcon.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/Share.svg';
import { InstagramHeadlineCalculations } from '../HeadlineCalculations/InstagramHeadlineCalculations';
import { TiktokHeadlineCalculations } from '../HeadlineCalculations/TiktokHeadlineCalculations';
import { YoutubeHeadlineStats } from '../HeadlineCalculations/YoutubeHeadlineStats';
import EmptyPlan from '../../../components/EmptyPlan/EmptyPlan';
import { Release } from '@round/api';
import CreatePlanModal from './CreatePlanModal/CreatePlanModal';
import EditPlanModal from './EditPlanModal/EditPlanModal';
import useNonNullContext from '../../../../../Hooks/useNonNullContext';
import { TiktokCreatorsContext } from '../../contexts/TiktokCreatorsContext/TiktokCreatorsContext';
import { InstagramCreatorsContext } from '../../contexts/InstagramCreatorsContext/InstagramCreatorsContext';
import { useCheckUserGroupsAccess } from 'Modules/Auth/hooks/useCheckUserGroupsAccess';
import MicroCreators from '../../../Microwave/MicroCreators/MicroCreators';
import { ReactComponent as EmailSendIcon } from '../../../../../assets/EmailSend.svg';
import useTiktokInfluencerPostGroups from '../../contexts/TiktokCreatorsContext/useTiktokInfluencerPostGroups';
import { useMicroCampaigns } from '../../../Microwave/MicroCreators/contexts/MicroCampaign';
import InstagramInfluencerPlanAudios from '../InstagramInfluencerPlanAudios/InstagramInfluencerPlanAudios';
import { ReactComponent as WarningIcon } from 'assets/icons/Warning.svg';
import YoutubeTable from '../YoutubeTable/YoutubeTable';
import { useYoutubePostGroups } from '../../contexts/YoutubeCreatorsContext/hooks/useYoutubePostGroups';
import { MicrowavePostsProvider } from 'Modules/Advertising/Microwave/MicroCreators/contexts/MIcrowavePosts';
import { useTiktokMicroCampaignStats } from 'Modules/Advertising/Microwave/MicroCreators/contexts/TiktokMicroCampaignStats';
import CreatorbaseProjectLinkDropdown from './components/CreatorbaseProjectSelect/CreatorbaseProjectLinkDropdown';

type InfluencerPlanContentUrlState = {
    showAssetLibrary?: boolean;
};

type CreatorsProps = {
    release: Release | null;
};

type Hash = 'instagram' | 'tiktok' | 'youtube' | 'micro';
const HASHES: Hash[] = ['instagram', 'tiktok', 'youtube', 'micro'];

const Creators = ({ release }: CreatorsProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const hasEditAccess = useCheckUserGroupsAccess(['influencer_editor']);
    const hasMicrowaveAccess = useCheckUserGroupsAccess(['microwave_admin']);

    const {
        init,
        initialized,
        loading,
        influencerPlan,
        plannerUsers,
        refreshRequests,
        refreshRequestsLoading,
        refreshInfluencerPlansPostsData,
    } = useContext(InfluencerPlanContext);

    const { createTiktokInfluencerPostGroup, tiktokInfluencerPosts } = useNonNullContext(TiktokCreatorsContext);
    const {
        groupsInitialized: tiktokInfluencerPostGroupsInitialized,
        init: initTiktokInfluencerPostGroups,
        groups: tiktokInfluencerPostGroups,
    } = useTiktokInfluencerPostGroups();

    const {
        postGroups: youtubeInfluencerPostGroups,
        isInitialized: isYoutubeGroupsInitialized,
        init: initYoutubeGroups,
        createYoutubeInfluencerPostGroup,
    } = useYoutubePostGroups();

    useAbortableEffect(
        (signal) => {
            if (!isYoutubeGroupsInitialized) {
                initYoutubeGroups({ signal });
            }
        },
        [isYoutubeGroupsInitialized, initYoutubeGroups]
    );

    const {
        isPrefetchCompleted: isInstagramPrefetchCompleted,
        prefetch: prefetchInstagram,
        isInitCompleted: isInstagramInitCompleted,
        instagramInfluencerPosts,
        instagramInfluencerPostGroups,
        createInstagramInfluencerPostGroup,
        instagramInfluencerPlanStats,
    } = useNonNullContext(InstagramCreatorsContext);

    const {
        campaigns: microCampaigns,
        init: initMicroCampaigns,
        isInitialized: isMicroCampaignsInitialized,
    } = useMicroCampaigns(release?.id);

    const tiktokMicroCampaign = microCampaigns.find((c) => c.platform === 'tiktok');
    const instagramMicroCampaign = microCampaigns.find((c) => c.platform === 'instagram');
    const youtubeMicroCampaign = microCampaigns.find((c) => c.platform === 'youtube');

    const shouldShowMicroTab = hasMicrowaveAccess && microCampaigns.length > 0;

    useAbortableEffect(
        (signal) => {
            if (!isMicroCampaignsInitialized) {
                initMicroCampaigns({ signal });
            }
        },
        [initMicroCampaigns, isMicroCampaignsInitialized]
    );

    const {
        microsStats,
        pendingPostCount,
        isInitialized: isMicroCampaignStatsInitialized,
        init: initMicroCampaignStats,
    } = useTiktokMicroCampaignStats(influencerPlan?.id);

    useAbortableEffect(
        (signal) => {
            if (!isMicroCampaignStatsInitialized) {
                initMicroCampaignStats({ signal });
            }
        },
        [initMicroCampaignStats, isMicroCampaignStatsInitialized]
    );

    const [showCreatePlanModal, setShowCreatePlanModal] = useState(false);
    const [showEditPlanModal, setShowEditPlanModal] = useState(false);
    const [platform, setPlatform] = useState<Hash | null>(null);
    const [{ showAssetLibrary }, setUrlState] = useUrlState<InfluencerPlanContentUrlState>();
    const [refreshLoading, setRefreshLoading] = useState(false);
    const refreshRequestedWithinAnHour = useMemo(() => {
        const lastUpdated = Math.max(...refreshRequests.map((request) => Date.parse(request.timestamp)));
        return Date.now() - lastUpdated <= 1000 * 60 * 60;
    }, [refreshRequests]);
    const noLiveInfluencerPosts =
        tiktokInfluencerPosts.filter((p) => p.status === 'live').length === 0 &&
        instagramInfluencerPosts.filter((p) => p.status === 'live').length === 0 &&
        !microsStats;
    const refreshDisabled = refreshRequestsLoading || refreshRequestedWithinAnHour || noLiveInfluencerPosts;

    const refreshTooltipText = useMemo(() => {
        if (noLiveInfluencerPosts) {
            return 'No posts to refresh';
        }

        return refreshRequestedWithinAnHour ? 'Currently refreshing. Please, check back later' : 'Refresh posts';
    }, [noLiveInfluencerPosts, refreshRequestedWithinAnHour]);

    const openAssetLibrary = useCallback(() => {
        setUrlState({ showAssetLibrary: true });
    }, [setUrlState]);

    const closeAssetLibrary = useCallback(() => {
        // URL state is string based, that's why we are assigning null to strip state from url
        setUrlState({ showAssetLibrary: undefined });
    }, [setUrlState]);

    const handleSetPlatform = useCallback(
        (pair: Hash | null) => {
            if (!pair) {
                return;
            }

            navigate(`#${pair}`);
        },
        [navigate]
    );

    const onAddNewGroup = useCallback(() => {
        if (platform === 'instagram') {
            createInstagramInfluencerPostGroup();
        }

        if (platform === 'tiktok') {
            createTiktokInfluencerPostGroup();
        }
    }, [platform, createInstagramInfluencerPostGroup, createTiktokInfluencerPostGroup]);

    const handleCreateYoutubePostGroup = useCallback(async () => {
        if (!influencerPlan) {
            return;
        }

        try {
            const response = await createYoutubeInfluencerPostGroup({
                influencer_plan: influencerPlan?.id,
                name: `Influencer Group ${(youtubeInfluencerPostGroups?.length ?? 0) + 1}`,
                ordering_index: youtubeInfluencerPostGroups?.length ?? 0,
                budget: '1.00',
                is_hidden: true,
            });

            if (response.status === 201) {
                showNotification('Group created', 'info');
                return;
            }
            showNotification('Could not create group', 'error');
        } catch {
            showNotification('Could not create group', 'error');
        }
    }, [createYoutubeInfluencerPostGroup, influencerPlan, youtubeInfluencerPostGroups]);

    const onRefreshInfluencerPlansPosts = useCallback(async () => {
        try {
            setRefreshLoading(true);
            const numberOfPostsToRefresh = await refreshInfluencerPlansPostsData();
            showNotification(
                `Data retrieval initiated for ${numberOfPostsToRefresh} post${
                    numberOfPostsToRefresh > 1 ? 's' : ''
                }. Please, check back later`,
                'info'
            );
        } catch {
            showNotification('Could not refresh posts', 'error');
        } finally {
            setRefreshLoading(false);
        }
    }, [refreshInfluencerPlansPostsData]);

    useEffect(() => {
        const shouldNotAutoSelectPlatform =
            platform ||
            location.hash ||
            !tiktokInfluencerPostGroupsInitialized ||
            !isInstagramPrefetchCompleted ||
            !isYoutubeGroupsInitialized ||
            !isMicroCampaignsInitialized;

        if (shouldNotAutoSelectPlatform) {
            return;
        }

        const noInstagramGroups = !instagramMicroCampaign && !instagramInfluencerPostGroups.length;
        if ((tiktokMicroCampaign || tiktokInfluencerPostGroups.length) && noInstagramGroups) {
            return handleSetPlatform('tiktok');
        }

        const noTiktokGroups = !tiktokMicroCampaign && !tiktokInfluencerPostGroups.length;
        if ((!!youtubeMicroCampaign || youtubeInfluencerPostGroups?.length) && noTiktokGroups && noInstagramGroups) {
            return handleSetPlatform('youtube');
        }

        return handleSetPlatform('instagram');
    }, [
        platform,
        handleSetPlatform,
        location.hash,
        tiktokInfluencerPostGroupsInitialized,
        isInstagramPrefetchCompleted,
        tiktokInfluencerPostGroups.length,
        instagramInfluencerPostGroups.length,
        isMicroCampaignsInitialized,
        tiktokMicroCampaign,
        instagramMicroCampaign,
        isYoutubeGroupsInitialized,
        youtubeInfluencerPostGroups?.length,
        youtubeMicroCampaign,
    ]);

    useEffect(() => {
        const hash = location.hash.substr(1) as Hash;
        if (HASHES.includes(hash)) {
            setPlatform(hash);
        }
    }, [location.hash]);

    useAbortableEffect(
        (signal) => {
            if (!initialized) {
                init({ signal });
            }
        },
        [initialized, init]
    );

    useEffect(() => {
        if (!tiktokInfluencerPostGroupsInitialized) {
            initTiktokInfluencerPostGroups();
        }
    }, [initTiktokInfluencerPostGroups, tiktokInfluencerPostGroupsInitialized]);

    useEffect(() => {
        if (!isInstagramPrefetchCompleted) {
            prefetchInstagram();
        }
    }, [isInstagramPrefetchCompleted, prefetchInstagram]);

    const runDates = influencerPlan
        ? `${formatDateLong(influencerPlan.start_date)} - ${formatDateLong(influencerPlan.end_date)}`
        : '-';

    const hasCurrencyMismatch =
        (tiktokMicroCampaign && influencerPlan?.currency.id !== tiktokMicroCampaign?.currency_id) ||
        (instagramMicroCampaign && influencerPlan?.currency.id !== instagramMicroCampaign?.currency_id) ||
        (youtubeMicroCampaign && influencerPlan?.currency.id !== youtubeMicroCampaign.currency_id);

    const totalBudget = (() => {
        if (!influencerPlan) {
            return '-';
        }

        let budget =
            Number(influencerPlan.instagram_budget) +
            Number(influencerPlan.tiktok_budget) +
            Number(influencerPlan.youtube_budget) +
            (tiktokMicroCampaign?.budget ?? 0) +
            (instagramMicroCampaign?.budget ?? 0) +
            (youtubeMicroCampaign?.budget ?? 0);

        return asMoney(budget, influencerPlan.currency);
    })();

    const amountSpent = (() => {
        const groups = [
            ...instagramInfluencerPostGroups,
            ...tiktokInfluencerPostGroups,
            ...(youtubeInfluencerPostGroups ?? []),
        ];

        return (
            groups.reduce((budget, group) => budget + Number(group.budget), 0) +
            (tiktokMicroCampaign?.budget ?? 0) +
            (instagramMicroCampaign?.budget ?? 0) +
            (youtubeMicroCampaign?.budget ?? 0)
        );
    })();

    const amountSpentText = influencerPlan ? `${asMoney(amountSpent, influencerPlan?.currency)} planned cost` : '';

    const accountTeam = useMemo(
        () =>
            plannerUsers.length ? plannerUsers.map((user) => `${user.first_name} ${user.last_name}`).join(', ') : '-',
        [plannerUsers]
    );

    if (!loading && !influencerPlan) {
        return (
            <>
                <EmptyPlan
                    release={release}
                    onCreatePlan={() => setShowCreatePlanModal(true)}
                    readOnly={!hasEditAccess}
                />
                {release && (
                    <CreatePlanModal
                        isModalOpen={showCreatePlanModal}
                        onClose={() => setShowCreatePlanModal(false)}
                        onCreated={() => init()}
                        release={release}
                    />
                )}
            </>
        );
    }

    const isDataLoading =
        !initialized ||
        !tiktokInfluencerPostGroupsInitialized ||
        !isInstagramPrefetchCompleted ||
        !isYoutubeGroupsInitialized;

    return (
        <>
            <ProtectedByUserGroups groups={['influencer_editor']}>
                <EditPlanModal isModalOpen={showEditPlanModal} onClose={() => setShowEditPlanModal(false)} />
            </ProtectedByUserGroups>

            <div className={styles.content}>
                <div className={styles.topRow}>
                    <div className={cn(styles.row, styles.dataCards)}>
                        <DataCard
                            loading={isDataLoading}
                            title="Run Dates"
                            value={runDates}
                            tooltipValue={runDates}
                            valuesClassName={styles.dataCardValue}
                        />
                        <DataCard
                            loading={isDataLoading}
                            title="Total Budget"
                            value={totalBudget}
                            additionalValue={amountSpentText}
                            valuesClassName={styles.dataCardValue}
                            hint={
                                hasCurrencyMismatch ? (
                                    <div>
                                        {instagramMicroCampaign &&
                                            influencerPlan?.currency.id !== instagramMicroCampaign?.currency_id && (
                                                <>
                                                    <span>Instagram Micro.wav campaign has different currency</span>
                                                    <br />
                                                </>
                                            )}
                                        {tiktokMicroCampaign &&
                                            influencerPlan?.currency.id !== tiktokMicroCampaign?.currency_id && (
                                                <span>TikTok Micro.wav campaign has different currency</span>
                                            )}
                                        {youtubeMicroCampaign &&
                                            youtubeMicroCampaign.currency_id !== influencerPlan?.currency.id && (
                                                <span>Youtube Micro.wav campaign has different currency</span>
                                            )}
                                    </div>
                                ) : undefined
                            }
                            HintIcon={hasCurrencyMismatch ? WarningIcon : undefined}
                            hintClassName={styles.currencyMismatchWarning}
                        />
                        <ProtectedByUserGroups groups={['user_admin']}>
                            <DataCard
                                loading={isDataLoading}
                                title="Account Team"
                                value={accountTeam}
                                tooltipValue={accountTeam}
                                valuesClassName={styles.dataCardValue}
                            />
                        </ProtectedByUserGroups>
                        {platform === 'micro' ? (
                            <DataCard
                                loading={!isMicroCampaignStatsInitialized || !isMicroCampaignsInitialized}
                                title="Micro.wav Posts"
                                value={
                                    !tiktokMicroCampaign && !microsStats
                                        ? '-'
                                        : `${microsStats?.post_count ?? 0}/${tiktokMicroCampaign?.target_post_count}`
                                }
                                valuesClassName={styles.dataCardValue}
                                additionalValue={pendingPostCount ? `${pendingPostCount} unapproved` : undefined}
                            />
                        ) : null}
                    </div>

                    <div className={cn(styles.row, styles.actionButtons)}>
                        {platform !== 'micro' && (
                            <ProtectedByUserGroups groups={['creatorbase_round_planner']}>
                                <CreatorbaseProjectLinkDropdown>
                                    {(targetProps) => (
                                        <Button {...targetProps} type="bordered" className={styles.actionButton}>
                                            <LinkIcon className={styles.actionButtonIcon} />
                                            Link to Creatorbase
                                        </Button>
                                    )}
                                </CreatorbaseProjectLinkDropdown>
                            </ProtectedByUserGroups>
                        )}

                        <ProtectedByUserGroups groups={['influencer_editor']}>
                            <Button
                                type="bordered"
                                className={styles.actionButton}
                                onClick={() => setShowEditPlanModal(true)}
                            >
                                <EditIcon className={styles.actionButtonIcon} />
                                Edit Plan
                            </Button>

                            <Button
                                type="bordered"
                                hint={refreshTooltipText}
                                className={styles.actionButton}
                                disabled={refreshDisabled}
                                loading={refreshLoading}
                                onClick={onRefreshInfluencerPlansPosts}
                            >
                                <RefreshCCWIcon className={styles.refreshDataButtonIcon} width={12} height={12} />
                                Refresh Data
                            </Button>
                        </ProtectedByUserGroups>
                    </div>
                </div>

                <Tabs activeTab={platform} onChange={handleSetPlatform}>
                    <div className={styles.navigationsRow}>
                        <div className={styles.tabs}>
                            <TabNav>
                                <TabNavItem className={styles.tabNavItem} name="instagram">
                                    <InstagramIcon className={styles.tabIcon} /> Instagram
                                </TabNavItem>

                                <TabNavItem className={styles.tabNavItem} name="tiktok">
                                    <TiktokIcon className={styles.tabIcon} /> Tiktok
                                </TabNavItem>

                                <TabNavItem className={styles.tabNavItem} name="youtube">
                                    <YoutubeIcon className={styles.tabIcon} /> YouTube
                                </TabNavItem>

                                {shouldShowMicroTab ? (
                                    <TabNavItem className={styles.tabNavItem} name="micro">
                                        <EmailSendIcon className={styles.tabIcon} />
                                        Micro
                                    </TabNavItem>
                                ) : null}
                            </TabNav>
                        </div>

                        <div
                            className={cn(styles.navigationsRowBadges, {
                                [styles.visible]: platform === 'tiktok' || platform === 'instagram',
                            })}
                        >
                            {platform === 'tiktok' && <TiktokBadgeList />}
                            {platform === 'instagram' && <InstagramInfluencerPlanAudios />}
                        </div>
                    </div>

                    <TabPanel name="instagram">
                        <InstagramHeadlineCalculations
                            loading={!isInstagramInitCompleted}
                            currency={influencerPlan?.currency}
                            planStats={instagramInfluencerPlanStats}
                        />

                        <div className={styles.toolbar}>
                            <ProtectedByUserGroups groups={['influencer_editor']}>
                                <Button disabled={isDataLoading} type="filled" color="black" onClick={onAddNewGroup}>
                                    + Add group
                                </Button>
                            </ProtectedByUserGroups>

                            <Button
                                disabled={!initialized}
                                type="bordered"
                                className={styles.actionButton}
                                onClick={openAssetLibrary}
                            >
                                <AssetLibraryIcon className={styles.actionButtonIcon} />
                                Asset Library
                            </Button>
                        </div>

                        <InstagramTable />
                    </TabPanel>

                    <TabPanel name="tiktok">
                        <TiktokHeadlineCalculations />

                        <div className={styles.toolbar}>
                            <ProtectedByUserGroups groups={['influencer_editor']}>
                                <Button disabled={isDataLoading} type="filled" color="black" onClick={onAddNewGroup}>
                                    + Add group
                                </Button>
                            </ProtectedByUserGroups>

                            <Button
                                disabled={!initialized}
                                type="bordered"
                                className={styles.actionButton}
                                onClick={openAssetLibrary}
                            >
                                <AssetLibraryIcon className={styles.actionButtonIcon} />
                                Asset Library
                            </Button>
                        </div>

                        <TiktokTable />
                    </TabPanel>

                    <MicrowavePostsProvider>
                        <TabPanel name="youtube">
                            <YoutubeHeadlineStats />

                            <div className={styles.toolbar}>
                                <ProtectedByUserGroups groups={['influencer_editor']}>
                                    <Button
                                        disabled={isDataLoading}
                                        type="filled"
                                        color="black"
                                        onClick={handleCreateYoutubePostGroup}
                                    >
                                        + Add group
                                    </Button>
                                </ProtectedByUserGroups>
                            </div>

                            <YoutubeTable />
                        </TabPanel>
                    </MicrowavePostsProvider>

                    <TabPanel name="micro">
                        <MicroCreators />
                    </TabPanel>
                </Tabs>

                {platform && isAssetsLibraryPlatform(platform) && influencerPlan && showAssetLibrary && (
                    <AssetsLibraryModal platform={platform} planId={influencerPlan.id} closeModal={closeAssetLibrary} />
                )}
            </div>
        </>
    );
};

export default Creators;
