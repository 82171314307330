import useCampaigns from '../useCampaigns';
import { useMemo } from 'react';
import { useInstagramCampaignStats } from './instagramCampaignStats';
import { isNumber } from '../../../../../../utility/utility';
import uniq from 'lodash/uniq';
import useTeam from '../../hooks/useTeam';
import useInstagramAudios from '../../hooks/useInstagramAudios';
import { InstagramAudio, microwave, users } from '@round/api';
import pick from 'lodash/pick';

type HookConfig = {
    releaseId?: number | undefined;
    isImperative?: boolean;
};

export default function useInstagramCampaigns(config: HookConfig = {}) {
    const campaignsState = useCampaigns(config);
    const campaigns = useMemo(() => campaignsState.data?.filter((c) => c.platform === 'instagram') ?? [], [
        campaignsState.data,
    ]);

    const { stats } = useInstagramCampaignStats(
        useMemo(() => campaigns.map((c) => c.instagram_campaign_id).filter(isNumber) ?? [], [campaigns])
    );

    const teamUserIds = useMemo(() => uniq(campaigns.flatMap((c) => c.team_members ?? [])), [campaigns]);
    const { data: teams } = useTeam({ userIds: teamUserIds });

    const audioIds = useMemo(() => uniq(campaigns.map((c) => c.instagram_audio_id).filter(isNumber)), [campaigns]);
    const { data: audios } = useInstagramAudios({ ids: audioIds });

    return {
        ...campaignsState,
        campaigns,
        stats,
        teams: Object.values(teams)
            .map((state) => state?.data)
            .filter((user): user is users.UserPlanner => !!user),
        isTeamLoading: (campaign: microwave.Campaign) => {
            const accountTeamState = pick(teams, campaign.team_members ?? []);
            return Object.values(accountTeamState).some(
                (state) => state?.status === 'loading' || state?.status === 'idle'
            );
        },
        audios: Object.values(audios)
            .map((state) => state?.data)
            .filter((audio): audio is InstagramAudio => !!audio && audioIds.includes(audio.id)),
    };
}
