import { FieldHookConfig, useField, useFormikContext } from 'formik';
import cn from 'classnames';
import styles from 'ui-new/formik/FormField.module.css';
import Select from 'ui-new/Select/Select';
import { StylesConfig } from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import ErrorMessage from './ErrorMessage';
import { useCurrencies } from 'contexts/OptionsContext/useCurrencies';

type Props = {
    currencyFieldName: string;
    label: string;
    className?: string;
    isCurrencyDisabled?: boolean;
} & FieldHookConfig<number>;

const selectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        border: 'none',
        boxShadow: 'none',
        outline: 'none',
        width: '50px',
        padding: 0,
        ':hover': {
            border: 'none',
            boxShadow: 'none',
            outline: 'none',
        },
    }),
    menu: (base) => ({
        ...base,
        minWidth: '7.5rem',
    }),
};

const MoneyField = ({
    currencyFieldName,
    label,
    className,
    disabled,
    placeholder,
    isCurrencyDisabled,
    ...props
}: Props) => {
    const [{ onChange, ...field }, meta, helpers] = useField(props);
    const formik = useFormikContext<Record<string, any>>();
    const { options: currencyOptions } = useCurrencies();

    const hasError = meta.error;
    return (
        <div className={cn(styles.field, className, { [styles.disabled]: disabled })}>
            <label className={styles.label} htmlFor={props.name}>
                {label}
            </label>

            <div
                className={cn(styles.input, styles.moneyInput, {
                    [styles.error]: hasError,
                    [styles.disabled]: disabled,
                })}
            >
                <Select
                    placeholder="..."
                    isSearchable={false}
                    isClearable={false}
                    isDisabled={disabled || isCurrencyDisabled}
                    styles={selectStyles}
                    value={formik.values[currencyFieldName]}
                    onChange={(value) => {
                        formik.setFieldValue(currencyFieldName, value);
                    }}
                    options={currencyOptions}
                />
                <CurrencyInput
                    {...field}
                    disabled={disabled}
                    onValueChange={(value) => {
                        helpers.setTouched(true);
                        formik.setFieldValue(props.name, value);
                    }}
                    placeholder={placeholder}
                    groupSeparator=","
                    decimalSeparator="."
                    allowDecimals
                    decimalsLimit={4}
                    className={styles.inputField}
                />
            </div>

            <ErrorMessage name={props.name} />
        </div>
    );
};

export default MoneyField;
