import { CommonFilterProps } from '../helpers';
import FilterBadge from 'ui/Filters/FilterBadge/FilterBadge';
import Checkbox from 'ui/DataEntry/Checkbox/Checkbox';
import styles from './CheckboxFilter.module.css';

type Props = Pick<CommonFilterProps, 'label' | 'isDisabled'> & {
    value: boolean | undefined;
    onChange: (value: boolean | undefined) => void;
};

const CheckboxFilter = ({ label, value, onChange, isDisabled }: Props) => {
    return (
        <FilterBadge isDisabled={isDisabled} onClick={() => onChange(!value)}>
            <Checkbox className={styles.checkbox} value={Boolean(value)} disabled={isDisabled} />
            {label}
        </FilterBadge>
    );
};

export default CheckboxFilter;
