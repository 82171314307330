import { createReducer } from '../../../../../helpers';
import { InstagramUserImage, ReducerAction, ReducerActionWithPayload } from '../../../../../App.types';
import { InstagramInfluencerPost, InstagramInfluencerPostResult } from '../../types/Instagram.types';
import { InstagramUserStats } from 'Modules/Instagram/Instagram.types';
import { uniqBy } from 'lodash';
import {
    InstagramInfluencerUser,
    InfluencerPostGroup,
    InstagramInfluencerPostGroupStats,
    InstagramInfluencerPlanStats,
    InstagramAudio,
    InstagramPost,
} from '@round/api';

export const initialState: InstagramCreatorsContextState = {
    isPrefetchCompleted: false,
    isInitCompleted: false,
    instagramInfluencerPostGroups: [],
    instagramInfluencerPosts: [],
    instagramInfluencerPostResults: [],
    instagramPosts: [],
    instagramUserImages: [],
    instagramUserStats: [],
    instagramInfluencerUsers: [],
    instagramInfluencerPostGroupStats: [],
    instagramInfluencerPlanStats: undefined,
    instagramInfluencerPlanAudios: [],
    instagramInfluencerPlanAudiosError: null,
    areInstagramInfluencerPlanAudiosInitialized: false,
    areInstagramInfluencerPlanAudiosLoading: false,
};

export type InstagramInfluencerPostGroupStatsWithId = InstagramInfluencerPostGroupStats & { group_id: number };

export type InstagramCreatorsContextState = {
    isPrefetchCompleted: boolean;
    isInitCompleted: boolean;
    instagramInfluencerPostGroups: InfluencerPostGroup[];
    instagramInfluencerPosts: InstagramInfluencerPost[];
    instagramInfluencerPostResults: InstagramInfluencerPostResult[];
    instagramUserStats: InstagramUserStats[];
    instagramUserImages: InstagramUserImage[];
    instagramPosts: InstagramPost[];
    instagramInfluencerUsers: InstagramInfluencerUser[];
    instagramInfluencerPostGroupStats: InstagramInfluencerPostGroupStatsWithId[];
    instagramInfluencerPlanStats: InstagramInfluencerPlanStats | undefined;
    instagramInfluencerPlanAudios: InstagramAudio[];
    areInstagramInfluencerPlanAudiosLoading: boolean;
    areInstagramInfluencerPlanAudiosInitialized: boolean;
    instagramInfluencerPlanAudiosError: string | null;
};

export type Actions =
    | ReducerActionWithPayload<
          'prefetchCompleted',
          Pick<InstagramCreatorsContextState, 'instagramInfluencerPostGroups'>
      >
    | ReducerActionWithPayload<
          'initCompleted',
          Pick<
              InstagramCreatorsContextState,
              | 'instagramUserStats'
              | 'instagramUserImages'
              | 'instagramPosts'
              | 'instagramInfluencerUsers'
              | 'instagramInfluencerPostResults'
              | 'instagramInfluencerPosts'
              | 'instagramInfluencerPostGroupStats'
              | 'instagramInfluencerPlanStats'
          >
      >
    | ReducerActionWithPayload<'instagramInfluencerUsersFetched', InstagramInfluencerUser[]>
    | ReducerActionWithPayload<'influencerPostGroupCreated', InfluencerPostGroup>
    | ReducerActionWithPayload<'influencerPostGroupDeleted', number>
    | ReducerActionWithPayload<'influencerPostGroupUpdated', InfluencerPostGroup>
    | ReducerActionWithPayload<'influencerPostGroupsBatchUpdated', InfluencerPostGroup[]>
    | ReducerActionWithPayload<'instagramInfluencerPostCreated', InstagramInfluencerPost>
    | ReducerActionWithPayload<'instagramInfluencerPostUpdated', InstagramInfluencerPost>
    | ReducerActionWithPayload<'setInstagramInfluencerPostResult', InstagramInfluencerPostResult>
    | ReducerActionWithPayload<'instagramInfluencerPostResultDeleted', number>
    | ReducerActionWithPayload<'instagramInfluencerPostDeleted', number>
    | ReducerActionWithPayload<'instagramInfluencerUserUpdated', InstagramInfluencerUser>
    | ReducerAction<'loadInstagramInfluencerPlanAudios'>
    | ReducerActionWithPayload<'instagramInfluencerPlanAudiosInitialized', InstagramAudio[]>
    | ReducerActionWithPayload<'setInstagramInfluencerPlanAudiosError', string | null>;

export default createReducer<InstagramCreatorsContextState, Actions>({
    prefetchCompleted: (state, { payload }) => ({
        ...state,
        isPrefetchCompleted: true,
        ...payload,
    }),
    initCompleted: (state, { payload }) => ({
        ...state,
        isInitCompleted: true,
        ...payload,
    }),
    influencerPostGroupCreated: (state, { payload }) => ({
        ...state,
        instagramInfluencerPostGroups: state.instagramInfluencerPostGroups.concat(payload),
    }),
    influencerPostGroupDeleted: (state, { payload }) => {
        const groupPosts = state.instagramInfluencerPosts.filter((p) => p.group_id === payload).map((p) => p.id);
        return {
            ...state,
            instagramInfluencerPostGroups: state.instagramInfluencerPostGroups.filter((group) => group.id !== payload),
            instagramInfluencerPosts: state.instagramInfluencerPosts.filter((post) => post.group_id !== payload),
            instagramInfluencerPostResults: state.instagramInfluencerPostResults.filter(
                (res) => !groupPosts.includes(res.post)
            ),
        };
    },
    influencerPostGroupUpdated: (state, { payload }) => ({
        ...state,
        instagramInfluencerPostGroups: state.instagramInfluencerPostGroups.map((group) =>
            group.id === payload.id ? payload : group
        ),
    }),
    influencerPostGroupsBatchUpdated: (state, { payload }) => ({
        ...state,
        instagramInfluencerPostGroups: state.instagramInfluencerPostGroups.map(
            (group) => payload.find((g) => g.id === group.id) ?? group
        ),
    }),
    instagramInfluencerUsersFetched: (state, { payload }) => ({
        ...state,
        instagramInfluencerUsers: uniqBy(state.instagramInfluencerUsers.concat(payload), 'id'),
    }),
    instagramInfluencerPostCreated: (state, { payload }) => ({
        ...state,
        instagramInfluencerPosts: state.instagramInfluencerPosts.concat(payload),
    }),
    instagramInfluencerPostUpdated: (state, { payload }) => ({
        ...state,
        instagramInfluencerPosts: state.instagramInfluencerPosts.map((p) => (p.id === payload.id ? payload : p)),
    }),
    setInstagramInfluencerPostResult: (state, { payload }) => {
        if (!state.instagramInfluencerPostResults.find((r) => r.id === payload.id)) {
            return {
                ...state,
                instagramInfluencerPostResults: state.instagramInfluencerPostResults.concat(payload),
            };
        }

        return {
            ...state,
            instagramInfluencerPostResults: state.instagramInfluencerPostResults.map((result) =>
                result.id === payload.id ? payload : result
            ),
        };
    },
    instagramInfluencerPostResultDeleted: (state, { payload }) => {
        return {
            ...state,
            instagramInfluencerPostResults: state.instagramInfluencerPostResults.filter(
                (result) => result.id !== payload
            ),
        };
    },
    instagramInfluencerPostDeleted: (state, { payload }) => ({
        ...state,
        instagramInfluencerPostResults: state.instagramInfluencerPostResults.filter((res) => res.post !== payload),
        instagramInfluencerPosts: state.instagramInfluencerPosts.filter((post) => post.id !== payload),
    }),
    instagramInfluencerUserUpdated: (state, { payload }) => ({
        ...state,
        instagramInfluencerUsers: state.instagramInfluencerUsers.map((influencer) =>
            influencer.id === payload.id ? payload : influencer
        ),
    }),
    loadInstagramInfluencerPlanAudios: (state) => ({
        ...state,
        instagramInfluencerPlanAudios: [],
        areInstagramInfluencerPlanAudiosLoading: true,
        areInstagramInfluencerPlanAudiosInitialized: false,
        instagramInfluencerPlanAudiosError: null,
    }),
    instagramInfluencerPlanAudiosInitialized: (state, { payload }) => ({
        ...state,
        instagramInfluencerPlanAudios: payload,
        instagramInfluencerPlanAudiosError: null,
        areInstagramInfluencerPlanAudiosInitialized: true,
        areInstagramInfluencerPlanAudiosLoading: false,
    }),
    setInstagramInfluencerPlanAudiosError: (state, { payload }) => ({
        ...state,
        instagramInfluencerPlanAudios: [],
        areInstagramInfluencerPlanAudiosLoading: false,
        areInstagramInfluencerPlanAudiosInitialized: true,
        instagramInfluencerPlanAudiosError: payload,
    }),
});
