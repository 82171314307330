import React from 'react';
import styles from './PostLinkCell.module.css';
import { Skeleton } from '@round/ui-kit';

type Props = {
    url: string;
    isLoading?: boolean;
};

const PostLinkCell = ({ url, isLoading }: Props) => {
    if (isLoading) {
        return <Skeleton />;
    }

    if (!url) {
        return <>-</>;
    }

    return (
        <a href={url} target="_blank" rel="noreferrer" className={styles.postLink}>
            Post link ↗
        </a>
    );
};

export default PostLinkCell;
