import { CommonFilterProps } from '../helpers';
import Dropdown from '../../Dropdown/Dropdown';
import FilterBadgeClearable from 'ui/Filters/FilterBadge/FilterBadgeClearable/FilterBadgeClearable';
import FilterHeader from 'ui/Filters/FilterHeader/FilterHeader';
import { makeLocationOption, useInfluencerLocationSelect } from 'Hooks/selectOptions/useInfluencerLocationSelect';
import Select from 'ui-new/Select/Select';
import { StylesConfig } from 'react-select';
import styles from './LocationFilter.module.css';
import { influencerQueries } from '@round/api';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Spinner } from '@round/ui-kit';

const locationQueries = influencerQueries.location;

type Props = CommonFilterProps & {
    initialValueId: number | null | undefined;
    onChange: (valueId: number | undefined) => void;
};

const selectStyles: StylesConfig = {
    control: (base) => ({
        ...base,
        minWidth: '16.25rem',
    }),
};

const LocationFilter = ({
    isOpen,
    onOpen,
    onClose,
    onRemove,
    label,
    onClear,
    icon,
    onChange,
    isDisabled,
    initialValueId,
}: Props) => {
    const {
        props: { onChange: onCountryChange, ...countrySelectProps },
        resetValue: resetCountryValue,
    } = useInfluencerLocationSelect({
        type: 'country',
        initOn: 'menuOpen',
    });

    const {
        props: { onChange: onCityChange, ...citySelectProps },
        resetOptions: resetCityOptions,
        resetValue: resetCityValue,
    } = useInfluencerLocationSelect({
        type: 'city',
        initOn: 'menuOpen',
        country_code: countrySelectProps.value?.countryCode,
    });

    const { data: primaryLocation, status: primaryLocationStatus, fetchStatus: primaryLocationFetchStatus } = useQuery({
        ...locationQueries.options.detail(initialValueId),
        enabled: typeof initialValueId == 'number' && !countrySelectProps.value && !citySelectProps.value,
    });

    const { data: secondaryLocation } = useQuery({
        ...locationQueries.options.list({ country_code: primaryLocation?.country_code }),
        enabled: primaryLocation?.type === 'CITY' && isOpen && !countrySelectProps.value,
        select: (response) => response.results[0],
    });

    useEffect(() => {
        if (!primaryLocation) {
            return;
        }

        if (primaryLocation.type === 'COUNTRY') {
            onCountryChange?.(makeLocationOption(primaryLocation), { action: 'set-value' });
            return;
        }

        onCityChange?.(makeLocationOption(primaryLocation), { action: 'set-value' });
        if (secondaryLocation) {
            onCountryChange?.(makeLocationOption(secondaryLocation), { action: 'set-value' });
        }
    }, [onCityChange, onCountryChange, primaryLocation, secondaryLocation]);

    const renderedValue = citySelectProps.value?.label ?? countrySelectProps.value?.label ?? '-';

    const clearValues = () => {
        resetCountryValue();
        resetCityValue();
    };

    const isValueLoading = primaryLocationStatus === 'pending' && primaryLocationFetchStatus === 'fetching';
    return (
        <Dropdown
            isOpen={isOpen}
            onClose={onClose}
            control={
                <FilterBadgeClearable
                    isDisabled={isDisabled}
                    onClick={onOpen}
                    onClear={() => {
                        clearValues();
                        onRemove();
                    }}
                >
                    {label}:&nbsp;
                    {isValueLoading ? (
                        <Spinner containerClassName={styles.spinnerContainer} spinnerClassName={styles.spinner} />
                    ) : (
                        renderedValue
                    )}
                </FilterBadgeClearable>
            }
        >
            <FilterHeader
                name={label}
                icon={icon}
                hasValue={Boolean(citySelectProps.value || countrySelectProps.value)}
                onReset={() => {
                    clearValues();
                    onClear();
                }}
            />

            <div className={styles.selects}>
                <label className={styles.locationTypeLabel}>Country</label>
                <Select
                    styles={selectStyles}
                    menuPortalTarget={null}
                    {...countrySelectProps}
                    onChange={(value, action) => {
                        onChange(value?.value);
                        onCountryChange?.(value, action);
                        resetCityOptions();
                        resetCityValue();
                    }}
                />

                <label className={styles.locationTypeLabel}>City</label>
                <Select
                    styles={selectStyles}
                    menuPortalTarget={null}
                    isDisabled={!countrySelectProps.value}
                    {...citySelectProps}
                    onChange={(value, action) => {
                        onChange(value?.value);
                        onCityChange?.(value, action);
                    }}
                />
            </div>
        </Dropdown>
    );
};

export default LocationFilter;
