import { microwave } from '@round/api';
import { OptionsParams, useSelect, UseSingleValueSelectParams } from 'Hooks/useSelect';
import { useMemo } from 'react';
import { fetchPlatformImageData, getInfluencerImageUrls } from '../helpers';
import { MakeRequired } from 'utility/utility.types';

export type MicrowaveInfluencerOption = {
    value: number;
    label: string;
    emailAddress: string;
    tiktokImageUrl?: string;
    instagramImageUrl?: string;
    youtubeImageUrl?: string;
    tiktokUniqueId: string;
    tiktokNickname?: string;
    instagramUniqueId?: string | null;
    youtubeChannelTitle: string | null;
};

const mapInfluencerToOption = (
    inf: microwave.MicrowaveInfluencer,
    imageUrls: {
        tiktok: string | undefined;
        instagram: string | undefined;
        youtube: string | undefined;
    }
): MicrowaveInfluencerOption => ({
    value: inf.id,
    label: inf.email,
    emailAddress: inf.email,
    tiktokNickname: inf.tiktok_user_nickname,
    tiktokUniqueId: inf.tiktok_user_unique_id,
    instagramUniqueId: inf.instagram_user_username,
    tiktokImageUrl: imageUrls.tiktok,
    instagramImageUrl: imageUrls.instagram,
    youtubeImageUrl: imageUrls.youtube,
    youtubeChannelTitle: inf.youtube_channel_title,
});

export const buildFetchOptions = (
    influencerParams: Omit<microwave.GetMicrowaveInfluencersParams, 'page' | 'page_size' | 'search'>
) => (params: OptionsParams, requestInit?: RequestInit) =>
    fetchOptions({ ...influencerParams, ...params }, requestInit);

const fetchOptions = async (
    params: MakeRequired<microwave.GetMicrowaveInfluencersParams, 'page' | 'page_size'>,
    requestInit?: RequestInit
) => {
    const response = await microwave.getMicrowaveInfluencers(params, requestInit);

    const [tiktokUserImages, instagramUserImages, youtubeChannels] = await fetchPlatformImageData(
        response.data.results,
        requestInit
    );

    return {
        ...response.data,
        results: response.data.results.map((inf) => {
            const platformImageData = getInfluencerImageUrls(inf, {
                tiktok: tiktokUserImages,
                instagram: instagramUserImages,
                youtube: youtubeChannels,
            });

            return mapInfluencerToOption(inf, platformImageData);
        }),
    };
};

const fetchInitialValue = async (id: number, requestInit?: RequestInit) => {
    const response = await microwave.getInfluencer(id, requestInit);

    if (response.status === 404) {
        throw new Error('Could not find initial influencer');
    }

    const [tiktokImages, instagramImages, youtubeChannels] = await fetchPlatformImageData([response.data], requestInit);

    return mapInfluencerToOption(
        response.data,
        getInfluencerImageUrls(response.data, {
            tiktok: tiktokImages,
            instagram: instagramImages,
            youtube: youtubeChannels,
        })
    );
};

type Params = Omit<UseSingleValueSelectParams<MicrowaveInfluencerOption>, 'isMulti' | 'fetchOptions'> & {
    fetchOptions?: typeof fetchOptions;
};

export default function useMicrowaveInfluencerSelect(params: Params) {
    const defaultFetchOptions = useMemo(() => buildFetchOptions({}), []);
    const fetchOptions = params.fetchOptions || defaultFetchOptions;

    return useSelect({ ...params, isMulti: false, fetchOptions, fetchInitialValue });
}
