import React, { createContext, useCallback, useEffect } from 'react';
import { InfluencerPlan } from '../../../../App.types';
import { InfluencerPlanPostsRefreshRequest } from '../InfluencerPlan.types';
import useSafeReducer from '../../../../Hooks/useSafeReducer';
import {
    getInfluencerPlanPostsRefreshRequests,
    refreshInfluencerPlanPosts,
    getAllInfluencerPlans,
} from '../InfluencerPlan.api';
import { InstagramInfluencerPost } from '../types/Instagram.types';
import { fetchCreateInfluencerPlan, fetchDeleteInfluencerPlan, patchInfluencerPlan } from '../api/InfluencerPlan.api';
import { InfluencerPlanDto } from '../types/InfluencerPlan.types';
import { FCWithChildren } from '../../../../utility/utility.types';
import { getPlannerUsers, UserPlanner, InstagramInfluencerUser } from '@round/api';

type LoadedInfluencerPlan = {
    type: 'loadedInfluencerPlan';
    influencerPlan: InfluencerPlan;
};

type LoadedRefreshRequests = {
    type: 'loadedRefreshRequests';
    payload: InfluencerPlanPostsRefreshRequest[];
};

type SetRefreshRequestsLoading = {
    type: 'setRefreshRequestsLoading';
    payload: boolean;
};

type ResetAction = {
    type: 'reset';
};

type SetInitialized = {
    type: 'setInitialized';
    payload: boolean;
};

type LoadedPlannerUsers = {
    type: 'loadedPlannerUsers';
    payload: UserPlanner[];
};

type Action =
    | LoadedInfluencerPlan
    | LoadedRefreshRequests
    | SetRefreshRequestsLoading
    | ResetAction
    | SetInitialized
    | LoadedPlannerUsers;

type InfluencerPlanContextUtils = {
    fetchInfluencerPlan: (releaseId: number) => Promise<InfluencerPlan | void>;
    refreshInfluencerPlansPostsData: () => Promise<number>;
    refreshPlannerUsers: (userIds: number[], requestInit?: RequestInit) => Promise<void>;
    init: (requestInit?: RequestInit) => Promise<void>;
    createInfluencerPlan: (data: InfluencerPlanDto) => Promise<void>;
    updateInfluencerPlan: (data: Partial<InfluencerPlanDto>) => Promise<void>;
    deleteInfluencerPlan: () => Promise<void>;
};

type InfluencerPlanContextState = {
    initialized: boolean;
    loading: boolean;
    error: boolean;
    influencerPlan?: InfluencerPlan;
    instagramInfluencerPosts?: InstagramInfluencerPost[];
    instagramInfluencerUsers: InstagramInfluencerUser[];
    plannerUsers: UserPlanner[];
    refreshRequests: InfluencerPlanPostsRefreshRequest[];
    refreshRequestsLoading: boolean;
};

type InfluencerPlanContextValues = InfluencerPlanContextState & InfluencerPlanContextUtils;

function reducer(state: InfluencerPlanContextState, action: Action) {
    switch (action.type) {
        case 'loadedInfluencerPlan':
            return {
                ...state,
                loading: false,
                influencerPlan: action.influencerPlan,
            };

        case 'loadedRefreshRequests':
            return {
                ...state,
                refreshRequests: action.payload,
            };

        case 'setRefreshRequestsLoading':
            return {
                ...state,
                refreshRequestsLoading: action.payload,
            };

        case 'reset':
            return initialState;

        case 'setInitialized':
            return {
                ...state,
                initialized: action.payload,
            };
        case 'loadedPlannerUsers':
            return {
                ...state,
                plannerUsers: action.payload,
            };

        default:
            return state;
    }
}

const initialState: InfluencerPlanContextState = {
    initialized: false,
    loading: true,
    error: false,
    influencerPlan: undefined,
    instagramInfluencerPosts: undefined,
    instagramInfluencerUsers: [],
    plannerUsers: [],
    refreshRequests: [],
    refreshRequestsLoading: false,
};

const initialContextValues: InfluencerPlanContextState & InfluencerPlanContextUtils = {
    ...initialState,
    fetchInfluencerPlan: async () => {},
    refreshInfluencerPlansPostsData: async () => 0,
    refreshPlannerUsers: async () => {},
    init: async () => {},
    createInfluencerPlan: async () => {},
    updateInfluencerPlan: async () => {},
    deleteInfluencerPlan: async () => {},
};

export const InfluencerPlanContext = createContext<InfluencerPlanContextValues>(initialContextValues);
export const InfluencerPlanProvider: FCWithChildren<{ releaseId: number }> = ({ children, releaseId }) => {
    const [state, dispatch] = useSafeReducer(reducer, initialState);

    const fetchInfluencerPlan = useCallback(
        async (releaseId: number, requestInit?: RequestInit) => {
            try {
                const [influencerPlan] = await getAllInfluencerPlans({ release_id: releaseId }, requestInit);
                dispatch({ type: 'loadedInfluencerPlan', influencerPlan });
                return influencerPlan;
            } catch (e) {
                // no-op
            }
        },
        [dispatch]
    );

    const getRefreshRequests = useCallback(
        async (influencerPlanId: number, requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'setRefreshRequestsLoading', payload: true });
                const refreshRequests = await getInfluencerPlanPostsRefreshRequests(influencerPlanId, requestInit);
                dispatch({ type: 'loadedRefreshRequests', payload: refreshRequests });
            } finally {
                dispatch({ type: 'setRefreshRequestsLoading', payload: false });
            }
        },
        [dispatch]
    );

    const refreshInfluencerPlansPostsData = useCallback(async () => {
        if (!state.influencerPlan) {
            throw new Error('Influencer Plan Id needed');
        }

        const { to_refresh } = await refreshInfluencerPlanPosts(state.influencerPlan.id);
        await getRefreshRequests(state.influencerPlan.id);
        return to_refresh;
    }, [getRefreshRequests, state.influencerPlan]);

    const createInfluencerPlan = useCallback(
        async (data: InfluencerPlanDto) => {
            const influencerPlan = await fetchCreateInfluencerPlan(data);
            dispatch({ type: 'loadedInfluencerPlan', influencerPlan });
        },
        [dispatch]
    );

    const updateInfluencerPlan = useCallback(
        async (data: Partial<InfluencerPlanDto>) => {
            if (!state.influencerPlan) {
                return;
            }

            const response = await patchInfluencerPlan(state.influencerPlan.id, data);
            if (response.status === 200) {
                dispatch({ type: 'loadedInfluencerPlan', influencerPlan: response.data });
            }

            if (response.status === 404) {
                throw new Error('Influencer plan not found');
            }
        },
        [dispatch, state.influencerPlan]
    );

    const deleteInfluencerPlan = useCallback(async () => {
        if (!state.influencerPlan) {
            return;
        }

        await fetchDeleteInfluencerPlan(state.influencerPlan.id);
        dispatch({ type: 'reset' });
    }, [dispatch, state.influencerPlan]);

    const refreshPlannerUsers = useCallback(
        async (userIds: number[], requestInit?: RequestInit) => {
            if (!userIds.length) {
                dispatch({ type: 'loadedPlannerUsers', payload: [] });
                return;
            }
            const plannerUsers = (await getPlannerUsers({ id: userIds.join(',') }, requestInit)).data.results;
            dispatch({ type: 'loadedPlannerUsers', payload: plannerUsers });
        },
        [dispatch]
    );

    const init = useCallback(
        async (requestInit?: RequestInit) => {
            const influencerPlan = await fetchInfluencerPlan(releaseId, requestInit);
            if (!influencerPlan) {
                return;
            }
            if (influencerPlan.planners?.length) {
                refreshPlannerUsers(influencerPlan.planners);
            }
            await getRefreshRequests(influencerPlan.id);
            dispatch({ type: 'setInitialized', payload: true });
        },
        [dispatch, fetchInfluencerPlan, getRefreshRequests, refreshPlannerUsers, releaseId]
    );

    useEffect(() => {
        dispatch({ type: 'reset' });
    }, [dispatch, releaseId]);

    const values = {
        ...state,
        init,
        fetchInfluencerPlan,
        refreshInfluencerPlansPostsData,
        refreshPlannerUsers,
        createInfluencerPlan,
        updateInfluencerPlan,
        deleteInfluencerPlan,
    };

    return <InfluencerPlanContext.Provider value={values}>{children}</InfluencerPlanContext.Provider>;
};
