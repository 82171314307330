import { microwave } from '@round/api';
import { FilterParams } from './InstagramContactedFilters/InstagramContactedFilters';
import { MakeRequired } from 'utility/utility.types';
import { StringSearchParams, toNumberOrUndefined, UrlData, useSessionUrlState } from '@round/utils';
import useRelease from '../../../Release/useRelease';
import { useEffect } from 'react';

type UrlStateShape = Pick<microwave.GetInvitesParams, 'page' | 'page_size' | 'ordering'> & FilterParams;

export type UrlState = MakeRequired<UrlStateShape, 'page' | 'page_size'>;
type UrlStateKey = keyof UrlState;

const initialState: UrlState = {
    page: 1,
    page_size: 10,
};

const parseUrlState = ({
    searchParams: {
        page,
        page_size,
        ordering,
        chase_email_count,
        showOnlySelected,
        influencer_location_id,
        content_tags,
    },
}: UrlData<StringSearchParams<UrlState>>): UrlState => ({
    page: page ? Number(page) : initialState.page,
    page_size: page_size ? Number(page_size) : initialState.page_size,
    ordering: ordering as UrlState['ordering'],
    showOnlySelected: showOnlySelected === 'true' || undefined,
    chase_email_count: toNumberOrUndefined(chase_email_count),
    influencer_location_id: toNumberOrUndefined(influencer_location_id),
    content_tags,
});

type HookConfig = {
    campaignId: number;
    shouldPersist?: boolean;
};

export default function useInstagramContactedUrlState({ shouldPersist, campaignId }: HookConfig) {
    const { data: release } = useRelease();

    const { url, mergeSearchParams, persistSession, clearSession } = useSessionUrlState<UrlState>(
        `mw-ig-campaign-contacted-params-${campaignId}`,
        (url, session, { merge }) => {
            const urlKeys = Object.keys(parseUrlState({ searchParams: {}, hash: '' })) as UrlStateKey[];

            const hasAnyParamSet = Object.keys(url.searchParams).some((k) => urlKeys.includes(k as UrlStateKey));
            if (hasAnyParamSet) {
                return url;
            }

            return merge(url, { searchParams: { ...initialState, ...session?.searchParams } });
        }
    );

    useEffect(() => {
        return () => {
            if (shouldPersist) {
                persistSession({ searchParams: parseUrlState(url) });
            }
        };
    }, [persistSession, shouldPersist, url]);

    useEffect(() => {
        return () => {
            if (!release?.id) {
                clearSession();
            }
        };
    }, [clearSession, release?.id]);

    return {
        state: parseUrlState(url),
        mergeSearchParams: mergeSearchParams,
    };
}
