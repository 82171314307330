import {
    FacebookErrorResponse,
    FacebookTokens,
    GenericDropdownOption,
    ReducerAction,
    ReducerActionWithPayload,
    TagOption,
} from './App.types';
import capitalize from 'lodash/capitalize';
import { Slide, toast, ToastOptions } from 'react-toastify';
import { resetTime } from './utility/utility';
import {
    ContentTag,
    Currency,
    encodeQueryString,
    encodeUrlSearchParams,
    fetchAll,
    fetchWithToken,
    InfluencerTag,
    refreshTokens,
    UserGroup,
} from '@round/api';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import fromPairs from 'lodash/fromPairs';
import differenceWith from 'lodash/differenceWith';

export const getLastUpdatedLabel = (lastUpdated: Date, justNowThreshold = 1000 * 60 * 30) => {
    const oneDay = 1000 * 60 * 60 * 24;
    const now = new Date();
    if (now.getTime() - lastUpdated.getTime() <= justNowThreshold) {
        return 'Just now';
    }

    const daysPassed = Math.floor(Math.abs((resetTime(lastUpdated).getTime() - resetTime(now).getTime()) / oneDay));
    return daysPassed === 0 ? 'today' : daysPassed === 1 ? 'yesterday' : `${daysPassed} days ago`;
};

export const getReadableDateTimeString = (date: Date) => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const isToday =
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();

    if (isToday) {
        return 'Today at ' + date.toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric', timeZone: 'UTC' });
    }

    const isYesterday =
        date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear();

    if (isYesterday) {
        return (
            'Yesterday at ' + date.toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric', timeZone: 'UTC' })
        );
    }

    return date
        .toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            timeZone: 'UTC',
        })
        .replace(',', ' at');
};

export const formatTo2dp = (num: number | string) => {
    return parseFloat(Number(num).toFixed(2));
};

export const formatNumberToCommaNotation = (num: number | string) => {
    return String(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const asMoney = (value: number | undefined | null, currency: Currency | undefined, fractionDigits = 2) => {
    if (typeof value !== 'number' || isNaN(value) || value === Infinity) {
        return '-';
    }

    return `${currency?.symbol ?? ''}${formatNumberToCommaNotation(value.toFixed(fractionDigits))}`;
};

export const asMoneyWithoutZeros = (value: number, currency: Currency) => {
    const money = asMoney(value, currency);
    return money.replace(/\.00$/, '');
};

export const datePickerFormat = 'MMMM d, yyyy';

export function formatDate(asString: string, options: Intl.DateTimeFormatOptions = {}) {
    // 20 Jan 2020
    const parsed = new Date(asString);
    return parsed.toLocaleString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        ...options,
    });
}

export function formatDateShort(asString: string, options: Intl.DateTimeFormatOptions = {}) {
    // 20 Jan
    const parsed = new Date(asString);
    return formatDateObjShort(parsed, options);
}

export function formatDateObjShort(d: Date, options: Intl.DateTimeFormatOptions = {}) {
    // 20 Jan
    return d.toLocaleString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: '2-digit',
        ...options,
    });
}

export const formatDateLong = (date: string) => formatDate(date, { month: 'long', day: '2-digit' });

export function roundTo2Dp(x: number) {
    return Math.round(x * 100) / 100;
}

export function numberWithCommas(n: number | string) {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const buildInstagramUserUrl = (username: string | undefined) => {
    if (!username) {
        return '';
    }

    return `https://instagram.com/${username}`;
};

export const buildInstagramAudioUrl = (instagramId: string | number | undefined) => {
    if (!instagramId) {
        return '';
    }

    return `https://www.instagram.com/reels/audio/${instagramId}/`;
};

export const buildTiktokUserUrl = (uniqueId: string) => {
    return `https://www.tiktok.com/@${uniqueId}`;
};

export const buildTiktokMusicUrl = (musicTitle: string, musicTiktokId: string) => {
    const titleWithStrippedPunctuation = musicTitle.replace(/[^\w\s]|_/g, '');
    const titleWithHyphenatedSpaces = titleWithStrippedPunctuation.replace(/ /g, '-');

    return `https://www.tiktok.com/music/${titleWithHyphenatedSpaces}-${musicTiktokId}`;
};

export const buildTiktokPostUrl = (userUniqueId: string, videoId: string) => {
    return `https://www.tiktok.com/@${userUniqueId}/video/${videoId}`;
};

export const buildTiktokHashtagUrl = (hashtag: string) => {
    return `https://www.tiktok.com/tag/${hashtag}`;
};

export const buildTwitterPostUrl = (id: string, username: string) => {
    return `https://twitter.com/${username}/status/${id}/`;
};

export const buildYoutubeChannelUrl = (channelId: string) => {
    return `https://youtube.com/channel/${channelId}`;
};

export const openInNewTab = (url: string) => {
    const protocolRegexp = /https?:\/\//;
    const formattedUrl = protocolRegexp.test(url) ? url : `https://${url}`;
    const newWindow = window.open(formattedUrl, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
};

/*
 * Tokens for fetchFacebookApi.
 * Tokens are not persisted in local storage
 * and available only here via closure.
 * Tokens should not be exported.
 * */
let facebookTokens: FacebookTokens | undefined;

/**
 * Facebook api request, which returns request body on resolve.
 * If response contains OAuthException, fetches facebook token from
 * backend and retries request.
 * @param url
 * @param queryParams
 * @param args
 */
export async function fetchFacebookApi<T>(
    url: string,
    queryParams: Record<string, string | number> = {},
    args?: RequestInit
): Promise<T | FacebookErrorResponse> {
    async function fetchWorker(depth: number): Promise<T | FacebookErrorResponse> {
        if (!facebookTokens) {
            facebookTokens = await fetchFacebookToken();
        }

        const queryString = encodeQueryString({
            ...queryParams,
            locale: 'en_GB',
            access_token: facebookTokens.accessToken,
            appsecret_proof: facebookTokens.appSecretProof,
        });

        const response = await fetch(`https://graph.facebook.com/v17.0${url}${queryString}`, args);
        const body = await response.json();

        // return auth exception if we've already refreshed token.
        if (body.error?.type === 'OAuthException' && depth > 0) {
            return body;
        }

        if (body.error?.type === 'OAuthException') {
            facebookTokens = undefined;
            return fetchWorker(depth + 1);
        }

        return body;
    }

    return fetchWorker(0);
}

async function fetchFacebookToken(): Promise<FacebookTokens> {
    const body = (await fetchWithToken('/api/facebook/api-access-token').then((response) => response.json())) as {
        facebook_access_token: string;
        facebook_app_secret_proof: string;
    };

    return {
        appSecretProof: body.facebook_app_secret_proof,
        accessToken: body.facebook_access_token,
    };
}

export function isFacebookError(obj: any): obj is FacebookErrorResponse {
    if (!obj || typeof obj !== 'object') {
        return false;
    }

    return (
        typeof obj.error === 'object' &&
        typeof obj.error.fbtrace_id === 'string' &&
        typeof obj.error.code === 'number' &&
        typeof obj.error.type === 'string' &&
        typeof obj.error.message === 'string'
    );
}

export function isNotEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

/**
 * Formats snake case string to capitalized
 * @example
 * some_snake_case_input => Some Snake Case Input
 * @param str
 */
export function formatSnakeCaseToDisplay(str: string): string {
    return str
        .replaceAll(/[^a-zA-Z]/g, ' ')
        .split(' ')
        .map(capitalize)
        .join(' ');
}

/**
 * Parsing search params from `location.search`
 * to an object.
 * @param searchParams
 */
export function parseUrlSearchParams(searchParams: string) {
    const params = new URLSearchParams(searchParams);
    return Array.from(params.entries())
        .filter((entry) => !!entry[1])
        .reduce<Record<string, string>>((acc, value) => {
            acc[value[0]] = value[1];
            return acc;
        }, {});
}

/**
 * Show sliding notification message
 * @param message
 * @param mode - defines notification's style
 */
export function showNotification(message: string, mode: 'info' | 'error') {
    const notificationOptions: ToastOptions = {
        autoClose: 2000,
        hideProgressBar: true,
        position: 'bottom-center',
        style: {
            minHeight: 'auto',
        },
        bodyStyle: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        transition: Slide,
    };

    toast[mode](message, notificationOptions);
}

/**
 * Returns a function which tells if user matches group requirements.
 * @param userGroups - user's groups to test against
 * @returns userHasGroupAccess - A function which tells if user has access given groups to check against and matchType.
 */
export function makeUserHasGroupAccess(
    userGroups: UserGroup[]
): (groups: UserGroup[], hasAccessWhenMatches?: 'all' | 'any') => boolean {
    /**
     * @param groups - list of group requirements
     * @param matchType - check type.
     *  if 'all' is selected, then user groups should contain every group passed,
     *  if match type is 'any' then user should have at least one group from requirements
     */
    return (groups, hasAccessWhenMatches = 'all') => {
        const matches = groups.filter((group) => userGroups.includes(group));
        return hasAccessWhenMatches === 'all' ? matches.length === groups.length : matches.length > 0;
    };
}

/**
 * Process response and download file
 * @param response
 * @param mimeType - optional param. If not passed content-type from response is used
 */
export async function downloadFileFromResponse(response: Response, mimeType?: string) {
    const FILE_NAME_REGEX = /filename="(.*)"/i;
    const contentDisposition = response.headers.get('content-disposition');
    const fileName = contentDisposition?.match(FILE_NAME_REGEX)?.[1] || '';

    const buffer = await response.arrayBuffer();
    const responseContentType = response.headers.get('content-type') || undefined;
    const blob = new Blob([buffer], { type: mimeType ?? responseContentType });

    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.setAttribute('download', fileName);
    a.click();
}

export const displayOptionalNumericTableValue = (
    value: string | number | undefined | null,
    emptyValueRepresentation = '-'
) => {
    return !isNaN(Number(value)) && value !== undefined && value !== null
        ? numberWithCommas(value)
        : emptyValueRepresentation;
};

/**
 * Takes a number and returns short version as in example.
 * Limits only up to millions
 * @param n
 * @example
 * formatNumberToKNotation(1000); // 1K
 * formatNumberToKNotation(10_000); // 10K
 * formatNumberToKNotation(1_000_000); // 1M
 * formatNumberToKNotation(1_000_000_000); // 1000M
 */
export const formatNumberToKNotation = (n: number): string => {
    const exponent = Math.floor(Math.log10(Math.abs(n)));
    const sliceRules = [
        { threshold: 3, exponentSign: '', format: (n: number) => n },
        {
            threshold: 4,
            exponentSign: 'K',
            format: (n: number) => {
                const formatted = (Math.floor((n / Math.pow(10, exponent)) * 10) / 10).toFixed(1);
                if (Number(formatted) - Math.floor(Number(formatted)) === 0) {
                    return parseInt(String(formatted));
                }

                return formatted;
            },
        },
        {
            threshold: 6,
            exponentSign: 'K',
            format: (n: number) => Math.round(n).toString(10).slice(0, -3),
        },
        {
            threshold: 7,
            exponentSign: 'M',
            format: (n: number) => {
                const formatted = (Math.floor((n / Math.pow(10, exponent)) * 10) / 10).toFixed(1);
                if (Number(formatted) - Math.floor(Number(formatted)) === 0) {
                    return parseInt(String(formatted));
                }

                return formatted;
            },
        },
        {
            threshold: 8,
            exponentSign: 'M',
            format: (n: number) => {
                const formatted = (Math.floor((n / Math.pow(10, exponent)) * 100) / 10).toFixed(1);
                if (Number(formatted) - Math.floor(Number(formatted)) === 0) {
                    return parseInt(String(formatted));
                }

                return formatted;
            },
        },
        {
            threshold: Infinity,
            exponentSign: 'M',
            format: (n: number) => Math.round(n).toString(10).slice(0, -6),
        },
    ];

    const rule = sliceRules.find((r) => r.threshold > exponent);
    if (!rule) {
        return n.toString();
    }

    return `${rule.format(n)}${rule.exponentSign}`;
};

export const formatPostingFrequencyFromAverage = (averagePostsPerDay: string) => {
    const average = parseFloat(averagePostsPerDay);
    if (!average) {
        return '-';
    }

    const formattingRules = [
        {
            threshold: 0.5,
            postingFrequency: `Every ${Math.floor(1 / average)} days`,
        },
        {
            threshold: 0.75,
            postingFrequency: 'Every 2 days',
        },
        {
            threshold: 1.5,
            postingFrequency: 'Every Day',
        },
        {
            threshold: Infinity,
            postingFrequency: 'Multiple times per day',
        },
    ];

    return formattingRules.find((r) => r.threshold > average)?.postingFrequency ?? '-';
};

/**
 * Creates a reducer function that accepts action handlers object
 * where key is action type and value is a reducer function.
 * @param handlers
 */
export function createReducer<State, Actions extends ReducerAction<any> | ReducerActionWithPayload<any, any>>(
    handlers: ActionHandlers<State, Actions>
): (s: State, action: Actions) => State {
    return (state, action) => {
        if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
            return handlers[action.type as keyof ActionHandlers<State, Actions>](state, action as any);
        }

        return state;
    };
}

type Slices<State, Actions> = {
    [slice: string]: (state: State, actions: Actions) => State;
};

type CombinedState<S extends Slices<any, any>> = {
    [key in keyof S]: ReturnType<S[key]>;
};

type CombinedActions<S extends Slices<any, any>> = Parameters<S[keyof S]>[1];

export function combineReducers<S extends Slices<any, any>>(
    slices: S
): (s: CombinedState<S>, actions: CombinedActions<S>) => CombinedState<S> {
    return (state, action) => {
        return Object.keys(slices).reduce(
            (state, prop) => ({
                ...state,
                [prop]: slices[prop](state[prop], action),
            }),
            state
        );
    };
}

export function mapInfluencerTagsToOptions(influencerTags: InfluencerTag[]) {
    const tagOptions: TagOption[] = influencerTags.map((tag) => ({
        value: tag.id,
        label: tag.name,
        type: tag.type,
        code: tag.code,
    }));
    const locationTagOptions = tagOptions.filter((tag) => tag.type === 'LOCATION');
    const categoryTagOptions = tagOptions.filter((tag) => tag.type === 'CATEGORY');

    return {
        tagOptions,
        locationTagOptions,
        categoryTagOptions,
    };
}

export function mapContentTagsToOptions(tags: ContentTag[]): GenericDropdownOption<number>[] {
    return tags.map((tag) => ({
        value: tag.id,
        label: tag.name,
    }));
}

export type ActionHandlers<State, Actions extends ReducerAction<any> | ReducerActionWithPayload<any, any>> = {
    [k in Actions['type']]: (state: State, action: Extract<Actions, { type: k }>) => State;
};

export { fetchAll, fetchWithToken, refreshTokens, encodeQueryString, encodeUrlSearchParams };

export function applyCommission(value: number, rate: number) {
    return value / (1 - rate);
}

/**
 * Returns the key value pairs in object1 that differ from object2
 **/
export function getObjectDifference(object1: Object, object2: Object) {
    return fromPairs(differenceWith(toPairs(object1), toPairs(object2), isEqual));
}

export function mapStringListToArray(list: string = ''): string[] {
    return !list ? [] : list.split(',').filter((v) => !!v);
}

export function removeSubstringFromUrlStateString(element: string, urlString: string) {
    return urlString
        .split(',')
        .filter((item) => item !== element)
        .join(',');
}

/**
 * Add an element to comma-separated list of elements in string
 * @param element element to add
 * @param list comma-separated list of elements
 */
export function appendToStringList(element: string, list: string) {
    return list
        .split(',')
        .filter((item) => !!item)
        .concat(element)
        .join(',');
}
