import { ReactNode } from 'react';
import styles from './HeadlineStatsCard.module.css';
import { SkeletonizedValue } from '@round/ui-kit';
import TruncationPopover from 'ui-new/TruncationPopover/TruncationPopover';

type Props = {
    title: string;
    value: ReactNode;
    icon: ReactNode;
    isLoading?: boolean;
};

const HeadlineStatsCard = ({ title, value, icon, isLoading }: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.iconContainer}>{icon}</div>
            <div className={styles.data}>
                <p className={styles.title}>{title} </p>
                <TruncationPopover content={value}>
                    {(setRef) => (
                        <SkeletonizedValue isInitialized={!isLoading}>
                            <p className={styles.value} ref={setRef}>
                                {value}
                            </p>
                        </SkeletonizedValue>
                    )}
                </TruncationPopover>
            </div>
        </div>
    );
};

export default HeadlineStatsCard;
