import { useNonNullContextSelector } from 'Hooks/useNonNullContextSelector';
import { ReleaseContext } from '../../ReleaseContext';
import { useCallback } from 'react';
import { microwave } from '@round/api';
import { useAbortableEffect } from '@round/utils';

type HookConfig = {
    releaseId?: number;
    isImperative?: boolean;
};

export default function useReleaseStats({ releaseId, isImperative = false }: HookConfig) {
    const state = useNonNullContextSelector(ReleaseContext, ([state]) => state.releaseStats);
    const dispatch = useNonNullContextSelector(ReleaseContext, ([, dispatch]) => dispatch);

    const fetchData = useCallback(
        async (releaseId: number, requestInit?: RequestInit) => {
            try {
                dispatch({ type: 'loadReleaseStats' });
                const response = await microwave.getReleaseStats(releaseId, requestInit);

                if (response.status === 404 || response.status === 403) {
                    dispatch({ type: 'errorLoadingReleaseStats', payload: response.data.detail });
                    return response;
                }

                dispatch({ type: 'releaseStatsInitialized', payload: response.data });
                return response;
            } catch (e) {
                if (e instanceof Error && e.name === 'AbortError') {
                    throw e;
                }

                dispatch({ type: 'errorLoadingReleaseStats', payload: 'Could not load stats' });
                throw e;
            }
        },
        [dispatch]
    );

    useAbortableEffect(
        (signal) => {
            if (isImperative || !releaseId) {
                return;
            }

            fetchData(releaseId, { signal }).catch(() => {});
        },
        [fetchData, isImperative, releaseId]
    );

    const reset = () => dispatch({ type: 'resetReleaseStats' });

    return {
        state,
        fetchData,
        reset,
    };
}
