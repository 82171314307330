import {
    ApiResponse,
    ApiResponseError,
    NotFoundResponse,
    OrderingValues,
    PaginatedApiResponseData,
    PaginatedRequest,
} from '../../types';
import { MicrowaveInfluencer } from './influencers.types';
import { encodeUrlSearchParams, fetchWithToken } from '../../helpers';

export type MicrowaveInfluencersSortingKey = keyof Pick<
    MicrowaveInfluencer,
    | 'tiktok_post_count'
    | 'tiktok_post_rate'
    | 'location'
    | 'genre_affinity'
    | 'follower_count'
    | 'instagram_follower_count'
>;
export function isMicrowaveInfluencersSortingKey(key: any): key is MicrowaveInfluencersSortingKey {
    const sortable: MicrowaveInfluencersSortingKey[] = [
        'tiktok_post_count',
        'tiktok_post_rate',
        'location',
        'genre_affinity',
        'follower_count',
        'instagram_follower_count',
    ];
    return typeof key === 'string' && sortable.includes(key as MicrowaveInfluencersSortingKey);
}

export type GetMicrowaveInfluencersParams = Partial<
    PaginatedRequest & {
        id: string;
        rating: string;
        search: string;
        location_id: number;
        content_tags: string;
        exclude_content_tags: string;
        posted_with_audio: string;
        min_tiktok_post_count: number;
        max_tiktok_post_count: number;
        min_instagram_post_count: number;
        max_instagram_post_count: number;
        min_tiktok_post_invite_count: number;
        max_tiktok_post_invite_count: number;
        min_instagram_post_invite_count: number;
        max_instagram_post_invite_count: number;
        min_tiktok_post_rate: number;
        min_instagram_post_rate: number;
        min_instagram_follower_count: number;
        max_instagram_follower_count: number;
        exclude_posted_with_audio: string;
        exclude_invited_to_plan: number;
        exclude_invited_to_campaign: number;
        hashtag_ids: string;
        with_genre_affinity: string;
        //genre affinity post count
        min_relevant_post_count: number;
        ordering: OrderingValues<MicrowaveInfluencersSortingKey> | OrderingValues<MicrowaveInfluencersSortingKey>[];
        has_instagram_account: boolean;
        has_tiktok_account: boolean;
        has_youtube_account: boolean;
        exclude_posted_with_instagram_audio: number;
        posted_with_instagram_audio: number;
    }
>;

export async function getMicrowaveInfluencers(
    params: GetMicrowaveInfluencersParams,
    requestInit?: RequestInit
): Promise<ApiResponse<PaginatedApiResponseData<MicrowaveInfluencer>, 200>> {
    const response = await fetchWithToken(`/api/microwave/influencers/${encodeUrlSearchParams(params)}`, requestInit);

    if (!response.ok) {
        throw new Error(`Could not get microwave influencers ${encodeUrlSearchParams(params)}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export async function getInfluencer(
    id: number,
    requestInit?: RequestInit
): Promise<ApiResponse<MicrowaveInfluencer, 200> | NotFoundResponse> {
    const response = await fetchWithToken(`/api/microwave/influencers/${id}/`, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not fetch microwave influencer ${id}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}

export type MicrowaveInfluencerWrite = Pick<MicrowaveInfluencer, 'rating' | 'location' | 'content_tags'>;

type PatchMicrowaveInfluencerResponse =
    | ApiResponse<MicrowaveInfluencer, 200>
    | NotFoundResponse
    | ApiResponse<ApiResponseError<MicrowaveInfluencerWrite>, 400>;

export async function patchMicrowaveInfluencer(
    influencerId: number,
    data: Partial<MicrowaveInfluencerWrite>
): Promise<PatchMicrowaveInfluencerResponse> {
    const response = await fetchWithToken(`/api/microwave/influencers/${influencerId}/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status === 404 || response.status === 400) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error(`Could not update influencer ${influencerId}`);
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
